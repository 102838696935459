import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"primary headline text-left white--text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"white--text"},[_vm._v("$vuetify.icons.expand")])]},proxy:true}])},[_vm._v(" Project Costs ")]),_c(VExpansionPanelContent,[_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"sm":"12","md":"3","p":""}},[_c('baseDatePickerWithText',{attrs:{"label":"Start Date","clearable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c(VCol,{attrs:{"sm":"12","md":"3"}},[_c('baseDatePickerWithText',{attrs:{"label":"End Date","clearable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c(VCol,[_c(VTextField,{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c('excelExport',{attrs:{"data":_vm.mappedData,"exportFields":_vm.excelFields,"summaryData":_vm.summaryData,"worksheet":"Project Costs","name":("Project_Costs_" + _vm.projectNumber + ".xlsx")}},[_vm._v("Export")])],1)],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"item-key":"ID","headers":_vm.visibleHeaders,"items":_vm.mappedData,"items-per-page":-1,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.preExam",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.preExam)))]}},{key:"item.onSite",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.onSite)))]}},{key:"item.offSite",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.offSite)))]}},{key:"item.billableHours",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.billableHours)))]}},{key:"item.otherHours",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.otherHours)))]}},{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.subTotal)))]}},{key:"item.postExam",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.postExam)))]}},{key:"item.travel",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.travel)))]}},{key:"footer",fn:function(){return [_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Exam Total: "+_vm._s(_vm._f("money")(_vm.examTotal)))]),_c(VCol,{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Travel Total: "+_vm._s(_vm._f("money")(_vm.travelTotal)))]),_c(VCol,{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Post-Exam Total: "+_vm._s(_vm._f("money")(_vm.postExamTotal)))]),_c(VCol,{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Grand Total: "+_vm._s(_vm._f("money")(_vm.grandTotal)))])],1)]},proxy:true},{key:"item.examCosts",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.examCosts)))])]}},{key:"item.postExamCosts",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.postExamCosts)))])]}},{key:"item.personnelCosts",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.personnelCosts)))])]}},{key:"item.travelExpenses",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.travelExpenses)))])]}},{key:"item.totalCosts",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.totalCosts)))])]}}])})]],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }