import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"850","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialogBudget = false}},model:{value:(_vm.dialogBudget),callback:function ($$v) {_vm.dialogBudget=$$v},expression:"dialogBudget"}},[_c(VForm,{ref:"budgetedForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary"},[_c('h3',{staticClass:"hpHeading white--text"},[_vm._v(_vm._s(_vm.modalText))])]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VSelect,{attrs:{"item-text":"Description","item-value":"ID","rules":[_vm.rules.fieldRequired],"items":_vm.availableActivityCodes,"label":"Timesheet Activity","readonly":_vm.selectedProjectBudget.Approved},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(item.Code)+" "+_vm._s(item.Description))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(item.Code)+" "+_vm._s(item.Description))])]}}]),model:{value:(_vm.selectedProjectBudget.ActivityID),callback:function ($$v) {_vm.$set(_vm.selectedProjectBudget, "ActivityID", $$v)},expression:"selectedProjectBudget.ActivityID"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"6"}},[_c(VTextField,{attrs:{"label":"Standard Budget Hours","readonly":""},model:{value:(_vm.selectedProjectBudget.StandardBudgetHours),callback:function ($$v) {_vm.$set(_vm.selectedProjectBudget, "StandardBudgetHours", $$v)},expression:"selectedProjectBudget.StandardBudgetHours"}})],1),_c(VCol,{attrs:{"sm":"12","md":"6"}},[_c(VTextField,{attrs:{"readonly":_vm.selectedProjectBudget.Approved,"rules":[_vm.rules.fieldRequired],"label":"Budgeted Hours"},model:{value:(_vm.selectedProjectBudget.BudgetedHours),callback:function ($$v) {_vm.$set(_vm.selectedProjectBudget, "BudgetedHours", $$v)},expression:"selectedProjectBudget.BudgetedHours"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VTextarea,{attrs:{"readonly":_vm.selectedProjectBudget.Approved,"outlined":"","label":"Comments"},model:{value:(_vm.selectedProjectBudget.Comments),callback:function ($$v) {_vm.$set(_vm.selectedProjectBudget, "Comments", $$v)},expression:"selectedProjectBudget.Comments"}})],1)],1)],1),_c(VCardActions,[(!_vm.selectedProjectBudget.Approved)?_c(VBtn,{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.saveBudgetHours}},[_vm._v("Save")]):_vm._e(),(
            _vm.isManagerOfProject &&
            !_vm.selectedProjectBudget.Approved &&
            _vm.selectedProjectBudget.ID > 0
          )?_c(VBtn,{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.approveBudgetHours,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialogBudget = false}}},[_vm._v("Approve")]):_vm._e(),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelBudgetHours}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }