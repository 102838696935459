<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Time Details
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="data"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Hours"
                        :name="`Project_Hours_${projectNumber}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="data"
                    :items-per-page="-1"
                    :search="search"
                    class="pt-4"
                    show-group-by
                    :group-by="divisionId == 5 ? [] : 'Date'"
                    dense
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                  >
                    <template v-slot:item.billable="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          {{ item.billable }}
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="openBillableEdit(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit Billable Status</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.Hours="{item}">{{
                      item.Hours | decimalToTime
                    }}</template>
                    <!-- <template v-slot:footer="">
                      <v-row justify="end">
                        <v-col sm="auto" class="mt-4">
                          Total Hours: {{ totalHours | decimalToTime }}
                          <br />Total Billable Hours:
                          {{ totalBillableHours | decimalToTime }}
                        </v-col>
                      </v-row>
                    </template>-->
                    <template v-slot:footer>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col sm="auto" class="ma-4"
                          >Total Hours: {{ totalTimeDetails }}</v-col
                        >
                      </v-row>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="billableDialog" scrollable width="300">
      <v-form
        ref="billableForm"
        @submit.prevent
        lazy-validation
        v-model="valid"
      >
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">Billable Time?</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="12">
                {{ selectedTimeSheetEntry.Date }} -
                {{ selectedTimeSheetEntry.TimeSheet.User.FirstName }}
                {{ selectedTimeSheetEntry.TimeSheet.User.LastName }} -
                {{ selectedTimeSheetEntry.Hours | decimalToTime }}
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                Change this time to be
                <v-select
                  v-model="selectedTimeSheetEntry.BillableCategoryID"
                  :items="availableBillableCategories"
                  item-text="Description"
                  item-value="ID"
                  label="Billing Category"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :disabled="saving"
              @click="confirmBillableTime"
              >Save</v-btn
            >
            <v-btn outlined color="primary" @click="cancelBillableEdit"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import TimeSheetEntry from '@classes/TimeSheetEntry'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    projectNumber: String,
    projectDetails: Object,
    projectId: Number,
    divisionId: Number,
    data: Array,
    projectSummary: Array,
  },
  components: {
    excelExport,
  },
  filters: {
    decimalToTime(d) {
      var decimalTime = parseFloat(d) * 60 * 60
      var hours = Math.floor(decimalTime / (60 * 60))

      decimalTime = decimalTime - hours * 60 * 60
      var minutes = Math.round(decimalTime / 60)

      if (minutes < 10) {
        minutes = '0' + minutes
      }

      return hours + ':' + minutes
    },
  },
  data() {
    return {
      search: '',
      valid: true,
      panel: [0],
      selectedTimeSheetEntry: new TimeSheetEntry(),
      billableDialog: false,
      pagination: {sortBy: 'Date', descending: true},
      saving: false,
    }
  },
  created() {
    if (this.divisionId == 3) {
      this.loadLuBillableCategory()
    }
  },
  computed: {
    luBillableCategory: get('luBillableCategory/luBillableCategory'),
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: 'Date',
          align: 'center',
          sortable: 'true',
          value: 'Date',
          visible: true,
        },
        {
          text: 'Activity',
          align: 'center',
          sortable: 'true',
          value: 'activityCode',
          visible: true,
        },
        {
          text: 'Code Description',
          align: 'left',
          sortable: 'true',
          value: 'activityDescription',
          visible: true,
        },
        {
          text: 'User',
          align: 'left',
          sortable: 'true',
          value: 'user',
          visible: true,
        },
        {
          text: 'Hours',
          align: 'right',
          sortable: 'true',
          value: 'Hours',
          visible: true,
        },
        {
          text: 'Billable',
          align: 'center',
          sortable: 'true',
          value: 'billable',
          visible: this.divisionId == 3,
        },
      ]
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        fields[item.text] = item.value
      })
      fields.hours = {
        field: 'Hours',
        callback: (value) => {
          return value / 24
        },
        format: '[h]:mm',
        sum: true,
      }
      fields.Hours = {
        field: 'Hours',
        sum: true,
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Hours'})
      return summary
    },
    availableBillableCategories() {
      // get active for this division
      var list = this.luBillableCategory.filter(
        (i) =>
          i.IsActive &&
          i.DivisionID == this.divisionId &&
          (!i.Prefix || i.Prefix == this.projectDetails.Prefix)
      )
      // if existing record make sure the previous selection is available
      if (this.selectedTimeSheetEntry.ID > 0) {
        var selectedItem = this.luBillableCategory.find(
          (i) => i.ID == this.selectedTimeSheetEntry.BillableCategoryID
        )
        if (
          !list.find(
            (c) => c.ID == this.selectedTimeSheetEntry.BillableCategoryID
          ) &&
          selectedItem
        ) {
          list.push(selectedItem)
        }
      }
      // sort by sort order column
      list.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    totalTimeDetails() {
      let totalTime = this.data.reduce((sum, item) => {
        return sum + item.Hours
      }, 0)
      return totalTime
    },
  },
  methods: {
    updateTimesheetEntryBillableCategory: call(
      'timeSheetEntry/updateBillableCategory'
    ),
    loadLuBillableCategory: call('luBillableCategory/loadLuBillableCategory'),
    async validate() {
      this.$refs.billableForm.validate()
    },
    openBillableEdit(item) {
      this.selectedTimeSheetEntry = new TimeSheetEntry(item)
      this.billableDialog = true
    },
    cancelBillableEdit() {
      this.selectedTimeSheetEntry = new TimeSheetEntry()
      this.billableDialog = false
    },

    confirmBillableTime() {
      this.validate().then(() => {
        if (this.valid) {
          this.saving = true
          this.updateTimesheetEntryBillableCategory(
            this.selectedTimeSheetEntry.root()
          )
            .then((res) => {
              this.billableDialog = false
              this.saving = false
            })
            .catch((error) => {
              console.error(error)
              this.saving = false
            })
        }
      })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
