<template>
  <v-autocomplete
    :items="users"
    :label="label"
    v-model="selected"
    :multiple="multi"
    :rules="rules"
    item-text="FirstName"
    item-value="ID"
  >
    <template v-slot:selection="{item, index}">
      <span>{{ item.FirstName }} {{ item.LastName }}</span>
    </template>
    <template v-slot:item="{item, index}">
      <span>{{ item.FirstName }} {{ item.LastName }}</span>
    </template>
  </v-autocomplete>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'v-luUser',
  props: {
    label: '',
    value: null,
    multi: false,
    group: false,
    rules: Array,
    divisionId: Number,
  },
  data() {
    return {
      selected: this.value,
      searchText: '',
      users: [],
      pagination: {
        page: 1,
        sortBy: 'LastName',
        descending: false,
        rowsPerPage: 200,
      },
    }
  },
  created() {
    this.searchUsers()
  },
  searchText: {
    handler() {
      this.bounce(this)
    },
  },
  methods: {
    searchUsers() {
      if (this.divisionId) {
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `UserSearch?pageNum=${this.pagination.page - 1}&pageSize=${
          this.pagination.rowsPerPage
        }&sortProperty=${this.pagination.sortBy}&sortDirection=${
          this.pagination.descending ? 'desc' : 'asc'
        }&searchText=${this.searchText}&allUsers=false&division=${
          this.divisionId
        }`
        this.$axios.get(url).then(
          (res) => {
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.users = res.data.Entries
            this.searchCount--
          },
          (error) => {
            this.error = error
            this.handleError(error)
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
  },
  computed: {},
  watch: {
    selected(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.selected = val
    },
  },
}
</script>
<style></style>
