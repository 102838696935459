<template>
  <div>
    <v-dialog
      persistent
      v-model="dialogDate"
      @keydown.esc="dialogDate = false"
      width="950"
    >
      <v-form ref="datesForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">{{ modalText }}</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col class="py-0" sm="12" md="6">
                <v-select
                  v-model="selectedProjectDate.DateType"
                  :rules="[rules.fieldRequired]"
                  :items="availableDateTypes"
                  item-text="DateType"
                  item-value="ID"
                  label="Date Type"
                >
                  <template v-if="divisionID == 5" v-slot:selection="{item}">
                    <span>{{ item.SortOrder }} {{ item.DateType }}</span>
                  </template>
                  <template v-if="divisionID == 5" v-slot:item="{item}">
                    <span>{{ item.SortOrder }} {{ item.DateType }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="selectedDateType && selectedDateType.SentToAvailable"
                class="py-0"
                sm="12"
                md="6"
              >
                <!-- Sent to MINERALS FIELD ONLY -->
                <v-select
                  v-model="selectedProjectDate.SentTo"
                  :items="availableSentTo"
                  item-text="SentTo"
                  item-value="ID"
                  label="Sent To"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" sm="12" md="6">
                <BaseDatePickerWithText
                  v-model="selectedProjectDate.FromDate"
                  :label="`${
                    selectedDateType && selectedDateType.StartDateDisplay
                      ? selectedDateType.StartDateDisplay
                      : 'From Date'
                  } mm/dd/yyyy`"
                ></BaseDatePickerWithText>
              </v-col>
              <v-col
                class="py-0"
                sm="12"
                md="6"
                v-if="selectedDateType && selectedDateType.ShowEndDate"
              >
                <BaseDatePickerWithText
                  v-model="selectedProjectDate.ToDate"
                  :label="`${
                    selectedDateType && selectedDateType.EndDateDisplay
                      ? selectedDateType.EndDateDisplay
                      : 'To Date'
                  } mm/dd/yyyy`"
                ></BaseDatePickerWithText>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" sm="12">
                <v-textarea
                  v-model="selectedProjectDate.Comment"
                  outlined
                  label="Comments"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="selectedDateType && selectedDateType.FindingsRequired">
              <v-expansion-panels v-model="panel">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="primary text-left white--text"
                  >
                    Findings
                    <template v-slot:actions>
                      <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col sm="auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="addFinding"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Finding Information</span>
                      </v-tooltip>
                    </v-col>
                    <template
                      v-if="selectedProjectDate.ProjectFindings.length > 0"
                    >
                      <v-data-table
                        item-key="index"
                        :headers="headers"
                        :items-per-page="-1"
                        :expanded.sync="expanded"
                        class="pt-4"
                        single-expand
                        show-expand
                        dense
                        :items="displayFindings"
                      >
                        <template v-slot:item.findingEdit="{item}">
                          <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="openFindingEditDialog(item)"
                              >
                                <v-icon class="clickable" color="primary"
                                  >mdi-pencil</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Edit Finding Information</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.FindingsValue="{item}">
                          <span>{{ item.FindingsValue | moneyFormat }}</span>
                        </template>
                        <template v-slot:item.findingsType="{item}">
                          <span>
                            {{
                              item.FindingsTypeID | findingType(luFindingType)
                            }}
                          </span>
                        </template>
                        <template v-slot:item.CountyId="{item}">
                          <span>{{ item.CountyId | county(luCounty) }}</span>
                        </template>
                        <template v-slot:item.Comments="{item}">
                          <span @click="expandRow(item)">
                            {{ item.Comments | snippet(20) }}
                          </span>
                        </template>
                        <template v-slot:expanded-item="{item, headers}">
                          <td :colspan="headers.length">{{ item.Comments }}</td>
                        </template>
                        <template v-slot:item.findingDelete="{item}">
                          <v-tooltip v-if="findingDeleteAllowed" bottom>
                            <template v-slot:activator="{on}">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="deleteFindingType(item)"
                              >
                                <v-icon class="clickable" color="primary"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Delete Finding Information</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
            <v-card-actions class="my-2">
              <v-btn
                color="primary"
                depressed
                :disabled="saving"
                @click="saveDate"
                >Save</v-btn
              >
              <v-btn outlined color="primary" @click="cancelDate">Cancel</v-btn>
            </v-card-actions>
            <span
              v-if="displayFindingValidation && findingRequired"
              class="findingWarning"
            >
              At least one finding is required for the selected date type!
            </span>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      persistent
      v-model="findingDialog"
      @keydown.esc="findingDialog = false"
      width="850"
      scrollable
    >
      <v-form
        ref="findingsForm"
        @submit.prevent
        lazy-validation
        v-model="valid"
      >
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">Findings</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col class="py-0" sm="12" md="6">
                <BaseDatePickerWithText
                  v-model="selectedProjectFinding.SentDate"
                  :rules="[rules.fieldRequired]"
                  label="Sent Date mm/dd/yyyy"
                ></BaseDatePickerWithText>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" sm="12" md="6">
                <v-text-field
                  v-model="selectedProjectFinding.FindingsValue"
                  type="number"
                  :rules="[(v) => v != null || 'Required', rules.amountPattern]"
                  label="Finding Value"
                  prefix="$"
                ></v-text-field>
              </v-col>
              <v-col class="py-0" sm="12" md="6">
                <!-- FINDING TYPE -->
                <v-select
                  v-model="selectedProjectFinding.FindingsTypeID"
                  item-text="FindingType"
                  item-value="ID"
                  :items="availableFindingTypes"
                  :rules="[rules.fieldRequired]"
                  label="Findings Type"
                  clearable
                ></v-select>
              </v-col>
              <v-col
                v-if="
                  selectedFindingsType && selectedFindingsType.RequiresCounty
                "
                class="py-0"
                sm="12"
                md="6"
              >
                <v-select
                  v-model="selectedProjectFinding.CountyId"
                  item-text="Name"
                  item-value="ID"
                  :items="luCounty"
                  label="County Name"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 pt-4" sm="12">
                <v-textarea
                  v-model="selectedProjectFinding.Comments"
                  outlined
                  label="Comments"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              depressed
              :disabled="saving"
              @click="saveFinding"
              >Save</v-btn
            >
            <v-btn outlined color="primary" @click="cancelFinding"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog persistent v-model="deleteFindingDialog" scrollable width="650">
      <v-form ref="notesForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text"
              >Are you sure you want to delete this Finding?</h3
            >
          </v-card-title>
          <v-card-text class="pa-4">
          </v-card-text>
            <v-card-actions>
              <v-btn @click="closeDeleteFinding" color="primary">Cancel</v-btn>
              <v-btn @click="deleteSelectedFinding" outlined color="primary"
                >Delete</v-btn
              >
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import ProjectDate from '@classes/ProjectDate'
import ProjectFindings from '@classes/ProjectFindings'
import {get, sync, commit, call} from 'vuex-pathify'
import {mask} from 'vue-the-mask'
import Rules from '../../validation/rules'
export default {
  directives: {
    mask,
  },
  components: {},
  props: {
    project: Number,
    divisionID: Number,
    projectDetails: Object,
  },
  data() {
    return {
      selectedProjectDate: new ProjectDate(),
      selectedProjectFinding: new ProjectFindings(),
      dialogDate: false,
      findingDialog: false,
      deleteFindingDialog: false,
      valid: true,
      modalText: 'Date Information',
      panel: 0,
      expanded: [],
      findingEditIndex: -1,
      rules: Rules,
      displayFindingValidation: false,
      saving: false,
    }
  },
  created() {
    this.loadLuDateType()
    this.loadLuFindingType()
    this.loadLuCounty()
    this.loadLuSentTo()
    var de = this
    eventBus.$on('dialogDate', function (value) {
      de.selectedProjectDate = new ProjectDate(value)
      de.selectedProjectDate.ProjectID = de.project
      de.dialogDate = !!value
    })
    eventBus.$on('dialogFindings', function (value) {
      de.selectedProjectDate = new ProjectDate()
      de.selectedProjectFinding = new ProjectFindings(value)
      de.findingDialog = !!value
    })
    eventBus.$on('closeProjectDate', (pd) => {
      de.dialogDate = false
    })
  },
  beforeDestroy() {
    var de = this
    eventBus.$off('dialogDate')
    eventBus.$off('dialogFindings')
    eventBus.$off('closeProjectDate')
  },
  filters: {
    dateType(id, list) {
      if (id > 0) {
        let match = list.find((item) => {
          return item.ID == id
        })
        return match ? match.DateType : ''
      } else {
        return ''
      }
    },
    sentTo(id, list) {
      if (id > 0) {
        let match = list.find((item) => {
          return item.ID == id
        })
        return match ? match.SentTo : ''
      } else {
        return ''
      }
    },
    county(id, list) {
      if (id > 0) {
        let match = list.find((item) => {
          return item.ID == id
        })
        return match ? match.Name : ''
      } else {
        return ''
      }
    },
    findingType(id, list) {
      if (id > 0) {
        let match = list.find((item) => {
          return item.ID == id
        })
        return match ? match.FindingType : ''
      } else {
        return ''
      }
    },
  },
  computed: {
    luDateType: get('luDateType/luDateType'),
    luSentTo: get('luSentTo/luSentTo'),
    luFindingType: get('luFindingType/luFindingType'),
    luCounty: get('luCounty/luCounty'),
    projectDate: get('projectDate/projectDate'),
    findingRequired() {
      return (
        this.selectedDateType &&
        this.selectedProjectDate &&
        this.selectedDateType.FindingsRequired == true &&
        this.selectedProjectDate.ProjectFindings.length == 0 &&
        this.selectedProjectDate.ID == 0
      )
    },
    selectedDateType() {
      return this.luDateType.find(
        (d) => d.ID == this.selectedProjectDate.DateType
      )
    },
    selectedFindingsType() {
      return this.luFindingType.find(
        (d) => d.ID == this.selectedProjectFinding.FindingsTypeID
      )
    },
    usedDateTypes() {
      let dateTypes = []
      this.projectDate.forEach((d) => {
        if (dateTypes.indexOf(d.DateType) < 0) {
          dateTypes.push(d.DateType)
        }
      })
      return dateTypes
    },
    availableDateTypes() {
      // get active for this division
      var dateTypes = this.luDateType.filter(
        (d) =>
          d.IsActive &&
          d.DivisionID == this.divisionID &&
          (!d.Prefix ||
            d.Prefix == this.projectDetails.Prefix ||
            //BT projects use the same date types as BB
            (this.projectDetails.Prefix == 'BT' && d.Prefix == 'BB'))
      )
      // for public funds we are restricting it to unused selections
      if (this.divisionID == 4) {
        if (this.usedDateTypes.length > 0) {
          this.usedDateTypes.forEach((u) => {
            let index = dateTypes.findIndex((t) => {
              return t.ID == u
            })
            if (index > -1) {
              dateTypes.splice(index, 1)
            }
          })
        }
      }
      // for banking "BU" we are disabling existing items
      if (this.projectDetails.Prefix == 'BU') {
        if (this.usedDateTypes.length > 0) {
          this.usedDateTypes.forEach((u) => {
            let index = dateTypes.findIndex((t) => {
              return t.ID == u
            })
            if (index > -1) {
              dateTypes[index].disabled = true
            }
          })
        }
      }
      // if existing record make sure the previous selection is available
      if (this.selectedProjectDate.ID > 0) {
        var selectedDateType = this.luDateType.find(
          (d) => d.ID == this.selectedProjectDate.DateType
        )
        if (
          !dateTypes.find((d) => d.ID == this.selectedProjectDate.DateType) &&
          selectedDateType
        ) {
          dateTypes.push(selectedDateType)
        }
      }
      // sort by sort order column
      dateTypes.sort((a, b) => {
        let textField = 'DateType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return dateTypes
    },
    availableSentTo() {
      // get active for this division
      var sentTo = this.luSentTo.filter(
        (s) => s.IsActive && s.DivisionID == this.divisionID
      )
      // if existing record make sure the previous selection is available
      if (this.selectedProjectDate.ID > 0) {
        var selectedSentTo = this.luSentTo.find(
          (s) => s.ID == this.selectedProjectDate.SentTo
        )
        if (
          !sentTo.find((s) => s.ID == this.selectedProjectDate.SentTo) &&
          selectedSentTo
        ) {
          sentTo.push(selectedSentTo)
        }
      }
      // sort by sort order column
      sentTo.sort((a, b) => {
        let textField = 'SentTo'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return sentTo
    },
    availableFindingTypes() {
      // get active for this division
      var findingTypes = this.luFindingType.filter(
        (f) => f.Active && f.DivisionID == this.divisionID
      )
      // if existing record make sure the previous selection is available
      if (this.selectedProjectFinding.ID > 0) {
        var selectedFindingType = this.luFindingType.find(
          (f) => f.ID == this.selectedProjectFinding.FindingsTypeID
        )
        if (
          !findingTypes.find(
            (f) => f.ID == this.selectedProjectFinding.FindingsTypeID
          ) &&
          selectedFindingType
        ) {
          findingTypes.push(selectedFindingType)
        }
      }
      // sort by sort order column
      findingTypes.sort((a, b) => {
        let textField = 'FindingType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return findingTypes
    },
    displayFindings() {
      return this.selectedProjectDate.ProjectFindings.map((f, index) => {
        return {
          ...f,
          index: index,
        }
      })
    },
    findingDeleteAllowed() {
      return this.selectedProjectDate.ProjectFindings.length > 1
    },
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'findingEdit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Sent Date',
          align: 'left',
          value: 'SentDate',
          sortable: true,
          visible: true,
        },
        {
          text: 'Findings Value',
          align: 'right',
          value: 'FindingsValue',
          sortable: true,
          visible: true,
        },
        {
          text: 'Findings Type',
          align: 'left',
          value: 'findingsType',
          sortable: true,
          visible: true,
        },
        // {
        //   // EXCISE
        //   text: 'Mileage',
        //   align: 'left',
        //   value: 'mileage',
        //   sortable: true,
        // },
        {
          text: 'Comments',
          align: 'left',
          value: 'Comments',
          sortable: true,
          visible: true,
        },
        {
          text: 'County Name',
          align: 'left',
          value: 'CountyId',
          sortable: true,
          visible: true,
        },
        {
          text: '',
          align: 'left',
          value: 'findingDelete',
          visible: true,
          sortable: false,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    loadLuDateType: call('luDateType/loadLuDateType'),
    loadLuFindingType: call('luFindingType/loadLuFindingType'),
    loadLuSentTo: call('luSentTo/loadLuSentTo'),
    loadLuCounty: call('luCounty/loadLuCounty'),
    saveProjectDate: call('projectDate/saveProjectDate'),
    saveProjectFindings: call('projectFindings/saveProjectFindings'),
    deleteProjectFindings: call('projectFindings/deleteProjectFindings'),
    expandRow(item) {
      console.devlog(item)
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
    openFindingEditDialog(finding) {
      // var match = this.selectedProjectDate.ProjectFindings.findIndex((item) => {
      //   return item == finding
      // })
      // console.devlog(match)
      // if (match >= 0) {
      this.findingEditIndex = finding.index
      // }

      this.selectedProjectFinding = new ProjectFindings(finding)
      this.findingDialog = true
    },
    addFinding() {
      this.selectedProjectFinding = new ProjectFindings({
        ProjectDateID: this.selectedProjectDate.ID,
        ProjectID: this.selectedProjectDate.ProjectID,
        SentDate: this.selectedProjectDate.FromDate,
      })
      this.findingEditIndex = -1
      this.findingDialog = true
    },
    openDialog() {
      this.dialogDate = true
    },
    deleteFindingType(item) {
      this.selectedProjectFinding = new ProjectFindings(item)
      this.deleteFindingDialog = true
    },
    closeDeleteFinding() {
      this.deleteFindingDialog = false
    },
    async validate() {
      this.$refs.datesForm.validate()
    },
    saveDate() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedProjectDate.ProjectID = this.project
          if (this.findingRequired) {
            this.displayFindingValidation = true
          }
          // we will want to include the findings for new dates
          if (!this.findingRequired) {
            this.saving = true
            this.saveProjectDate(this.selectedProjectDate.removeRelated())
              .then((res) => {
                this.selectedProjectDate = new ProjectDate({
                  ProjectID: this.project,
                })
                this.dialogDate = false
                this.$refs.datesForm.resetValidation()
                eventBus.$emit('SaveFinding')
                this.saving = false
                // also refresh findings grid
              })
              .catch((error) => {
                console.error(error)
                this.saving = false
              })
          }
        }
      })
    },
    cancelDate() {
      this.selectedProjectDate = new ProjectDate({ProjectID: this.project})
      this.dialogDate = false
      this.$refs.datesForm.resetValidation()
    },
    cancelFinding() {
      this.selectedProjectFinding = new ProjectFindings()
      this.findingDialog = false
      this.$refs.findingsForm.resetValidation()
    },
    async validateFindings() {
      this.$refs.findingsForm.validate()
    },
    saveFinding() {
      this.validateFindings().then(() => {
        if (this.valid) {
          if (this.selectedProjectFinding.ID > 0) {
            // finding exists in db
            if (this.selectedProjectDate.ID > 0) {
              // we are also editing the date
              //update selected date with new finding
              var match = this.selectedProjectDate.ProjectFindings.findIndex(
                (item) => {
                  return item.ID == this.selectedProjectFinding.ID
                }
              )
              if (match >= 0) {
                This.$set(
                  this.selectedProjectDate.ProjectFindings,
                  match,
                  this.selectedProjectFinding
                )
              }
            } else {
              this.saving = true
              // we are editing the finding ,but not the date -- save directly
              this.saveProjectFindings(this.selectedProjectFinding.root()).then(
                (res) => {
                  eventBus.$emit('SaveFinding')
                  this.saving = false
                },
                (err) => {
                  this.saving = false
                }
              )
              //emit event to refresh findings grid
            }
          } else {
            //did we select a previously entered new item
            if (this.findingEditIndex >= 0) {
              this.$set(
                this.selectedProjectDate.ProjectFindings,
                this.findingEditIndex,
                this.selectedProjectFinding
              )
            } else {
              // new finding -- save to date we are editing
              this.selectedProjectDate.ProjectFindings.push(
                this.selectedProjectFinding
              )
            }
          }
          this.selectedProjectFinding = new ProjectFindings()
          this.findingDialog = false
          this.$refs.findingsForm.resetValidation()
        }
      })
    },
    deleteSelectedFinding() {
      if (this.selectedProjectFinding.ID > 0) {
        this.deleteProjectFindings(this.selectedProjectFinding.root())
          .then((res) => {
            //update selected date with deleted finding
            var match = this.selectedProjectDate.ProjectFindings.findIndex(
              (item) => {
                return item.ID == this.selectedProjectFinding.ID
              }
            )
            this.selectedProjectDate.ProjectFindings.splice(match, 1)
            this.selectedProjectFinding = new ProjectFindings()
            this.deleteFindingDialog = false
            this.$refs.findingsForm.resetValidation()
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.selectedProjectDate.ProjectFindings.splice(
          this.selectedProjectFinding.index,
          1
        )
        this.selectedProjectFinding = new ProjectFindings()
        this.deleteFindingDialog = false
        this.$refs.findingsForm.resetValidation()
      }
    },
  },
  watch: {
    findingRequired(nval, oval) {
      if (!nval) {
        this.displayFindingValidation = false
      }
    },
  },
}
</script>
<style scoped>
.findingWarning {
  color: red;
}
</style>
