<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary headline text-left white--text">
                Travel Expenses
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col v-if="userCanEditProjectItems" sm="auto" class="mt-4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openTravelExpensesDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Travel Expenses</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="mappedData"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Expenses"
                        :name="`Project_Expenses_${projectNumber}.xlsx`"
                      >Export</excelExport>
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="mappedData"
                    :items-per-page="-1"
                    :expanded.sync="expanded"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    single-expand
                    show-expand
                    dense
                  >
                    <template v-if="userCanEditProjectItems" v-slot:item.dateEdit="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn small color="primary" v-on="on" icon @click="openEditDialog(item)">
                            <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Travel Expenses</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.POV="{item, headers}">
                      <span>{{ item.POV | money }}</span>
                    </template>
                    <template v-slot:item.StateCar="{item, headers}">
                      <span>{{ item.StateCar | money }}</span>
                    </template>
                    <template v-slot:item.AirFare="{item, headers}">
                      <span>{{ item.AirFare | money }}</span>
                    </template>
                    <template v-slot:item.Hotel="{item, headers}">
                      <span>{{ item.Hotel | money }}</span>
                    </template>
                    <template v-slot:item.MIE="{item, headers}">
                      <span>{{ item.MIE | money }}</span>
                    </template>
                    <template v-slot:item.RentalCar="{item, headers}">
                      <span>{{ item.RentalCar | money }}</span>
                    </template>
                    <template v-slot:item.Other="{item, headers}">
                      <span>{{ item.Other | money }}</span>
                    </template>
                    <template v-slot:item.totals="{item, headers}">
                      <span>{{ item.totals | money }}</span>
                    </template>
                    <template v-slot:item.Comments="{item, headers}">
                      <span @click="expandRow(item)">
                        {{
                        item.Comments | snippet(50)
                        }}
                      </span>
                    </template>
                    <template v-slot:expanded-item="{item, headers}">
                      <td :colspan="headers.length" class="pa-2">
                        {{
                        item.Comments
                        }}
                      </td>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import ProjectExpenses from '@classes/ProjectExpenses'
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  props: {
    ProjectID: Number,
    DivisionId: Number,
    projectNumber: String,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      search: '',
      panel: [0, 1],
      expanded: [],
      singleExpand: false,
      data: [],
    }
  },
  created() {
    this.loadData()
  },
  filters: {
    money(value) {
      const amt = Number.parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
      if (!value) {
        return parseFloat(0).toFixed(2)
      }
      return (
        '$' +
        ((amt && amt.toLocaleString(undefined, {maximumFractionDigits: 2})) ||
          '0')
      )
      //return Number.parseFloat(value).toFixed(2)
    },
  },
  computed: {
    ...authComputed,
    ...get('projectExpenses',['projectExpenses', 'loading']),
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'dateEdit',
          sortable: false,
          visible: this.userCanEditProjectItems,
        },
        {
          text: 'POV',
          align: 'right',
          value: 'POV',
          sortable: true,
          visible: true,
        },
        {
          text: 'State Car',
          align: 'right',
          value: 'StateCar',
          sortable: true,
          visible: true,
        },
        {
          text: 'Airfare',
          align: 'right',
          value: 'AirFare',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Hotel',
          align: 'right',
          value: 'Hotel',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'M&IE',
          align: 'right',
          value: 'MIE',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Rental Car',
          align: 'right',
          value: 'RentalCar',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Other',
          align: 'right',
          value: 'Other',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Total Expenses',
          align: 'right',
          value: 'totals',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Auditor',
          align: 'left',
          value: 'auditor',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Comments',
          align: 'left',
          value: 'Comments',
          sortable: true,
          visible: true,
          filterable: true,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      if (fields['POV']) {
        fields['POV'] = {
          field: 'POV',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['State Car']) {
        fields['State Car'] = {
          field: 'StateCar',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Airfare']) {
        fields['Airfare'] = {
          field: 'AirFare',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Hotel']) {
        fields['Hotel'] = {
          field: 'Hotel',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['M&IE']) {
        fields['M&IE'] = {
          field: 'MIE',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Rental Car']) {
        fields['Rental Car'] = {
          field: 'RentalCar',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Other']) {
        fields['Other'] = {
          field: 'Other',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Total Expenses']) {
        fields['Total Expenses'] = {
          field: 'totals',
          format: '$#,##0.00',
          sum: true,
        }
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Travel Expenses'})
      return summary
    },
    mappedData() {
      var data = this.projectExpenses.map((item) => {
        return {
          ...item,
          totals:
            (item.POV ? item.POV : 0) +
            (item.StateCar ? item.StateCar : 0) +
            (item.AirFare ? item.AirFare : 0) +
            (item.Hotel ? item.Hotel : 0) +
            (item.MIE ? item.MIE : 0) +
            (item.RentalCar ? item.RentalCar : 0) +
            (item.Other ? item.Other : 0),
          auditor: item.Auditor
            ? item.Auditor.FirstName + ' ' + item.Auditor.LastName
            : '',
        }
      })
      return data
    },
  },
  methods: {
    loadProjectExpenses: call('projectExpenses/loadProjectExpenses'),
    openTravelExpensesDialog() {
      eventBus.$emit('dialogTravelExpenses', new ProjectExpenses())
    },
    openEditDialog(value) {
      eventBus.$emit('dialogTravelExpenses', new ProjectExpenses(value))
    },
    loadData() {
      this.loadProjectExpenses(this.ProjectID)
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
  },
  watch: {},
}
</script>
<style scoped></style>
