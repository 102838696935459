<template>
  <v-row>
    <v-col xs="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title
              v-if="isNew"
              class="headline text-left white--text"
              >Add Project</v-list-item-title
            >
            <v-list-item-title v-else class="headline text-left white--text"
              >Edit Project</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row justify="start" v-if="!isNew">
              <v-col class="py-0" sm="auto">
                <v-text-field
                  v-model="project.ProjectNumber"
                  label="Project No."
                  :disabled="project.DivisionID == 4 || project.DivisionID == 5"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" sm="12" md="6">
                <BaseDatePickerWithText
                  label="Start Date mm/dd/yyyy"
                  v-model="project.AuditStart"
                ></BaseDatePickerWithText>
              </v-col>
              <v-col class="py-0" sm="12" md="6">
                <BaseDatePickerWithText
                  ref="datePrior"
                  label="End Date mm/dd/yyyy"
                  v-model="project.AuditEnd"
                  :rules="[
                    (val) =>
                      new Date(val) >= new Date(project.AuditStart) ||
                      'Project end cannot be before project start',
                  ]"
                ></BaseDatePickerWithText>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" sm="12" md="6">
                <v-select
                  v-model="project.ProjectDetailsID"
                  :items="availableProjectDetails"
                  item-text="Description"
                  item-value="ID"
                  label="Project Details"
                  :rules="[rules.fieldRequired]"
                  clearable
                ></v-select>
              </v-col>
              <v-col class="py-0" sm="12" md="6">
                <v-select
                  v-model="project.DepartmentStrategy"
                  item-text="Description"
                  item-value="Code"
                  :items="availableDepartmentStrategy"
                  label="Dept Strategy"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" sm="12">
                <v-text-field
                  v-model="project.Description"
                  label="Description"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="
                project.DivisionID == 6 ||
                (project.DivisionID == 5 &&
                  selectedProjectDetails.Prefix == 'MR')
              "
            >
              <v-col class="py-0" sm="12" md="6">
                <v-text-field
                  v-model="project.ProjectLicense.LicenseNumber"
                  label="License Number"
                ></v-text-field>
              </v-col>
              <v-col class="py-0" sm="12" md="6">
                <v-select
                  v-model="project.ProjectLicense.LicenseTypeID"
                  item-text="Description"
                  item-value="ID"
                  :items="availableLicenseTypes"
                  label="License Type"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="
                project.DivisionID == 3 && selectedProjectDetails.Prefix == 'BU'
              "
            >
              <v-col class="py-0" sm="12" md="2">
                <v-row>
                  <v-select
                    v-model="project.ProjectBanking.ExamTypeID"
                    item-text="Description"
                    item-value="ID"
                    :items="availableExamTypes"
                    label="Exam Type"
                    clearable
                  ></v-select>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="project.ProjectBanking.LocationNumber"
                    type="number"
                    v-mask="maskNumber"
                    label="Location Number"
                  ></v-text-field>
                </v-row>
                <!-- <v-row>
                </v-row>-->
              </v-col>
              <!-- <v-col class="py-0" sm="12" md="2"></v-col> -->
              <v-col class="py-0" sm="12" md="8">
                <v-textarea
                  v-model="project.ProjectBanking.Comments"
                  label="Comments"
                ></v-textarea>
              </v-col>
              <v-col class="py-0" sm="12" md="2">
                <v-select
                  v-model="project.ProjectBanking.CARRequired"
                  :items="CarrYesNo"
                  label="CAR Required?"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col class="py-0" sm="auto">
                <v-checkbox
                  v-model="project.IsActive"
                  label="Is Active?"
                ></v-checkbox>
              </v-col>
              <!-- <v-col class="py-0" sm="auto">
                <v-checkbox v-model="project.AddToWolfs" label="Add to Wolfs?"></v-checkbox>
              </v-col>-->
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <!-- SAVE BTN -->
      <v-row class="py-2" v-if="isSuperUser || isProjectCreator" justify="end">
        <v-col sm="auto">
          <v-btn @click="validateAndSave" :disabled="saving" color="primary">
            <v-icon left>mdi-content-save</v-icon>Save Project
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<!-- SCRIPTS -->
<script>
import {mask} from 'vue-the-mask'

import {authComputed} from '@state/helpers'
import Rules from '@validation/rules'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  directives: {
    mask,
  },
  model: {
    prop: 'project',
    event: 'change',
  },
  props: {
    project: Object,
  },
  created() {
    this.loadProjectDetail()
    this.loadLuDepartmentStrategy()
    this.loadLuLicenseType()
    this.loadLuExamType()
    // window.addEventListener('scroll', this.handleScroll)
  },
  destroy() {
    // window.removeEventListener('scroll', this.handleScroll)
  },
  components: {},
  data: () => ({
    valid: true,
    CarrYesNo: [
      {value: true, text: 'Yes'},
      {value: false, text: 'No'},
    ],
    rules: Rules,
    maskNumber: '#############',
    currentDate: {
      datePrior: null,
    },
    saving: false,
  }),
  computed: {
    ...authComputed,
    projectDetail: get('projectDetail/projectDetail'),
    availableProjectDetails() {
      let projectDetails = this.projectDetail.filter((pd) => {
        return pd.IsActive && pd.DivisionID == this.project.DivisionID
      })
      if (this.project.ID > 0) {
        let selectedProjectDetails = this.projectDetail.find(
          (pd) => pd.ID == this.project.ProjectDetailID
        )
        if (
          !projectDetails.find((pd) => pd.ID == this.project.ProjectDetailID) &&
          selectedProjectDetails
        ) {
          projectDetails.push(selectedProjectDetails)
        }
      }
      // sort
      projectDetails.sort((a, b) => {
        return a.Description > b.Description ? 1 : -1
      })
      return projectDetails
    },
    luDepartmentStrategy: get('luDepartmentStrategy/luDepartmentStrategy'),
    // Wating on DivisionID field to be added to the luDepartmentStrategy
    availableDepartmentStrategy() {
      let deptStrategy = this.luDepartmentStrategy.filter((ds) => {
        return ds.IsActive && ds.DivisionID == this.project.DivisionID
      })
      if (this.project.ID > 0) {
        let selectedStrategy = this.luDepartmentStrategy.find(
          (ds) => ds.Code == this.project.DepartmentStrategy
        )
        if (
          !deptStrategy.find(
            (ds) => ds.ID == this.project.DepartmentStrategy
          ) &&
          selectedStrategy
        ) {
          deptStrategy.push(selectedStrategy)
        }
      }
      // sort
      deptStrategy.sort((a, b) => {
        return a.Description > b.Description ? 1 : -1
      })
      return deptStrategy
    },
    availableLicenseTypes() {
      let list = this.luLicenseType.filter((item) => {
        return item.IsActive && item.DivisionID == this.project.DivisionID
      })
      if (this.project.ID > 0 && this.project.ProjectLicense.ID > 0) {
        let currentSelection = this.luLicenseType.find(
          (item) => item.ID == this.project.ProjectLicense.LicenseTypeID
        )
        if (
          !list.find(
            (item) => item.ID == this.project.ProjectLicense.LicenseTypeID
          ) &&
          currentSelection
        ) {
          list.push(currentSelection)
        }
      }
      list.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
    luLicenseType: get('luLicenseType/luLicenseType'),
    luExamType: get('luExamType/luExamType'),
    isNew() {
      return !this.project.ID > 0
    },
    selectedProjectDetails() {
      return (
        this.availableProjectDetails.find(
          (p) => p.ID == this.project.ProjectDetailsID
        ) || {}
      )
    },
    selectedStrategy() {
      return (
        this.availableDepartmentStrategy.find(
          (s) => s.ID == this.project.DepartmentStrategy
        ) || {}
      )
    },
    availableExamTypes() {
      // get active for this division
      var examTypes = this.luExamType.filter(
        (i) =>
          i.IsActive &&
          (!i.Prefix || i.Prefix == this.selectedProjectDetails.Prefix)
      )
      // if existing record make sure the previous selection is available
      if (this.project.ProjectBanking.ID > 0) {
        var selectedExamType = this.luExamType.find(
          (d) => d.ID == this.project.ProjectBanking.ExamTypeID
        )
        if (
          !examTypes.find(
            (d) => d.ID == this.project.ProjectBanking.ExamTypeID
          ) &&
          selectedExamType
        ) {
          examTypes.push(selectedExamType)
        }
      }
      // sort by sort order column then by description
      examTypes.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return examTypes
    },
  },
  methods: {
    saveProject: call('project/saveProject'),
    loadProjectDetail: call('projectDetail/loadProjectDetail'),
    loadLuDepartmentStrategy: call(
      'luDepartmentStrategy/loadLuDepartmentStrategy'
    ),
    loadLuLicenseType: call('luLicenseType/loadLuLicenseType'),
    loadLuExamType: call('luExamType/loadLuExamType'),
    validateAndSave() {
      this.validate().then(() => {
        if (this.valid) {
          this.saving = true
          this.saveProject(this.project.getSaveData()).then(
            (res) => {
              this.saving = false
              if (this.project.ID == 0 && res.data) {
                this.$router.push({
                  name: 'project',
                  params: {
                    subEntityId: res.data.SubEntityID,
                    projectId: res.data.ID,
                  },
                })
              }
            },
            (err) => {
              this.saving = false
            }
          )
        }
      })
    },
    async validate() {
      this.$refs.form.validate()
    },
    // handleScroll(event) {
    //   let stickyBanner = document.querySelector(' .v-banner')
    //   if (window.scrollY > 600) {
    //     console.devlog(window.scrollY)
    //     stickyBanner.className += ' stickyBanner'
    //   } else if (window.scrollY < 600) {
    //     stickyBanner.classList.remove('stickyBanner')
    //   }
    // },
  },
  watch: {},
}
</script>

<!-- STYLES -->
<style lang="scss" scoped>
//@import '@design';
.stickyBanner {
  background-color: red !important;
  position: sticky;
  top: 0;
}
</style>
