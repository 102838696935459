import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePanelWithHeader',{attrs:{"headerText":"Excise Stratum"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"auto"}},[_c(VSwitch,{attrs:{"label":"Show Inactive"},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}})],1),_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"12","sm":"auto"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{staticClass:"mt-4 mr-4",attrs:{"cols":"12","sm":"auto"}},[_c('BaseTooltipButton',{attrs:{"small":"","icon":"mdi-plus","iconColor":"white","buttonClass":"primary"},on:{"click":function($event){return _vm.$refs.exciseStratumForm.addExciseStratum()}}},[_vm._v("Add Excise Stratum ")])],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.hasErrorRateStratum},on:{"click":_vm.calculateErrorProjections}},[_vm._v(" Calculate Error Projections ")]),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary","disabled":!_vm.hasAverageErrorStratum},on:{"click":_vm.selectAverageErrorStratum}},[_vm._v(" Calculate Average Error Projections ")])],1)],1),_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.filteredHeaders,"items":_vm.filteredExciseStratum,"search":_vm.searchText,"loading":_vm.exciseStratumLoading,"dense":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-pencil"},on:{"click":function($event){return _vm.$refs.exciseStratumForm.editExciseStratum(item)}}},[_vm._v("Edit Excise Stratum ")])]}},{key:"item.IsActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.IsActive ? 'Active' : 'Inactive')+" ")]}},{key:"item.SampleSize",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.SampleSize)))])]}},{key:"item.StratumTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.StratumTotal)))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-delete"},on:{"click":function($event){return _vm.$refs.exciseStratumDelete.deleteConfirm(item)}}},[_vm._v("Delete Excise Stratum ")])]}}])}),_c('BaseDeleteConfirm',{ref:"exciseStratumDelete",attrs:{"delete":_vm.deleteExciseStratum},on:{"refresh":_vm.loadSelectedProjectExciseStratum}},[_vm._v(" Are you sure you want to delete this excise stratum? ")]),_c('ExciseStratumDialogForm',{ref:"exciseStratumForm",attrs:{"projectId":_vm.selectedProjectExciseStratumId},on:{"refresh":_vm.loadSelectedProjectExciseStratum}}),_c(VDialog,{attrs:{"persistent":"","scrollable":"","width":"550"},model:{value:(_vm.selectAverageErrorStratumDialog),callback:function ($$v) {_vm.selectAverageErrorStratumDialog=$$v},expression:"selectAverageErrorStratumDialog"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary white--text headline"},[_vm._v("Select Stratum")]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_vm._v("Select the stratum you wish to project from.")]),_c(VCol,{attrs:{"sm":"12"}},[_c('ExciseStratum',{attrs:{"label":"Stratum","items":_vm.averageErrorStrata,"rules":[function (v) { return !!v || 'required'; }]},model:{value:(_vm.selectedStratumId),callback:function ($$v) {_vm.selectedStratumId=$$v},expression:"selectedStratumId"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.selectAverageErrorStratumDialog = !_vm.selectAverageErrorStratumDialog}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"disabled":!_vm.selectedStratumId,"outlined":"","color":"primary"},on:{"click":_vm.calculateAverageErrorProjections}},[_vm._v("Calculate")])],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }