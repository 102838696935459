<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Date Information
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="userCanEditProjectItems"
                      sm="auto"
                      class="mt-4"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openDateDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Date Information</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="dataDescription"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Dates"
                        :name="`Project_Dates_${projectNumber}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="dataDescription"
                    :items-per-page="-1"
                    :expanded.sync="expanded"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    single-expand
                    show-expand
                    dense
                  >
                    <template
                      v-slot:group.header.data-table-expand="{on, props}"
                    >
                      <span>Expand</span>
                    </template>
                    <!-- :expanded.sync="expanded" -->
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.dateEdit="{item}"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="openEditDialog(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit Date Information</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.FromDate="{item}">
                      <span>{{ item.FromDate | dateformat }}</span>
                    </template>
                    <template v-slot:item.ToDate="{item}">
                      <span>{{ item.ToDate | dateformat }}</span>
                    </template>

                    <template v-slot:item.Comment="{item}">
                      <span @click="expandRow(item)">
                        {{ item.Comment | snippet(50) }}
                      </span>
                    </template>
                    <template v-slot:expanded-item="{item, headers}">
                      <td :colspan="headers.length" class="pa-2">
                        {{ item.Comment }}
                      </td>
                    </template>
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.projectDateDelete="{item}"
                    >
                      <v-tooltip v-if="item.ID > 0" bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="deleteDate(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete Project Date</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="deleteProjectDateDialog"
      scrollable
      width="715"
    >
      <v-form ref="notesForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text"
              >Are you sure you want to delete this Project Date?</h3
            >
            <small class="white--text"
              >PLEASE BE AWARE any findings associated with it, will also be
              deleted</small
            >
          </v-card-title>
          <v-card-text class="pa-4"> </v-card-text>
          <v-card-actions>
            <v-btn @click="closeDeleteProjectDate" color="primary"
              >Cancel</v-btn
            >
            <v-btn @click="deleteSelectedProjectDate" outlined color="primary"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import ProjectDate from '@classes/ProjectDate'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  model: {
    prop: 'projectDate',
  },
  components: {
    excelExport,
  },
  props: {
    ProjectID: Number,
    projectNumber: String,
    divisionId: Number,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  data() {
    return {
      selectedProjectDate: new ProjectDate(),
      deleteProjectDateDialog: false,
      valid: true,
      search: '',
      expanded: [],
      singleExpand: false,
      panel: [0, 1],
    }
  },
  created() {
    this.loadDataDate()
    // eventBus.$on('SaveDate', (pd) => {
    //   this.loadDataDate()
    //   eventBus.$emit('closeProjectDate')
    // })
    eventBus.$on('SaveFinding', () => {
      this.loadDataDate()
      // setTimeout(this.loadDataDate(), 500)
    })
  },
  beforeDestroy() {
    eventBus.$off('SaveFinding')
  },
  computed: {
    ...get('projectDate', ['projectDate', 'loading']),
    luDateType: get('luDateType/luDateType'),
    luSentTo: get('luSentTo/luSentTo'),
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'dateEdit',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {
          text: 'Date Type',
          align: 'left',
          value: 'dateType',
          sortable: true,
          visible: true,
          filterable: true,
        },
        {
          text: 'From Date',
          align: 'left',
          value: 'FromDate',
          sortable: true,
          visible: true,
          filterable: true,
        },
        {
          text: 'To Date',
          align: 'left',
          value: 'ToDate',
          sortable: true,
          visible: !this.divisionId == 4 || !this.divisionId == 3,
          filterable: true,
        },
        {
          text: 'Sent To',
          align: 'left',
          value: 'sentTo',
          sortable: true,
          visible: !this.divisionId == 3,
          filterable: true,
        },
        {
          text: 'Comments',
          align: 'left',
          value: 'Comment',
          sortable: true,
          visible: true,
          filterable: true,
        },
        {
          text: 'Entered By',
          align: 'left',
          sortable: true,
          visible: true,
          value: 'fullName',
          filterable: true,
        },
        {
          text: 'Modified By',
          align: 'left',
          sortable: true,
          visible: true,
          value: 'modifiedBy',
          filterable: true,
        },
        {
          text: '',
          align: 'left',
          value: 'projectDateDelete',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Dates'})
      return summary
    },
    dataDescription() {
      var data = this.projectDate.map((dd) => {
        let dateType = this.luDateType.find((dt) => dt.ID == dd.DateType)
        return {
          ...dd,
          fullName: dd.EnteredByNavigation
            ? dd.EnteredByNavigation.FirstName +
              ' ' +
              dd.EnteredByNavigation.LastName
            : 'unk.',
          modifiedBy: dd.ModifiedByNavigation
            ? dd.ModifiedByNavigation.FirstName +
              ' ' +
              dd.ModifiedByNavigation.LastName
            : '',
          dateType: dateType
            ? this.divisionId == 5 || this.divisionId == 4
              ? (dateType.SortOrder.toString().length < 2 ? '0' : '') +
                dateType.SortOrder +
                ' ' +
                dateType.DateType
              : dateType.DateType
            : '',
          sentTo: this.luSentTo.find((st) => st.ID == dd.SentTo)
            ? this.luSentTo.find((st) => st.ID == dd.SentTo).SentTo
            : '',
        }
      })
      return data
    },
  },
  methods: {
    deleteProjectDate: call('projectDate/deleteProjectDate'),
    loadProjectDate: call('projectDate/loadProjectDate'),
    openDateDialog() {
      eventBus.$emit('dialogDate', new ProjectDate())
    },
    openEditDialog(value) {
      eventBus.$emit('dialogDate', new ProjectDate(value))
    },
    loadDataDate() {
      this.loadProjectDate(this.ProjectID)
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
    deleteDate(item) {
      this.selectedProjectDate = new ProjectDate(item)
      this.deleteProjectDateDialog = true
    },
    closeDeleteProjectDate() {
      this.deleteProjectDateDialog = false
    },
    deleteSelectedProjectDate() {
      this.deleteProjectDate(this.selectedProjectDate.root())
        .then((res) => {
          this.selectedProjectDate = new ProjectDate()
          eventBus.$emit('SaveFinding')
          this.loadDataDate()
          this.deleteProjectDateDialog = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  watch: {},
}
</script>
<style>
.theme--light.v-data-table tbody tr.expanded.expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgba(50, 50, 50, 0.5),
    inset 0px -4px 6px -5px rgba(50, 50, 50, 0.5);
}
</style>
