<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Collections Information
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="userCanEditProjectItems"
                      sm="auto"
                      class="mt-4"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openCollectionDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Collection Information</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="collections"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Collections"
                        :name="`Project_Collections_${projectNumber}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="collections"
                    :items-per-page="-1"
                    :expanded.sync="expanded"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    single-expand
                    show-expand
                    dense
                  >
                    <template
                      v-slot:group.header.data-table-expand="{on, props}"
                    >
                      <span>Expand</span>
                    </template>
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.dateEdit="{item}"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="openEditDialog(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit Collections Information</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.CollectionsValue="{item, headers}">
                      <span>{{ item.CollectionsValue | moneyFormat }}</span>
                    </template>
                    <template v-slot:item.ReceivedDate="{item}">
                      {{ item.ReceivedDate | dateformat }}
                    </template>
                    <template v-slot:item.Comments="{item, headers}">
                      <span @click="expandRow(item)">
                        {{ item.Comments | snippet(50) }}
                      </span>
                    </template>
                    <template v-slot:expanded-item="{item, headers}">
                      <td :colspan="headers.length" class="pa-2">
                        {{ item.Comments }}
                      </td>
                    </template>
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.collectionDelete="{item}"
                    >
                      <v-tooltip v-if="item.ID > 0" bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="deleteCollection(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete Collection</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="deleteCollectionDialog" scrolable width="675">
      <v-form ref="notesForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text"
              >Are you sure you want to delete this Collection?</h3
            >
          </v-card-title>
          <v-card-text class="pa-4"> </v-card-text>
          <v-card-actions>
            <v-btn @click="closeDeleteCollection" color="primary">Cancel</v-btn>
            <v-btn @click="deleteSelectedCollection" outlined color="primary"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import ProjectCollections from '@classes/ProjectCollections'
import excelExport from '@components/excelExport'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  model: {
    prop: 'projectCollections',
  },
  components: {
    excelExport,
  },
  props: {
    ProjectID: Number,
    projectNumber: String,
    divisionId: Number,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  data() {
    return {
      selectedCollection: new ProjectCollections(),
      valid: true,
      search: '',
      expanded: [],
      singleExpand: false,
      panel: [0, 1],
      data: [],
      deleteCollectionDialog: false,
      loading: false,
    }
  },
  created() {
    this.loadData()
    eventBus.$on('SaveCollections', (sc) => {
      this.loadData()
      eventBus.$emit('closeCollection')
    })
  },
  beforeDestroy() {
    eventBus.$off('SaveCollections')
  },
  computed: {
    luFindingType: get('luFindingType/luFindingType'),
    luCounty: get('luCounty/luCounty'),
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'dateEdit',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {
          text: 'Collection Type',
          align: 'left',
          value: 'collectionType',
          sortable: true,
          visible: true,
          filterable: true,
        },
        {
          text: 'Received Date',
          align: 'left',
          value: 'ReceivedDate',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Amount',
          align: 'right',
          value: 'CollectionsValue',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'County',
          align: 'left',
          value: 'county',
          sortable: 'true',
          visible: this.divisionId == 5,
          filterable: true,
        },
        {
          text: 'Year',
          align: 'left',
          value: 'CollectionsYear',
          sortable: 'true',
          visible: this.divisionId == 5,
          filterable: true,
        },
        {
          text: 'Comments',
          align: 'left',
          value: 'Comments',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'NOVC Number',
          align: 'left',
          value: 'NovcNumber',
          sortable: 'true',
          visible: this.divisionId == 5,
          filterable: true,
        },
        {
          text: 'Entered By',
          align: 'left',
          sortable: true,
          visible: true,
          value: 'fullName',
          filterable: true,
        },
        {
          text: '',
          align: 'left',
          value: 'collectionDelete',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      if (fields['Amount']) {
        fields['Amount'] = {
          field: 'CollectionsValue',
          format: '$#,##0.00',
          sum: true,
        }
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Collections'})
      return summary
    },
    collections() {
      var data = this.data.map((fd) => {
        return {
          ...fd,
          fullName: fd.EnteredByNavigation
            ? fd.EnteredByNavigation.FirstName +
              ' ' +
              fd.EnteredByNavigation.LastName
            : 'unk.',
          collectionType: this.luFindingType.find(
            (ct) => ct.ID == fd.CollectionsFindingTypeID
          )
            ? this.luFindingType.find(
                (ct) => ct.ID == fd.CollectionsFindingTypeID
              ).FindingType
            : '',
          county: this.luCounty.find((c) => c.ID == fd.CountyID)
            ? this.luCounty.find((c) => c.ID == fd.CountyID).Name
            : '',
          comment: this.$filters.Snippet80(fd.Comments),
        }
      })
      return data
    },
  },
  methods: {
    deleteProjectCollections: call(
      'projectCollections/deleteProjectCollections'
    ),
    openCollectionDialog() {
      eventBus.$emit('dialogCollections', new ProjectCollections())
    },
    openEditDialog(value) {
      eventBus.$emit('dialogCollections', new ProjectCollections(value))
    },
    deleteCollection(item) {
      this.selectedCollection = new ProjectCollections(item)
      this.deleteCollectionDialog = true
    },
    closeDeleteCollection() {
      this.deleteCollectionDialog = false
    },
    deleteSelectedCollection() {
      this.deleteProjectCollections(this.selectedCollection.root())
        .then((res) => {
          this.selectedCollection = new ProjectCollections()
          this.loadData()
          this.deleteCollectionDialog = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
    loadData() {
      this.loading = true
      axios
        .get(`ProjectCollections/ByProject/${this.ProjectID}`)
        .then((res) => {
          this.data = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
  },
  watch: {},
}
</script>
<style scoped></style>
