<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary headline text-left white--text">
                Budget Hours
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="userCanEditProjectItems" sm="auto" class="mt-4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openBudgetDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Budget Hours</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="dataDescription"
                        :summaryData="summaryData"
                        :exportFields="excelFields"
                        worksheet="Project Budget Hours"
                        :name="`Project_Budget_Hours_${projectNumber}.xlsx`"
                      >Export</excelExport>
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="dataDescription"
                    :items-per-page="-1"
                    :expanded.sync="expanded"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    single-expand
                    show-expand
                    dense
                  >
                    <template v-slot:group.header.data-table-expand="{on, props}">
                      <span>Expand</span>
                    </template>
                    <template v-if="userCanEditProjectItems" v-slot:item.budgetEdit="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn small color="primary" v-on="on" icon @click="openEditDialog(item)">
                            <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Budget Information</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.Approved="{item}">
                      {{
                      item.Approved ? 'Yes' : 'No'
                      }}
                    </template>
                    <template v-slot:item.ApprovalDate="{item}">
                      {{
                      item.ApprovalDate | dateformat
                      }}
                    </template>
                    <template v-slot:item.Comments="{item, headers}">
                      <span @click="expandRow(item)">
                        {{
                        item.Comments | snippet(50)
                        }}
                      </span>
                    </template>
                    <template v-slot:item.approvedBy="{item, headers}">
                      <span v-if="item && item.ApprovedByNavigation">
                        {{ item.ApprovedByNavigation.FirstName }}
                        {{ item.ApprovedByNavigation.LastName }}
                      </span>
                    </template>
                    <template v-slot:item.overUnder="{item, headers}">
                      <span
                        :style="{color: item.overUnder == 'Over'? 'red': 'green'}"
                      >{{ item.overUnder}}</span>
                    </template>

                    <template v-slot:expanded-item="{item, headers}">
                      <td :colspan="headers.length" class="pa-2">
                        {{
                        item.Comments
                        }}
                      </td>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import ProjectBudgetedHours from '@classes/ProjectBudgetedHours'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  props: {
    ProjectID: Number,
    projectNumber: String,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      search: '',
      panel: [0, 1],
      data: [],
    }
  },
  created() {
    this.loadBudgetData()
    if (this.ProjectID) {
      this.loadProjectTimeSheetEntries(this.ProjectID)
    }
    eventBus.$on('SaveBudget', (pb) => {
      this.loadBudgetData()
      eventBus.$emit('closeProjectBudget')
    })
  },
  beforeDestroy() {
    eventBus.$off('SaveBudget')
  },
  computed: {
    projectTimeSheetEntries: get('timeSheetEntry/projectTimeSheetEntries'),
    ...get('projectBudgetedHours', ['projectBudgetedHours', 'loading']),
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'budgetEdit',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {
          text: 'Code',
          align: 'left',
          value: 'Activity.Code',
          sortable: true,
          visible: true,
          filterable: true,
        },
        {
          text: 'Activity',
          align: 'left',
          value: 'Activity.Description',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Std. Budget Hours',
          align: 'left',
          value: 'StandardBudgetHours',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Budgeted Hours',
          align: 'left',
          value: 'BudgetedHours',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Approved?',
          align: 'left',
          value: 'Approved',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Approver',
          align: 'left',
          value: 'approvedBy',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Approved Date',
          align: 'left',
          value: 'ApprovalDate',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Comments',
          align: 'left',
          value: 'Comments',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Recorded Time',
          align: 'left',
          value: 'recordedTime',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Over Under',
          align: 'left',
          value: 'overUnder',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Variance',
          align: 'left',
          value: 'variance',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Variance %',
          align: 'left',
          value: 'variancePercent',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      if (fields['Budgeted Hours']) {
        fields['Budgeted Hours'] = {
          field: 'BudgetedHours',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Budget Hours'})
      return summary
    },
    mappedTime() {
      var hours = this.projectTimeSheetEntries.map((item) => {
        return {
          ...item,
          activityCode: item.Code.Code,
          activityDescription: item.Code.Description,
          billable: item.BillableCategory
            ? item.BillableCategory.Description
            : 'No',
          userId: item.TimeSheet.UserID,
          user:
            item.TimeSheet.User.FirstName + ' ' + item.TimeSheet.User.LastName,
        }
      })
      let data = []
      let activities = [...new Set(hours.map((item) => item.activityCode))]
      activities.forEach((activity) => {
        let matches = hours.filter((item) => item.activityCode == activity)
        if (matches[0]) {
          let entry = {
            activityCode: matches[0].activityCode,
            activityDescription: matches[0].activityDescription,
            hours: matches.reduce((prev, item) => {
              return prev + item.Hours
            }, 0),
          }
          data.push(entry)
        }
      })
      return data
    },
    dataDescription() {
      var data = this.projectBudgetedHours.map((item) => {
        let time = this.mappedTime.find(
          (time) => time.activityCode == item.Activity.Code
        )
        return {
          ...item,
          approvedBy: item.ApprovedByNavigation
            ? item.ApprovedByNavigation.FirstName +
              ' ' +
              item.ApprovedByNavigation.LastName
            : '',
          time: time,
          recordedTime: time ? time.hours : 0,
          overUnder: time
            ? time.hours > item.BudgetedHours
              ? 'Over'
              : 'Under'
            : 'None',
          variance: time
            ? (item.BudgetedHours - time.hours).toFixed(2)
            : (item.BudgetedHours - 0).toFixed(2),
          variancePercent: time
            ? (
                ((item.BudgetedHours - time.hours) / item.BudgetedHours) *
                100
              ).toFixed(2) + ' %'
            : '0.00 %',
        }
      })
      return data
    },
  },
  methods: {
    loadProjectTimeSheetEntries: call(
      'timeSheetEntry/loadProjectTimeSheetEntries'
    ),
    loadProjectBudgetedHours: call(
      'projectBudgetedHours/loadProjectBudgetedHours'
    ),
    openBudgetDialog() {
      eventBus.$emit('dialogBudget', new ProjectBudgetedHours())
    },
    openEditDialog(value) {
      eventBus.$emit('dialogBudget', new ProjectBudgetedHours(value))
    },
    loadBudgetData() {
      this.loadProjectBudgetedHours(this.ProjectID)
      // axios
      //   .get(`ProjectBudgetedHours/ByProject/${this.ProjectID}`)
      //   .then((res) => {
      //     this.data = res.data
      //   })
      //   .catch((err) => {})
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
  },
  watch: {},
}
</script>
<style scoped></style>
