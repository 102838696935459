<template>
  <BasePanelWithHeader headerText="Excise Stratum">
    <template v-slot:content>
      <v-row>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
        </v-col>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="$refs.exciseStratumForm.addExciseStratum()"
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add Excise Stratum
          </BaseTooltipButton>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            :disabled="!hasErrorRateStratum"
            @click="calculateErrorProjections"
          >
            Calculate Error Projections
          </v-btn>
          <!-- </v-col>
        <v-col> -->
          <v-btn
            class="ml-2"
            color="primary"
            :disabled="!hasAverageErrorStratum"
            @click="selectAverageErrorStratum"
          >
            Calculate Average Error Projections
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredExciseStratum"
        :search="searchText"
        class="pt-4"
        :loading="exciseStratumLoading"
        dense
      >
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="$refs.exciseStratumForm.editExciseStratum(item)"
            iconColor="primary"
            icon="mdi-pencil"
            >Edit Excise Stratum
          </BaseTooltipButton>
        </template>
        <template v-slot:item.IsActive="{item}">
          {{ item.IsActive ? 'Active' : 'Inactive' }}
        </template>
                <template v-slot:item.SampleSize="{item}">
          <span>{{ item.SampleSize | moneyFormat }}</span>
        </template>
                <template v-slot:item.StratumTotal="{item}">
          <span>{{ item.StratumTotal | moneyFormat }}</span>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.exciseStratumDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete Excise Stratum
          </BaseTooltipButton>
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="exciseStratumDelete"
        :delete="deleteExciseStratum"
        @refresh="loadSelectedProjectExciseStratum"
      >
        Are you sure you want to delete this excise stratum?
      </BaseDeleteConfirm>
      <ExciseStratumDialogForm
        ref="exciseStratumForm"
        @refresh="loadSelectedProjectExciseStratum"
        :projectId="selectedProjectExciseStratumId"
      >
      </ExciseStratumDialogForm>
      <v-dialog
        persistent
        v-model="selectAverageErrorStratumDialog"
        scrollable
        width="550"
      >
        <v-card class="elevation-3">
          <v-card-title class="primary white--text headline"
            >Select Stratum</v-card-title
          >
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="12"
                >Select the stratum you wish to project from.</v-col
              >
              <v-col sm="12">
                <ExciseStratum
                  label="Stratum"
                  v-model="selectedStratumId"
                  :items="averageErrorStrata"
                  :rules="[(v) => !!v || 'required']"
                ></ExciseStratum>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="
                selectAverageErrorStratumDialog = !selectAverageErrorStratumDialog
              "
              >Cancel</v-btn
            >
            <v-btn
              @click="calculateAverageErrorProjections"
              :disabled="!selectedStratumId"
              outlined
              color="primary"
              >Calculate</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ExciseStratumDialogForm from '@components/form/add-edit/ExciseStratumDialog'
import ExciseStratum from '@components/select/a-single/ExciseStratum'
export default {
  components: {
    ExciseStratumDialogForm,
    ExciseStratum,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
    selectedStratumId: null,
    selectAverageErrorStratumDialog: false,
  }),
  props: {},
  created() {
    this.loadSelectedProjectExciseStratum()
  },
  computed: {
    ...get('project', [
      'selectedProjectExciseStratum',
      'selectedProjectExciseStratumId',
      'exciseStratumLoading',
    ]),
    hasErrorRateStratum() {
      return (
        this.selectedProjectExciseStratum.filter((x) => x.ProjectionTypeID == 3)
          .length > 0
      )
    },
    averageErrorStrata() {
      return this.selectedProjectExciseStratum.filter(
        (x) => x.ProjectionTypeID == 2
      )
    },
    hasAverageErrorStratum() {
      return this.averageErrorStrata.length > 0
    },
    filteredExciseStratum() {
      var list = this.selectedProjectExciseStratum.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: 'Stratum Number',
          align: 'center',
          sortable: true,
          value: 'StratumNumber',
          visible: true,
        },
        {
          text: 'Project ID',
          align: 'center',
          sortable: true,
          value: 'ProjectID',
          visible: false,
        },
        {
          text: 'Stratum Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Projection Type',
          align: 'center',
          sortable: true,
          value: 'ProjectionType.Description',
          visible: true,
        },
        {
          text: 'Sample Stratum Amount',
          align: 'center',
          sortable: true,
          value: 'SampleSize',
          visible: true,
        },
        {
          text: 'Stratum Population Amount',
          align: 'center',
          sortable: true,
          value: 'StratumTotal',
          visible: true,
        },
        {
          text: 'Number of Sample Months',
          align: 'center',
          sortable: true,
          value: 'NumberMonths',
          visible: true,
        },
        {
          text: 'Is Appended',
          align: 'center',
          sortable: true,
          value: 'IsAppended',
          visible: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('exciseStratum', ['deleteExciseStratum']),
    ...call('exciseExceptions', [
      'projectionErrorRates',
      'projectionAverageError',
    ]),
    ...call('project', ['loadSelectedProjectExciseStratum']),
    calculateErrorProjections() {
      this.projectionErrorRates(this.selectedProjectExciseStratumId).then(
        (res) => {
          this.loadSelectedProjectExciseStratum()
        }
      )
    },
    selectAverageErrorStratum() {
      if (this.averageErrorStrata.length > 1) {
        this.selectAverageErrorStratumDialog = true
      } else if (this.averageErrorStrata.length == 1) {
        this.selectedStratumId = this.averageErrorStrata[0].ID
        this.calculateAverageErrorProjections()
      }
    },
    calculateAverageErrorProjections() {
      this.selectAverageErrorStratumDialog = false
      this.projectionAverageError(this.selectedStratumId).then((res) => {
        this.selectedStratumId = null
        this.loadSelectedProjectExciseStratum()
      })
    },
  },
}
</script>
<style>
</style>