<template>
  <div>
    <ExciseExceptionsFlat :projectId="projectId"></ExciseExceptionsFlat>
  </div>
</template>
<script>
import ExciseExceptionsFlat from '@components/reports/exciseExceptionsFlat'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    projectId: {
      required: true,
      type: Number,
    },
  },
  components: {
    ExciseExceptionsFlat,
  },
  filters: {},
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped>
</style>