<template>
  <BasePanelWithHeader headerText="AdHoc Excise Exceptions Report">
    <!-- <v-data-table
        :headers="filteredHeaders"
        :items="adhocExciseExceptionsFlat"
        :server-items-length="adhocTotal"
        :loading="adhocLoading"
        :sort-by.sync="adhocSortBy"
        :sort-desc.sync="adhocDescending"
        :page.sync="adhocPage"
        :items-per-page.sync="adhocRowsPerPage"
        :footer-props="{itemsPerPageOptions: [5, 10, 25, 50]}"
        class="pt-4"
        dense
      >
      </v-data-table> -->
    <BaseServerSyncDataTable
      :headers="filteredHeaders"
      :items="adhocExciseExceptionsFlat"
      :searchTotal="adhocTotal"
      :loading="adhocLoading"
      :searchSortBy.sync="adhocSortBy"
      :searchDescending.sync="adhocDescending"
      :searchPage.sync="adhocPage"
      :searchRowsPerPage.sync="adhocRowsPerPage"
      multi-sort
      :show-group-by="!adhocPivotIncludePivot && showGroupBy"
      :groupBy.sync="groupBy"
      :groupDesc.sync="adhocGroupDescending"
    >
      <template v-slot:searchParams>
        <ExciseExceptionsFlatParams
          :headers="headers"
          :projectId="projectId"
        ></ExciseExceptionsFlatParams>
        <pivotOptions
          :includePivot.sync="adhocPivotIncludePivot"
          :pivotFirst.sync="adhocPivotFirst"
          :pivotName.sync="adhocPivotName"
          :rowFields.sync="adhocPivotRowFields"
          :columnFields.sync="adhocPivotColumnFields"
          :dataFields.sync="adhocPivotDataFields"
          :excludeFields.sync="adhocPivotExcludeFields"
          :filterFields.sync="adhocPivotFilterFields"
          :filteredHeaders="filteredHeaders"
        ></pivotOptions>
      </template>
      <template v-slot:item.InvoiceDate="{item}">
        <span>{{ item.InvoiceDate | dateformat }}</span>
      </template>
      <template v-slot:item.AuditStart="{item}">
        <span>{{ item.AuditStart | dateformat }}</span>
      </template>
      <template v-slot:item.AuditEnd="{item}">
        <span>{{ item.AuditEnd | dateformat }}</span>
      </template>
      <template v-slot:item.TaxableAmount="{item}">
        <span>{{ item.TaxableAmount | moneyFormat }}</span>
      </template>
      <template v-slot:item.TaxPaid="{item}">
        <span>{{ item.TaxPaid | moneyFormat }}</span>
      </template>
      <template v-slot:item.TaxRate="{item}">
        <span>{{ formatPercent(item.TaxRate) }}</span>
      </template>
      <template v-slot:item.TaxDue="{item}">
        <span>{{ item.TaxDue | moneyFormat }}</span>
      </template>
      <template v-slot:item.AuditedTax="{item}">
        <span>{{ item.AuditedTax | moneyFormat }}</span>
      </template>
      <template v-slot:item.ReportedTax="{item}">
        <span>{{ item.ReportedTax | moneyFormat }}</span>
      </template>
    </BaseServerSyncDataTable>
    <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="downloadAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate Report
        </v-btn>
      </v-col>
    </v-row>
    <SavedQueries
      reportName="ExciseExceptions"
      :searchParams="paramsToSave"
      :additionalQueries="staticReports"
      @selectQuery="selectQuery"
    ></SavedQueries>
  </BasePanelWithHeader>
</template>

<script>
import pivotOptions from '@components/reports/pivotOptions'
import ExciseExceptionsFlatParams from '@components/reports/exciseExceptionsFlatParams'
import SavedQueries from '@components/reports/savedQueries'
import {ExciseExceptionReports} from '@defaults/StaticReports'
import {get, sync, commit, call} from 'vuex-pathify'
import {debounce, range} from 'lodash'
//templateTODO: select default sortBy field
var defaultSortField = 'ID'
export default {
  name: 'ExciseExceptionsFlatReport',
  components: {
    pivotOptions,
    ExciseExceptionsFlatParams,
    SavedQueries,
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    staticReports: ExciseExceptionReports,
    headers: [
      {
        text: 'Entity Name',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'EntityName',
        visible: false,
      },
      {
        text: 'Project ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ProjectID',
        visible: false,
      },
      {
        text: 'Project Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ProjectNumber',
        visible: false,
      },
      {
        text: 'License Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'LicenseNumber',
        visible: false,
      },
      {
        text: 'License Type ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'LicenseTypeID',
        visible: false,
      },
      {
        text: 'License Type',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'LicenseType',
        visible: false,
      },
      {
        text: 'Audit Start',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AuditStart',
        visible: false,
      },
      {
        text: 'Audit End',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AuditEnd',
        visible: false,
      },
      {
        text: 'Project Description',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ProjectDescription',
        visible: false,
      },
      {
        text: 'Stratum ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StratumID',
        visible: false,
      },
      {
        text: 'Stratum Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StratumNumber',
        visible: false,
      },
      {
        text: 'Stratum Description',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StratumDescription',
        visible: false,
      },
      {
        text: 'Stratum Status',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StratumStatus',
        visible: false,
      },
      {
        text: 'Projection Type ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ProjectionTypeID',
        visible: false,
      },
      {
        text: 'Projection Type',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ProjectionType',
        visible: false,
      },
      {
        text: 'Sample Size',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'SampleSize',
        visible: false,
      },
      {
        text: 'Stratum Total',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StratumTotal',
        visible: false,
      },
      {
        text: 'Number of Months',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'NumberMonths',
        visible: false,
      },
      {
        text: 'Stratum Is Active',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'StratumIsActive',
        visible: false,
      },
      {
        text: 'Accounting Period',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AccountingPeriod',
        visible: true,
      },
      {
        text: 'Reference',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'Reference',
        visible: true,
      },
      {
        text: 'Vendor Customer',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'VendorCustomer',
        visible: true,
      },
      {
        text: 'Invoice Date',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'InvoiceDate',
        visible: true,
      },
      {
        text: 'Document Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'DocumentNumber',
        visible: true,
      },
      {
        text: 'Description',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'Description',
        visible: true,
      },
      {
        text: 'Taxable Amount',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxableAmount',
        visible: true,
      },
      {
        text: 'Tax Rate',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxRate',
        visible: true,
      },
      {
        text: 'Tax Paid',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxPaid',
        visible: true,
      },
      {
        text: 'Audited Tax',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'AuditedTax',
        visible: false,
      },
      {
        text: 'Reported Tax',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ReportedTax',
        visible: false,
      },
      {
        text: 'Tax Due',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxDue',
        visible: true,
      },
      {
        text: 'Error',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'Error',
        visible: false,
      },
      {
        text: 'Delivered To County ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'DeliveredToCountyID',
        visible: true,
      },
      {
        text: 'Delivered To County',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'DeliveredToCounty',
        visible: false,
      },
      {
        text: 'Delivered To City ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'DeliveredToCityID',
        visible: false,
      },
      {
        text: 'Delivered To City',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'DeliveredToCity',
        visible: false,
      },
      {
        text: 'Special District ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'SpecialDistrictID',
        visible: false,
      },
      {
        text: 'Special District',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'SpecialDistrict',
        visible: false,
      },
      {
        text: 'Issue ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'IssueID',
        visible: false,
      },
      {
        text: 'Issue',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'Issue',
        visible: false,
      },
      {
        text: 'Issue Number',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'IssueNumber',
        visible: true,
      },
      {
        text: 'Statute',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'Statute',
        visible: false,
      },
      {
        text: 'Rules Reg',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'RulesReg',
        visible: false,
      },
      {
        text: 'Subject To Interest',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'SubjectToInterest',
        visible: false,
      },
      {
        text: 'Transaction Type ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TransactionTypeID',
        visible: false,
      },
      {
        text: 'Transaction Type',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TransactionType',
        visible: false,
      },
      {
        text: 'Tax Type ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxTypeID',
        visible: false,
      },
      {
        text: 'Tax Type',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'TaxType',
        visible: false,
      },

      {
        text: 'Is Active',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'IsActive',
        visible: false,
      },
      {
        text: 'Status',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'Status',
        visible: false,
      },
      // {
      //   text: 'Is Appended',
      //   align: 'center',
      //   sortable: true,
      //(groupable: true),
      //   value: 'IsAppended',
      //   visible: false,
      // },
      {
        text: 'Created By ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CreatedByID',
        visible: false,
      },
      {
        text: 'Created By',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'CreatedBy',
        visible: false,
      },
      {
        text: 'Modified By ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ModifiedByID',
        visible: false,
      },
      {
        text: 'Modified By',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ModifiedBy',
        visible: false,
      },
      {
        text: 'ID',
        align: 'center',
        sortable: true,
        groupable: true,
        value: 'ID',
        visible: true,
      },
    ],
  }),
  created() {
    this.adhocSelectedFields = this.headers
      .filter((h) => h.visible)
      .map((h) => h.value)
    if (this.projectId) {
      this.adhocProjectID = [this.projectId]
    }
    this.displayAdhocExciseExceptionsFlat()
  },
  watch: {
    adhocPivotIncludePivot(nval, oval) {
      if (nval) {
        this.adhocGroupBy = null
        this.adhocGroupDescending = null
      }
    },
    adhocSelectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.adhocQueryIsModified = true
        }
      },
      deep: true,
    },
  },
  computed: {
    ...get('exciseExceptionsFlat', [
      'adhocExciseExceptionsFlat',
      'adhocLoading',
      'adhocParameters',
    ]),
    ...sync('exciseExceptionsFlat', [
      'adhocTotal',
      'adhocPage',
      'adhocSortBy',
      'adhocDescending',
      'adhocGroupBy',
      'adhocGroupDescending',
      'adhocRowsPerPage',
      'adhocPivotIncludePivot',
      'adhocPivotFirst',
      'adhocPivotName',
      'adhocPivotRowFields',
      'adhocPivotColumnFields',
      'adhocPivotExcludeFields',
      'adhocPivotDataFields',
      'adhocPivotFilterFields',
      'adhocSelectedFields',
      'adhocQueryIsModified',
      'adhocQueryName',
      'adhocText',
      'adhocProjectID',
      'adhocStratumID',
      'adhocStratumStatus',
      'adhocProjectionTypeID',
      'adhocIssueID',
      'adhocTransactionTypeID',
      'adhocTaxTypeID',
      'adhocDeliveredToCityID',
      'adhocDeliveredToCountyID',
      'adhocSpecialDistrictID',
      'adhocStatus',
      'adhocCreatedByID',
      'adhocModifiedByID',
    ]),
    //this can be used for filtering them down
    filteredHeaders: {
      get() {
        let list = this.headers.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.adhocSelectedFields.indexOf(a.value) -
            this.adhocSelectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    paramsToSave() {
      var params = JSON.parse(JSON.stringify(this.adhocParameters))
      params.projectID = []
      params.stratumID = []
      return params
    },
    showGroupBy() {
      return this.headers.filter((x) => x.groupable).length > 0
    },
    groupBy: {
      get() {
        if (this.adhocGroupBy) {
          return [this.adhocGroupBy]
        } else {
          return []
        }
      },
      set(nval) {
        if (nval) {
          if (Array.isArray(nval)) {
            if (nval.length > 0) {
              this.adhocGroupBy = nval[0]
            } else {
              this.adhocGroupBy = null
            }
          } else {
            this.adhocGroupBy = nval
          }
        } else {
          this.adhocGroupBy = null
        }
      },
    },
  },
  methods: {
    ...call('exciseExceptionsFlat', [
      'displayAdhocExciseExceptionsFlat',
      'downloadAdhoc',
    ]),
    selectQuery(item) {
      if (item && item.Query) {
        let query = JSON.parse(item.Query)
        if (Array.isArray(query.sortDirection)) {
          var descending = query.sortDirection.map((x) =>
            x == 'desc' ? true : false
          )
        } else {
          descending = query.sortDirection == 'desc' ? true : false
        }
        this.adhocDescending = descending
        this.adhocSortBy = query.sortProperty
        this.adhocGroupDescending =
          query.groupByDirection == 'desc' ? true : false
        this.adhocGroupBy = query.groupByProperty
        this.adhocText = query.searchText || ''
        this.adhocQueryName = query.queryName || item.Title
        this.adhocSelectedFields = query.includeFields || []
        if (!query.pivotOptions) {
          this.adhocPivotIncludePivot = query.includePivot
          this.adhocPivotFirst = query.pivotFirst
          this.adhocPivotName = query.pivotName
          this.adhocPivotRowFields = query.rowFields || []
          this.adhocPivotColumnFields = query.columnFields || []
          this.adhocPivotExcludeFields = query.excludeFields || []
          this.adhocPivotDataFields = query.dataFields || []
          this.adhocPivotFilterFields = query.filterFields || []
        } else {
          this.adhocPivotIncludePivot = query.pivotOptions.includePivot
          this.adhocPivotFirst = query.pivotOptions.pivotFirst
          this.adhocPivotName = query.pivotOptions.pivotName
          this.adhocPivotRowFields = query.pivotOptions.rowFields || []
          this.adhocPivotColumnFields = query.pivotOptions.columnFields || []
          this.adhocPivotExcludeFields = query.pivotOptions.excludeFields || []
          this.adhocPivotDataFields = query.pivotOptions.dataFields || []
          this.adhocPivotFilterFields = query.pivotOptions.filterFields || []
        }

        // this.adhocStartDate = query.startDate
        // this.adhocEndDate = query.endDate

        // this.adhocID = query.iD || [],
        if (this.projectId) {
          this.adhocProjectID = [this.projectId]
        }
        // this.adhocProjectNumber = query.projectNumber || [],
        // this.adhocProjectDescription = query.projectDescription || [],
        // this.adhocStratumNumber = query.stratumNumber || [],
        // this.adhocStratumDescription = query.stratumDescription || [],
        this.adhocStratumStatus = query.stratumStatus || []
        this.adhocProjectionTypeID = query.projectionTypeID || []
        // this.adhocProjectionType = query.projectionType || [],
        // this.adhocStratumIsActive = query.stratumIsActive || [],
        // this.adhocDocumentNumber = query.documentNumber || [],
        this.adhocIssueID = query.issueID || []
        // this.adhocIssue = query.issue || [],
        this.adhocTransactionTypeID = query.transactionTypeID || []
        // this.adhocTransactionType = query.transactionType || [],
        this.adhocTaxTypeID = query.taxTypeID || []
        // this.adhocTaxType = query.taxType || [],
        this.adhocDeliveredToCityID = query.deliveredToCityID || []
        // this.adhocDeliveredToCity = query.deliveredToCity || [],
        this.adhocDeliveredToCountyID = query.deliveredToCountyID || []
        // this.adhocDeliveredToCounty = query.deliveredToCounty || [],
        this.adhocSpecialDistrictID = query.specialDistrictID || []
        // this.adhocSpecialDistrict = query.specialDistrict || [],
        // this.adhocVendorCustomer = query.vendorCustomer || [],
        // this.adhocDescription = query.description || [],
        // this.adhocReference = query.reference || [],
        // this.adhocIsActive = query.isActive || [],
        this.adhocStatus = query.status || []
        // this.adhocIsAppended = query.isAppended || [],
        this.adhocCreatedByID = query.createdByID || []
        // this.adhocCreatedBy = query.createdBy || [],
        this.adhocModifiedByID = query.modifiedByID || []
        // this.adhocModifiedBy = query.modifiedBy || [],
      } else {
        this.resetQuery()
      }

      if (item && item.ID <= 0) {
        //this can be used for additional processing for query presets that are hardcoded in javascript,
      }
      this.$nextTick(() => {
        this.adhocQueryIsModified = false
      })
    },
    resetQuery() {
      this.queryName = ''
      this.adhocQueryIsModified = false
    },
    formatPercent(val) {
      if (val != null && val != undefined) {
        return parseFloat((val * 100).toFixed(2)) + '%'
      }
    },
  },
}
</script>

<style lang="scss">
</style>