<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Reported Values
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="userCanEditProjectItems"
                      sm="auto"
                      class="mt-4"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openReportedValuesDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Reported Values</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="valueDescription"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Reported Values"
                        :name="`Project_Reported_Values_${projectNumber}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="visibleHeaders"
                    :items="valueDescription"
                    :items-per-page="-1"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    dense
                  >
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.dateEdit="{item}"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="openEditDialog(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit Reported Value Information</span>
                      </v-tooltip>
                    </template>
                    <!-- EXCISE ONLY -->
                    <template v-slot:item.ReportedValue="{item}">
                      {{ item.ReportedValue | moneyFormat }}
                    </template>
                    <template v-slot:item.valueType="{item}">
                      {{ item.valueType }}
                    </template>
                    <!-- stage EXCISE FIELD ONLY/NO LONGER NEEDED -->
                    <!-- <template v-slot:stage="{item}">
                      <span v-show="showColumn('stage')">{{ item.stage }}</span>
                    </template>-->
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.reportedValueDelete="{item}"
                    >
                      <v-tooltip v-if="item.ID > 0" bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="deleteReportedValue(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete Reported Value</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="deleteReportedValueDialog"
      scrollable
      width="750"
    >
      <v-form ref="notesForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text"
              >Are you sure you want to delete this Reported Value?</h3
            >
          </v-card-title>
          <v-card-text class="pa-4"> </v-card-text>
          <v-card-actions>
            <v-btn @click="closeDeleteReportedValue" color="primary"
              >Cancel</v-btn
            >
            <v-btn @click="deleteSelectedReportedValue" outlined color="primary"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import ProjectReportedValues from '@classes/ProjectReportedValues'
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  props: {
    ProjectID: Number,
    DivisionId: Number,
    projectNumber: String,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      selectedReportedValue: new ProjectReportedValues(),
      deleteReportedValueDialog: false,
      valid: true,
      search: '',
      panel: [0, 1],
      data: [],
      loading: false,
    }
  },
  created() {
    this.loadData()
    eventBus.$on('SaveReportedValues', (rv) => {
      this.loadData()
      eventBus.$emit('closeReportedValues')
    })
  },
  beforeDestroy() {
    eventBus.$off('SaveReportedValues')
  },
  computed: {
    ...authComputed,
    luReportedType: get('luReportedType/luReportedType'),
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'dateEdit',
          visible: true,
          sortable: false,
          visible: this.userCanEditProjectItems,
        },
        {
          text: this.DivisionId == 6 ? 'Reported Tax Amount' : 'Value Reported',
          align: 'right',
          value: 'ReportedValue',
          sortable: true,
          visible: true,
        },
        // {
        //   // EXCISE ONLY INSTEAD OF ABOVE
        //   text: 'Reported Tax Amount',
        //   align: 'right',
        //   value: 'ReportedValue',
        //   sortable: true,
        //   visible: this.DivisionId == 6,
        // },
        {
          text: 'Value Type',
          align: 'left',
          value: 'valueType',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Entered By',
          align: 'left',
          value: 'fullName',
          sortable: 'true',
          visible: true,
        },

        {
          text: '',
          align: 'left',
          value: 'reportedValueDelete',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
      ]
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      if (fields['Reported Tax Amount']) {
        fields['Reported Tax Amount'] = {
          field: 'ReportedValue',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Value Reported']) {
        fields['Value Reported'] = {
          field: 'ReportedValue',
          format: '$#,##0.00',
          sum: true,
        }
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Reported Values'})
      return summary
    },
    valueDescription() {
      var data = this.data.map((vd) => {
        return {
          ...vd,
          fullName: vd.EnteredByNavigation
            ? vd.EnteredByNavigation.FirstName +
              ' ' +
              vd.EnteredByNavigation.LastName
            : 'unk.',
          valueType: this.luReportedType.find(
            (vt) => vt.ID == vd.ReportedValueTypeID
          )
            ? this.luReportedType.find((vt) => vt.ID == vd.ReportedValueTypeID)
                .ReportedType
            : '',
        }
      })
      return data
    },
  },
  methods: {
    deleteProjectReportedValues: call(
      'projectReportedValues/deleteProjectReportedValues'
    ),
    openReportedValuesDialog() {
      eventBus.$emit('dialogReportedValues', new ProjectReportedValues())
    },
    openEditDialog(value) {
      eventBus.$emit('dialogReportedValues', new ProjectReportedValues(value))
    },
    deleteReportedValue(item) {
      this.selectedReportedValue = new ProjectReportedValues(item)
      this.deleteReportedValueDialog = true
    },
    closeDeleteReportedValue() {
      this.deleteReportedValueDialog = false
    },
    deleteSelectedReportedValue() {
      this.deleteProjectReportedValues(this.selectedReportedValue.root())
        .then((res) => {
          this.selectedReportedValue = new ProjectReportedValues()
          this.loadData()
          this.deleteReportedValueDialog = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
    loadData() {
      this.loading = true
      axios
        .get(`ProjectReportedValues/ByProject/${this.ProjectID}`)
        .then((res) => {
          this.loading = false
          this.data = res.data
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
