<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Project Costs
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col sm="12" md="3" p>
                      <baseDatePickerWithText
                        label="Start Date"
                        v-model="startDate"
                        clearable
                      ></baseDatePickerWithText>
                    </v-col>
                    <v-col sm="12" md="3">
                      <baseDatePickerWithText
                        label="End Date"
                        v-model="endDate"
                        clearable
                      ></baseDatePickerWithText>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="mappedData"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Costs"
                        :name="`Project_Costs_${projectNumber}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="mappedData"
                    :items-per-page="-1"
                    :search="search"
                    class="pt-4"
                    dense
                  >
                    <template v-slot:item.preExam="{item}">{{
                      item.preExam | decimalToTime
                    }}</template>
                    <template v-slot:item.onSite="{item}">{{
                      item.onSite | decimalToTime
                    }}</template>
                    <template v-slot:item.offSite="{item}">{{
                      item.offSite | decimalToTime
                    }}</template>
                    <template v-slot:item.billableHours="{item}">{{
                      item.billableHours | decimalToTime
                    }}</template>
                    <template v-slot:item.otherHours="{item}">{{
                      item.otherHours | decimalToTime
                    }}</template>
                    <template v-slot:item.subTotal="{item}">{{
                      item.subTotal | decimalToTime
                    }}</template>
                    <template v-slot:item.postExam="{item}">{{
                      item.postExam | decimalToTime
                    }}</template>
                    <template v-slot:item.travel="{item}">{{
                      item.travel | decimalToTime
                    }}</template>
                    <template v-slot:footer>
                      <v-row justify="end">
                        <v-col sm="auto" class="ma-4"
                          >Exam Total: {{ examTotal | money }}</v-col
                        >
                        <v-col sm="auto" class="ma-4"
                          >Travel Total: {{ travelTotal | money }}</v-col
                        >
                        <v-col sm="auto" class="ma-4"
                          >Post-Exam Total: {{ postExamTotal | money }}</v-col
                        >
                        <v-col sm="auto" class="ma-4"
                          >Grand Total: {{ grandTotal | money }}</v-col
                        >
                      </v-row>
                    </template>
                    <template v-slot:item.examCosts="{item, headers}">
                      <span>{{ item.examCosts | money }}</span>
                    </template>
                    <template v-slot:item.postExamCosts="{item, headers}">
                      <span>{{ item.postExamCosts | money }}</span>
                    </template>
                    <template v-slot:item.personnelCosts="{item, headers}">
                      <span>{{ item.personnelCosts | money }}</span>
                    </template>
                    <template v-slot:item.travelExpenses="{item, headers}">
                      <span>{{ item.travelExpenses | money }}</span>
                    </template>
                    <template v-slot:item.totalCosts="{item, headers}">
                      <span>{{ item.totalCosts | money }}</span>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import {get, sync, commit, call} from 'vuex-pathify'
import dayjs from 'dayjs'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    projectNumber: String,
    projectId: Number,
    divisionId: Number,
    projectSummary: Array,
    userProject: Array,
  },
  components: {
    excelExport,
  },
  filters: {
    money(value) {
      const amt = Number.parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
      return (
        '$' +
        ((amt && amt.toLocaleString(undefined, {maximumFractionDigits: 2})) ||
          '0')
      )
      // return Number.parseFloat(value).toFixed(2)
    },
    decimalToTime(d) {
      var decimalTime = parseFloat(d) * 60 * 60
      var hours = Math.floor(decimalTime / (60 * 60))

      decimalTime = decimalTime - hours * 60 * 60
      var minutes = Math.round(decimalTime / 60)

      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hours + ':' + minutes
    },
  },
  data() {
    return {
      search: '',
      panel: [0],
      startDate: null,
      endDate: null,
    }
  },
  created() {
    this.loadData()
    this.loadLuProjectRole()
  },
  computed: {
    projectTimeSheetEntries: get('timeSheetEntry/projectTimeSheetEntries'),
    projectExpenses: get('projectExpenses/projectExpenses'),
    luProjectRole: get('luProjectRole/luProjectRole'),
    assignedUsers() {
      var projectUsers = this.userProject.map((pu) => {
        return {
          ...pu,
          role: this.luProjectRole.find((r) => r.ID == pu.ProjectRoleID)
            ? this.luProjectRole.find((r) => r.ID == pu.ProjectRoleID)
                .Description
            : '',
        }
      })
      return projectUsers
    },
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: 'Employee',
          align: 'left',
          sortable: 'true',
          value: 'user',
          visible: true,
        },
        {
          text: 'Role',
          align: 'left',
          sortable: 'true',
          value: 'role',
          visible: true,
        },
        {
          text: 'Pre-Exam',
          align: 'right',
          sortable: 'true',
          value: 'preExam',
          visible: true,
        },
        {
          text: 'On-Site',
          align: 'right',
          sortable: 'true',
          value: 'onSite',
          visible: true,
        },
        {
          text: 'Off-Site',
          align: 'right',
          sortable: 'true',
          value: 'offSite',
          visible: true,
        },
        {
          text: 'Post-Exam',
          align: 'right',
          sortable: 'true',
          value: 'postExam',
          visible: true,
        },
        {
          text: 'Sub-Total',
          align: 'right',
          sortable: 'true',
          value: 'subTotal',
          visible: true,
        },
        {
          text: 'Travel',
          align: 'right',
          sortable: 'true',
          value: 'travel',
          visible: true,
        },
        {
          text: 'Total Billable Hours',
          align: 'right',
          sortable: 'true',
          value: 'billableHours',
          visible: true,
        },
        {
          text: 'Exam Pers',
          align: 'right',
          sortable: 'true',
          value: 'examCosts',
          visible: true,
        },
        {
          text: 'Travel Expenses',
          align: 'right',
          sortable: 'true',
          value: 'travelExpenses',
          visible: true,
        },
        {
          text: 'Post-Exam Pers',
          align: 'right',
          sortable: 'true',
          value: 'postExamCosts',
          visible: true,
        },
        {
          text: 'Total Personnel Costs',
          align: 'right',
          sortable: 'true',
          value: 'personnelCosts',
          visible: false,
        },
        {
          text: 'Total Costs',
          align: 'right',
          sortable: 'true',
          value: 'totalCosts',
          visible: true,
        },
      ]
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      if (fields['Pre-Exam']) {
        fields['Pre-Exam'] = {
          field: 'preExam',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      if (fields['On-Site']) {
        fields['On-Site'] = {
          field: 'onSite',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      if (fields['Off-Site']) {
        fields['Off-Site'] = {
          field: 'offSite',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      if (fields['Post-Exam']) {
        fields['Post-Exam'] = {
          field: 'postExam',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      if (fields['Sub-Total']) {
        fields['Sub-Total'] = {
          field: 'subTotal',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      if (fields['Travel']) {
        fields['Travel'] = {
          field: 'travel',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      if (fields['Total Billable Hours']) {
        fields['Total Billable Hours'] = {
          field: 'billableHours',
          callback: (value) => {
            return value / 24
          },
          format: '[h]:mm',
          sum: true,
        }
      }
      if (fields['Exam Pers']) {
        fields['Exam Pers'] = {
          field: 'examCosts',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Post-Exam Pers']) {
        fields['Post-Exam Pers'] = {
          field: 'postExamCosts',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Total Personnel Costs']) {
        fields['Total Personnel Costs'] = {
          field: 'personnelCosts',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Travel Expenses']) {
        fields['Travel Expenses'] = {
          field: 'travelExpenses',
          format: '$#,##0.00',
          sum: true,
        }
      }
      if (fields['Total Costs']) {
        fields['Total Costs'] = {
          field: 'totalCosts',
          format: '$#,##0.00',
          sum: true,
        }
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Costs'})
      return summary
    },
    expenseUsers() {
      return [...new Set(this.mappedExpenses.map((item) => item.userId))]
    },
    timeUsers() {
      return [...new Set(this.mappedTime.map((item) => item.userId))]
    },
    combinedUsers() {
      return [...new Set([...this.expenseUsers, ...this.timeUsers])]
    },
    mappedExpenses() {
      return this.projectExpenses
        .filter((item) => {
          if (this.startDate || this.endDate) {
            var start = dayjs(this.startDate || '1753-01-01')
            var end = dayjs(this.endDate || undefined)
            var date = dayjs(item.EnteredDate)
            return date >= start && date <= end
          } else {
            return true
          }
        })
        .map((item) => {
          return {
            ...item,
            totals:
              (item.POV ? item.POV : 0) +
              (item.StateCar ? item.StateCar : 0) +
              (item.AirFare ? item.AirFare : 0) +
              (item.Hotel ? item.Hotel : 0) +
              (item.MIE ? item.MIE : 0) +
              (item.RentalCar ? item.RentalCar : 0) +
              (item.Other ? item.Other : 0),
            userId: item.AuditorID,
            user: item.Auditor
              ? item.Auditor.FirstName + ' ' + item.Auditor.LastName
              : '',
          }
        })
    },
    mappedTime() {
      var filteredEntries = this.projectTimeSheetEntries.filter(
        (item) => item.BillableCategoryID
      )
      if (this.startDate || this.endDate) {
        var start = dayjs(this.startDate || '1753-01-01')
        var end = dayjs(this.endDate || undefined)
        filteredEntries = filteredEntries.filter((item) => {
          var date = dayjs(item.Date)
          return date >= start && date <= end
        })
      }
      return filteredEntries.map((item) => {
        return {
          ...item,
          activityCode: item.Code.Code,
          activityDescription: item.Code.Description,
          billable: item.BillableCategory ? item.BillableCategory : 'No',
          userId: item.TimeSheet.UserID,
          user:
            item.TimeSheet.User.FirstName + ' ' + item.TimeSheet.User.LastName,
        }
      })
    },
    mappedData() {
      let data = []
      this.combinedUsers.forEach((item) => {
        let travel = this.mappedExpenses.filter((exp) => exp.userId == item)
        let hours = this.mappedTime.filter((time) => time.userId == item)
        let role = this.assignedUsers.filter((user) => user.UserID == item)
        let entry = {
          user:
            travel[0] && travel[0].user
              ? travel[0].user
              : hours[0] && hours[0].user
              ? hours[0].user
              : 'unknown',
          role: role[0] ? role[0].role : '',
          preExam: hours.reduce((prev, item) => {
            if (item.BillableCategoryID == 1) {
              return prev + item.Hours
            } else {
              return prev
            }
          }, 0),
          offSite: hours.reduce((prev, item) => {
            if (item.BillableCategoryID == 2) {
              return prev + item.Hours
            } else {
              return prev
            }
          }, 0),
          onSite: hours.reduce((prev, item) => {
            if (item.BillableCategoryID == 3) {
              return prev + item.Hours
            } else {
              return prev
            }
          }, 0),
          travel: hours.reduce((prev, item) => {
            if (item.BillableCategoryID == 5) {
              return prev + item.Hours
            } else {
              return prev
            }
          }, 0),
          postExam: hours.reduce((prev, item) => {
            if (item.BillableCategoryID == 4) {
              return prev + item.Hours
            } else {
              return prev
            }
          }, 0),
          subTotal: hours.reduce((prev, item) => {
            //billable hours except travel
            if (item.BillableCategoryID > 0 && item.BillableCategoryID != 5) {
              return prev + item.Hours
            } else {
              return prev
            }
          }, 0),
          billableHours: hours.reduce((prev, item) => {
            return prev + item.Hours
          }, 0),
          personnelCosts: hours.reduce((prev, item) => {
            return prev + item.Hours * 62.5
          }, 0),
          travelExpenses: travel.reduce((prev, item) => {
            return prev + item.totals
          }, 0),
          examCosts: hours.reduce((prev, item) => {
            return prev + (item.Code.Code == '215' ? 0 : item.Hours) * 62.5
          }, 0),

          postExamCosts: hours.reduce((prev, item) => {
            return prev + (item.Code.Code == '215' ? item.Hours : 0) * 62.5
          }, 0),
          totalCosts:
            travel.reduce((prev, item) => {
              return prev + item.totals
            }, 0) +
            hours.reduce((prev, item) => {
              return prev + item.Hours * 62.5
            }, 0),
        }
        if (entry.user) {
          data.push(entry)
        }
      })
      return data
    },
    grandTotal() {
      return this.mappedData.reduce((prev, item) => prev + item.totalCosts, 0)
    },
    examTotal() {
      return this.mappedData.reduce((prev, item) => prev + item.examCosts, 0)
    },
    travelTotal() {
      return this.mappedData.reduce(
        (prev, item) => prev + item.travelExpenses,
        0
      )
    },
    postExamTotal() {
      return this.mappedData.reduce(
        (prev, item) => prev + item.postExamCosts,
        0
      )
    },
  },
  methods: {
    loadLuProjectRole: call('luProjectRole/loadLuProjectRole'),
    loadProjectTimeSheetEntries: call(
      'timeSheetEntry/loadProjectTimeSheetEntries'
    ),
    loadProjectExpenses: call('projectExpenses/loadProjectExpenses'),
    loadData() {
      if (this.projectId) {
        this.loadProjectTimeSheetEntries(this.projectId)
        this.loadProjectExpenses(this.projectId)
      }
    },
  },
  watch: {},
}
</script>
<style scoped></style>
