<template>
  <v-dialog
    persistent
    v-model="dialog"
    @keydown.esc="dialog = false"
    width="850"
    scrollable
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Stratum Number"
                v-model="selectedExciseStratum.StratumNumber"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Stratum Description"
                v-model="selectedExciseStratum.Description"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <LuExciseProjectionType
                label="Projection Type"
                v-model="selectedExciseStratum.ProjectionTypeID"
              ></LuExciseProjectionType>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Sample Stratum Amount"
                v-model="selectedExciseStratum.SampleSize"
                type="number"
                :rules="[(v) => (v != null && v != undefined) || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Stratum Population Amount"
                v-model="selectedExciseStratum.StratumTotal"
                type="number"
                :rules="[(v) => (v != null && v != undefined) || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- only available for average error -->
            <v-col v-if="selectedExciseStratum.ProjectionTypeID == 2" sm="12">
              <v-text-field
                label="Number of Sample Months"
                v-model="selectedExciseStratum.NumberMonths"
                type="number"
                :rules="[
                  (v) => (v != null && v != undefined && v > 0) || 'required',
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="modalText != `Insert Excise Stratum`" sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedExciseStratum.IsActive"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Appended"
                v-model="selectedExciseStratum.IsAppended"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col sm="12">
              <Project
                label="Project"
                v-model="selectedExciseStratum.ProjectID"
              ></Project>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveExciseStratumDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ExciseStratum from '@classes/ExciseStratum'
//templateTODO: import lookup components you plan to use
//import Project from '@components/select/single/Project'
import LuExciseProjectionType from '@components/select/single/LuExciseProjectionType'
export default {
  components: {
    //templateTodo: define lookup components you are using
    //Project,
    LuExciseProjectionType,
  },
  props: {
    projectId: {
      type: Number,
    },
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedExciseStratum: new ExciseStratum(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('exciseStratum', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('exciseStratum', ['saveExciseStratum']),
    async validate() {
      this.$refs.form.validate()
    },
    editExciseStratum(entry) {
      this.selectedExciseStratum = new ExciseStratum(entry)
      this.modalText = 'Edit Excise Stratum'
      this.dialog = !this.dialog
    },
    addExciseStratum() {
      this.modalText = 'Insert Excise Stratum'
      this.selectedExciseStratum = new ExciseStratum({
        ProjectID: this.projectId,
      })
      this.dialog = !this.dialog
    },
    saveExciseStratumDetails() {
      this.validate().then(() => {
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          this.saveExciseStratum(
            this.selectedExciseStratum.removeRelated()
          ).then(
            (res) => {
              this.$emit('refresh')
              this.selectedExciseStratum = new ExciseStratum()
              this.$refs.form.resetValidation()
            },
            (err) => {}
          )
          this.dialog = false
        }
      })
    },
    cancelEntry() {
      this.dialog = false
      this.selectedExciseStratum = new ExciseStratum()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style scoped >
/deep/ .v-input input::-webkit-outer-spin-button,
/deep/ .v-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>