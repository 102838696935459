<template>
  <v-dialog
    persistent
    v-model="dialogRefunds"
    @keydown.esc="dialogRefunds = false"
    width="850"
    scrollable
  >
    <v-form ref="refundsForm" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">{{ modalText }}</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12" md="6">
              <v-text-field
                v-model="selectedRefunds.Quantity"
                label="Number of Refunds"
                :rules="[rules.fieldRequired]"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="6">
              <v-text-field
                v-model="selectedRefunds.Amount"
                :rules="[rules.fieldRequired]"
                type="number"
                label="Amount"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="6">
              <BaseDatePickerWithText
                v-model="selectedRefunds.DateReceived"
                label="Date Received mm/dd/yyyy"
              ></BaseDatePickerWithText>
            </v-col>
            <v-col sm="12" md="6">
              <BaseDatePickerWithText
                v-model="selectedRefunds.DateProcessed"
                label="Date Processed mm/dd/yyyy"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="saving" @click="saveRefunds"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelRefunds">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ProjectRefunds from '@classes/ProjectRefunds'
import {mask} from 'vue-the-mask'
import Rules from '../../validation/rules'
import {get, sync, commit, call} from 'vuex-pathify'

export default {
  directives: {
    mask,
  },
  components: {},
  props: {
    project: Number,
    divisionID: Number,
  },
  data() {
    return {
      selectedRefunds: new ProjectRefunds(),
      dialogRefunds: false,
      valid: true,
      modalText: 'Refunds',
      rules: Rules,
      saving: false,
    }
  },
  created() {
    var vm = this
    eventBus.$on('dialogRefunds', function (value) {
      vm.selectedRefunds = value
      vm.dialogRefunds = !!value
    })
    eventBus.$on('closeRefunds', (rv) => {
      vm.dialogRefunds = false
    })
  },
  beforeDestroy() {
    var vm = this
    eventBus.$off('dialogRefunds')
    eventBus.$off('closeRefunds')
  },
  computed: {},
  methods: {
    saveProjectRefunds: call('projectRefunds/saveProjectRefunds'),
    openDialog() {
      this.dialogRefunds = true
    },
    async validate() {
      this.$refs.refundsForm.validate()
    },
    saveRefunds() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedRefunds.ProjectID = this.project
          this.saving = true
          this.saveProjectRefunds(this.selectedRefunds.root())
            .then((res) => {
              this.selectedRefunds = new ProjectRefunds()
              this.$refs.refundsForm.resetValidation()
              eventBus.$emit('SaveRefunds', res.data)
              this.saving = false
            })
            .catch((error) => {
              console.error(error)
              this.saving = false
            })
        }
      })
    },
    cancelRefunds() {
      this.selectedRefunds = new ProjectRefunds({
        ProjectID: this.project,
      })
      this.dialogRefunds = false
      this.$refs.refundsForm.resetValidation()
    },
  },
  watch: {},
}
</script>
<style scoped></style>
