<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary headline text-left white--text">
                Exam Reviews
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="userCanEditProjectItems" sm="auto" class="mt-4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openReviewDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Review</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="mappedData"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Reviews"
                        :name="`Project_Reviews_${projectNumber}.xlsx`"
                      >Export</excelExport>
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="mappedData"
                    :items-per-page="-1"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    dense
                  >
                    <template v-slot:group.header.data-table-expand="{on, props}">
                      <span>Expand</span>
                    </template>
                    <template v-if="userCanEditProjectItems" v-slot:item.reviewEdit="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn small color="primary" v-on="on" icon @click="openEditDialog(item)">
                            <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Review Information</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.DateSubmitted="{item}">
                      {{
                      item.DateSubmitted | dateformat
                      }}
                    </template>
                    <template v-slot:item.DateCompleted="{item}">
                      {{
                      item.DateCompleted | dateformat
                      }}
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProjectReviews from '@classes/ProjectReviews'
import excelExport from '@components/excelExport'
export default {

  props: {
    ProjectID: Number,
    projectNumber: String,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      search: '',
      panel: [0],
      data: [],
      loading: false,
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'reviewEdit',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {
          text: 'Date Submitted',
          align: 'left',
          value: 'DateSubmitted',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Reviewer',
          align: 'left',
          value: 'reviewer',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Date Completed',
          align: 'left',
          value: 'DateCompleted',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Entered By',
          align: 'left',
          value: 'enteredBy',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
      ]
    },
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Reviews'})
      return summary
    },
    mappedData() {
      var data = this.data.map((item) => {
        return {
          ...item,
          reviewer: item.Reviewer
            ? item.Reviewer.FirstName + ' ' + item.Reviewer.LastName
            : '',
          enteredBy: item.EnteredByNavigation
            ? item.EnteredByNavigation.FirstName +
              ' ' +
              item.EnteredByNavigation.LastName
            : 'unknown',
        }
      })
      return data
    },
  },
  methods: {
    openEditDialog(value) {
      eventBus.$emit('dialogReview', new ProjectReviews(value))
    },
    openReviewDialog() {
      eventBus.$emit('dialogReview', new ProjectReviews())
    },
    loadProjectReviews() {
      this.loading = true
      const url = `ProjectReviews/ByProject/${this.ProjectID}`
      this.$axios.get(url).then(
        (res) => {
      this.loading = false
          this.data = res.data
        },
        (err) => {
      this.loading = false

        }
      )
    },
  },
  created() {
    this.loadProjectReviews()
    eventBus.$on('SaveReview', (pd) => {
      this.loadProjectReviews()
      eventBus.$emit('closeProjectReview')
    })
  },
  beforeDestroy() {
    var self = this
    eventBus.$off('SaveReview')
  },
  watch: {},
}
</script>
<style scoped></style>
