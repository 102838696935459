<template>
  <v-dialog
    persistent
    v-model="dialogTravelExpenses"
    @keydown.esc="dialogTravelExpenses = false"
    width="850"
    scrollable
  >
    <v-form
      ref="travelExpensesForm"
      @submit.prevent
      lazy-validation
      v-model="valid"
    >
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">{{ modalText }}</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="selectedTravelExpenses.POV"
                type="number"
                label="POV"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="selectedTravelExpenses.StateCar"
                type="number"
                label="State Car"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="selectedTravelExpenses.AirFare"
                type="number"
                label="AirFare"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="selectedTravelExpenses.Hotel"
                type="number"
                label="Hotel"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="selectedTravelExpenses.MIE"
                type="number"
                label="M&IE"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="selectedTravelExpenses.RentalCar"
                type="number"
                label="Rental Car"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="3">
              <v-text-field
                v-model="selectedTravelExpenses.Other"
                type="number"
                label="Other"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col sm="12">
              <luUser
                v-model="selectedTravelExpenses.AuditorID"
                item-text="Description"
                :rules="[rules.fieldRequired]"
                item-value="ID"
                label="Auditor"
                :divisionId="divisionID"
              ></luUser>
            </v-col>
            <v-col sm="12">
              <v-textarea
                v-model="selectedTravelExpenses.Comments"
                outlined
                label="Comments"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="saving" @click="saveTravelExpenses"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelTravelExpenses"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ProjectExpenses from '@classes/ProjectExpenses'
import luUser from '@components/LuUser'
import {mask} from 'vue-the-mask'
import Rules from '../../validation/rules'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  directives: {
    mask,
  },
  components: {
    luUser,
  },
  props: {
    project: Number,
    divisionID: Number,
  },
  data() {
    return {
      selectedTravelExpenses: new ProjectExpenses(),
      dialogTravelExpenses: false,
      valid: true,
      modalText: 'Travel Expenses',
      rules: Rules,
      saving: false,
    }
  },
  created() {
    var vm = this
    eventBus.$on('dialogTravelExpenses', function (value) {
      vm.selectedTravelExpenses = value
      vm.dialogTravelExpenses = !!value
    })
    eventBus.$on('closeTravelExpenses', (rv) => {
      vm.dialogTravelExpenses = false
    })
  },
  beforeDestroy() {
    var vm = this
    eventBus.$off('dialogTravelExpenses')
    eventBus.$off('closeTravelExpenses')
  },
  computed: {},
  methods: {
    saveProjectExpenses: call('projectExpenses/saveProjectExpenses'),
    openDialog() {
      this.dialogTravelExpenses = true
    },
    async validate() {
      this.$refs.travelExpensesForm.validate()
    },
    saveTravelExpenses() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedTravelExpenses.ProjectID = this.project
          this.saving = true
          this.saveProjectExpenses(this.selectedTravelExpenses.root())
            .then((res) => {
              this.dialogTravelExpenses = false
              this.saving = false
            })
            .catch((error) => {
              console.error(error)
              this.saving = false
            })
        }
      })
    },
    cancelTravelExpenses() {
      this.selectedTravelExpenses = new ProjectExpenses({
        ProjectID: this.project,
      })
      this.dialogTravelExpenses = false
      this.$refs.travelExpensesForm.resetValidation()
    },
  },
  watch: {},
}
</script>
<style scoped></style>
