<template>
  <div>
    <projectsDates
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :divisionId="DivisionId"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsDates>
    <projectsFindings
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :divisionId="DivisionId"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsFindings>
    <projectsCollections
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :divisionId="DivisionId"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsCollections>
    <projectsReportedValues
      :ProjectID="ProjectID"
      :DivisionId="DivisionId"
      :projectNumber="projectNumber"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsReportedValues>
  </div>
</template>
<script>
import projectsDates from '@components/projects/projectsDates'
import projectsFindings from '@components/projects/projectsFindings'
import projectsCollections from '@components/projects/projectsCollections'
import projectsReportedValues from '@components/projects/projectsReportedValues'
export default {
  components: {
    projectsDates,
    projectsFindings,
    projectsCollections,
    projectsReportedValues,
  },
  props: {
    projectNumber: String,
    ProjectID: Number,
    DivisionId: Number,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
