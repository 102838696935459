<template>
  <div>
    <!-- <AuditorsExaminers></AuditorsExaminers> -->
    <projectsDates
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :projectSummary="projectSummary"
      :divisionId="DivisionId"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsDates>
    <ProjectsBudget
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></ProjectsBudget>
  </div>
</template>
<script>
// import AuditorsExaminers from '../projects/projectsAuditorsExaminersAssigned'
import projectsDates from '@components/projects/projectsDates'
import ProjectsBudget from '@components/projects/projectsBudget'
export default {
  components: {
    // AuditorsExaminers,
    projectsDates,
    ProjectsBudget,
  },
  props: {
    projectNumber: String,
    ProjectID: Number,
    DivisionId: Number,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
