import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasePanelWithHeader',{attrs:{"headerText":"Canned Project Reports"}},[_c(VRow,[_c(VCol,[_c(VSelect,{attrs:{"label":"Select Report","items":_vm.availableCannedReports,"return-object":"","item-text":"Description"},model:{value:(_vm.selectedCannedReport),callback:function ($$v) {_vm.selectedCannedReport=$$v},expression:"selectedCannedReport"}})],1),(
          _vm.selectedCannedReport.FileTypes &&
            _vm.selectedCannedReport.FileTypes.length > 0
        )?_c('downloadButtons',{attrs:{"fileTypes":_vm.selectedCannedReport.FileTypes},on:{"downloadReport":_vm.downloadReport}}):_vm._e()],1),(_vm.reportParameters.length > 0)?_c('reportParameters',{attrs:{"reportParameters":_vm.reportParameters,"searchParams":_vm.searchParams,"staticParams":['projectId', 'licenseNumber', 'ridNumber']},on:{"updateSearchParam":_vm.updateSearchParam}}):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"6"}},[(
            _vm.selectedCannedReport &&
              _vm.selectedCannedReport.reportDefaults &&
              _vm.selectedCannedReport.reportDefaults.ReportSections &&
              _vm.selectedCannedReport.reportDefaults.ReportSections.length > 0
          )?_c(VSelect,{attrs:{"label":"Show Section","items":_vm.selectedCannedReport.reportDefaults.ReportSections,"item-text":"Name","item-value":"ID"},model:{value:(_vm.sectionId),callback:function ($$v) {_vm.sectionId=$$v},expression:"sectionId"}}):_vm._e()],1)],1),(_vm.selectedCannedReport.ID)?_c('BaseServerSyncDataTable',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.gridData,"searchTotal":_vm.searchTotal,"loading":_vm.loading,"searchSortBy":_vm.searchSortBy,"searchDescending":_vm.searchDescending,"searchPage":_vm.searchPage,"searchRowsPerPage":_vm.searchRowsPerPage,"multi-sort":"","show-group-by":_vm.showGroupBy,"groupBy":_vm.groupBy,"groupDesc":_vm.groupByDescending},on:{"update:searchSortBy":function($event){_vm.searchSortBy=$event},"update:search-sort-by":function($event){_vm.searchSortBy=$event},"update:searchDescending":function($event){_vm.searchDescending=$event},"update:search-descending":function($event){_vm.searchDescending=$event},"update:searchPage":function($event){_vm.searchPage=$event},"update:search-page":function($event){_vm.searchPage=$event},"update:searchRowsPerPage":function($event){_vm.searchRowsPerPage=$event},"update:search-rows-per-page":function($event){_vm.searchRowsPerPage=$event},"update:groupBy":function($event){_vm.groupBy=$event},"update:group-by":function($event){_vm.groupBy=$event},"update:groupDesc":function($event){_vm.groupByDescending=$event},"update:group-desc":function($event){_vm.groupByDescending=$event}},scopedSlots:_vm._u([_vm._l((_vm.mappedFormats),function(format,field){return {key:field,fn:function(ref){
          var item = ref.item;
return [_vm._v(_vm._s(_vm.formatItemField(item, field, format)))]}}})],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }