import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"primary headline text-left white--text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"white--text"},[_vm._v("$vuetify.icons.expand")])]},proxy:true}])},[_vm._v(" Refunds ")]),_c(VExpansionPanelContent,[_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[(_vm.userCanEditProjectItems)?_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","fab":"","depressed":"","small":""},on:{"click":function($event){return _vm.openRefundsDialog()}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,2827143198)},[_c('span',[_vm._v("Add Refunds")])])],1):_vm._e(),_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c('excelExport',{attrs:{"data":_vm.mappedData,"exportFields":_vm.excelFields,"summaryData":_vm.summaryData,"worksheet":"Project Refunds","name":("Project_Refunds_" + _vm.projectNumber + ".xlsx")}},[_vm._v("Export")])],1)],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.visibleHeaders,"items":_vm.mappedData,"items-per-page":-1,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([(_vm.userCanEditProjectItems)?{key:"item.dateEdit",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Refunds")])])]}}:null,{key:"item.Amount",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.Amount)))])]}},{key:"footer",fn:function(){return [_c(VRow,[_c(VCol,{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Total Refunds: "+_vm._s(_vm.totalRefunds))]),_c(VCol,{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Total Refund Amount: "+_vm._s(_vm._f("money")(_vm.totalRefundAmount)))])],1)]},proxy:true},{key:"item.DateReceived",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.DateReceived))+" ")]}},{key:"item.DateProcessed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.DateProcessed))+" ")]}}],null,true)})]],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }