<template>
  <div>
    <v-dialog
      persistent
      v-model="dialogCollections"
      @keydown.esc="dialogCollections = false"
      width="850"
      scrollable
    >
      <v-form
        ref="collectionForm"
        @submit.prevent
        lazy-validation
        v-model="valid"
      >
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">{{ modalText }}</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="12" md="6">
                <v-select
                  v-model="selectedCollection.CollectionsFindingTypeID"
                  :items="availableCollectionTypes"
                  item-text="FindingType"
                  item-value="ID"
                  :rules="[rules.fieldRequired]"
                  label="Collection Type"
                ></v-select>
              </v-col>
              <v-col sm="12" md="6">
                <BaseDatePickerWithText
                  label="Received Date mm/dd/yyyy"
                  v-model="selectedCollection.ReceivedDate"
                ></BaseDatePickerWithText>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <v-text-field
                  v-model="selectedCollection.CollectionsValue"
                  label="Amount"
                  prefix="$"
                  type="number"
                  :rules="[rules.amountPattern]"
                ></v-text-field>
                <!-- <currency-field
                  :rules="[rules.amountSeparatorPattern]"
                  v-model="selectedCollection.CollectionsValue"
                  label="Amount"
                  prefix="$"
                  :allowNegative="true"
                ></currency-field>-->
              </v-col>
              <!-- -Check Number EXCISE FIELD ONLY -->
              <!-- <v-col sm="12" md="6">
                <v-text-field label="Check Number"></v-text-field>
              </v-col>-->
              <v-col
                v-if="selectedCollectionType.RequiresCounty"
                sm="12"
                md="6"
              >
                <v-select
                  v-model="selectedCollection.CountyID"
                  :items="luCounty"
                  item-text="Name"
                  item-value="ID"
                  label="County"
                  :rules="[rules.fieldRequired]"
                  clearable
                ></v-select>
              </v-col>
              <v-col
                v-if="selectedCollectionType.RequiresNOVCNumber"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="selectedCollection.CollectionsYear"
                  :rules="[rules.fieldRequired, rules.yearPattern]"
                  v-mask="maskYear"
                  label="Year"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="selectedCollectionType.RequiresNOVCNumber"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="selectedCollection.NovcNumber"
                  :rules="[rules.fieldRequired, rules.novcNumberPattern]"
                  label="NOVC Number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-textarea
                  v-model="selectedCollection.Comments"
                  outlined
                  label="Comments"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" :disabled="saving" @click="saveCollection"
              >Save</v-btn
            >
            <v-btn outlined color="primary" @click="cancelCollection"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import currencyField from '@components/currencyField'
import ProjectCollections from '@classes/ProjectCollections'
import Rules from '../../validation/rules'
import {mask} from 'vue-the-mask'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  directives: {
    mask,
  },
  components: {
    'currency-field': currencyField,
  },
  props: {
    project: Number,
    divisionID: Number,
  },
  data() {
    return {
      maskYear: '####',
      selectedCollection: new ProjectCollections(),
      dialogCollections: false,
      valid: true,
      modalText: 'Collection Information',
      rules: Rules,
      saving: false,
    }
  },
  created() {
    this.loadLuFindingType()
    this.loadLuCounty()
    var vm = this
    eventBus.$on('dialogCollections', function (value) {
      vm.selectedCollection = value
      vm.dialogCollections = !!value
    })
    eventBus.$on('closeCollection', (sc) => {
      vm.dialogCollections = false
    })
  },
  beforeDestroy() {
    var vm = this
    eventBus.$off('dialogCollections')
    eventBus.$off('closeCollection')
  },

  computed: {
    luFindingType: get('luFindingType/luFindingType'),
    luCounty: get('luCounty/luCounty'),
    selectedCollectionType() {
      return (
        this.luFindingType.find(
          (f) => f.ID == this.selectedCollection.CollectionsFindingTypeID
        ) || {}
      )
    },
    availableCollectionTypes() {
      // get active for this division
      var collectionTypes = this.luFindingType.filter(
        (c) => c.Active && c.DivisionID == this.divisionID
      )
      // if existing record make sure the previous selection is available
      if (this.selectedCollection.ID > 0) {
        var selectedCollectionType = this.luFindingType.find(
          (c) => c.ID == this.selectedCollection.CollectionsFindingTypeID
        )
        if (
          !collectionTypes.find(
            (c) => c.ID == this.selectedCollection.CollectionsFindingTypeID
          ) &&
          selectedCollectionType
        ) {
          collectionTypes.push(selectedCollectionType)
        }
      }
      // sort by sort order column
      collectionTypes.sort((a, b) => {
        let textField = 'FindingType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return collectionTypes
    },
  },
  methods: {
    loadLuFindingType: call('luFindingType/loadLuFindingType'),
    loadLuCounty: call('luCounty/loadLuCounty'),
    saveProjectCollections: call('projectCollections/saveProjectCollections'),
    openDialog() {
      this.dialogCollections = true
    },
    async validate() {
      this.$refs.collectionForm.validate()
    },
    saveCollection() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedCollection.ProjectID = this.project
          this.saving = true
          this.saveProjectCollections(this.selectedCollection.root())
            .then((res) => {
              eventBus.$emit('SaveCollections', res.data)
              this.$refs.collectionForm.resetValidation()
              this.saving = false
            })
            .catch((error) => {
              console.error(error)
              this.saving = false
            })
        }
      })
    },

    cancelCollection() {
      this.selectedCollection = new ProjectCollections({
        ProjectID: this.project,
      })
      this.dialogCollections = false
      this.$refs.collectionForm.resetValidation()
    },
  },
  watch: {},
}
</script>
<style scoped></style>
