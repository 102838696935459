<template>
    <v-container fluid>
      <div>
        <v-breadcrumbs :items="breadcrumbs" divider=">" large></v-breadcrumbs>
      </div>
      <v-row>
        <v-col xs="12">
          <projectsDetails :project="selectedProject" :subEntityId="this.$route.params.subEntityId"></projectsDetails>
          <!-- <projectUsers
            v-if="!isNew"
            :projectUsers="selectedProject.UserProject"
            :divisionId="currentUser.DivisionID"
            :projectId="selectedProject.ID"
            @updateUsers="selectedProject.UserProject = $event"
          ></projectUsers>-->
          <v-card v-if="!isNew" class="mx-auto">
            <v-card-text>
              <project-tabs
                :project="selectedProject"
                :userCanEditProjectItems="userCanEditProjectItems"
              ></project-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<!-- SCRIPTS -->
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import Project from '@classes/Project'
import projectsDetails from '@components/projects/projectsDetails'
import projectTabs from '@components/projects/projects-tabs.vue'

export default {
  components: {
    projectsDetails,
    projectTabs,
  },
  page() {
    return {
      title: this.selectedProject
        ? this.selectedProject.ProjectNumber
        : 'Project',
    }
  },
  data: () => ({
    selectedProject: new Project(),
  }),
  computed: {
    ...authComputed,
    selectedAuditSubEntity: get('auditSubEntity/selectedAuditSubEntity'),
    subEntity() {
      if (this.$route.params.projectId) {
        return this.selectedProject.SubEntity || {}
      } else {
        return this.selectedAuditSubEntity || {}
      }
    },
    userCanEditProjectItems() {
      // public funds only leads
      if (
        this.isSuperUser ||
        (this.selectedProject.DivisionID == 4 &&
          this.currentUser.DivisionID == 4 &&
          this.userIsLeadOnProject)
      ) {
        return true
      }
      //excise only project creators
      else if (
        this.isSuperUser ||
        (this.selectedProject.DivisionID == 6 &&
          this.currentUser.DivisionID == 6 &&
          this.isProjectCreator)
      ) {
        return true
      }
      //minerals only assigned
      else if (
        this.isSuperUser ||
        (this.selectedProject.DivisionID == 5 &&
          this.currentUser.DivisionID == 5 &&
          this.userHasRoleOnProject)
      ) {
        return true
      }
      //banking only assigned
      else if (
        this.isSuperUser ||
        (this.selectedProject.DivisionID == 3 &&
          this.currentUser.DivisionID == 3 &&
          this.userHasRoleOnProject)
      ) {
        return true
      }
      //admin
      else if (
        this.isSuperUser ||
        (this.selectedProject.DivisionID == 2 &&
          this.currentUser.DivisionID == 2 &&
          this.userIsOnProject)
      ) {
        return true
      } else {
        return false
      }
    },
    userIsOnProject() {
      return (
        this.selectedProject.UserProject.filter(
          (up) => up.UserID == this.currentUser.ID
        ).length > 0
      )
    },
    userHasRoleOnProject() {
      return (
        this.selectedProject.UserProject.filter(
          (up) => up.UserID == this.currentUser.ID && !!up.ProjectRoleID
        ).length > 0
      )
    },
    userIsLeadOnProject() {
      return (
        this.selectedProject.UserProject.filter(
          (up) =>
            up.UserID == this.currentUser.ID &&
            !!up.ProjectRoleID &&
            (!!up.ProjectRole.IsPrimary ||
              up.ProjectRoleID == 16 ||
              up.ProjectRoleID == 3)
        ).length > 0
      )
    },
    entity() {
      if (this.$route.params.projectId) {
        return this.selectedProject.SubEntity.Entity || {}
      } else {
        return this.selectedAuditSubEntity.Entity || {}
      }
    },
    isNew() {
      return !this.selectedProject.ID > 0
    },
    breadcrumbs() {
      return [
        {
          text: 'Search',
          disabled: false,
          to: '/entities',
        },
        {
          text: `Entity: ${this.entity.FullName}`,
          disabled: false,
          to: `/entity/${this.subEntity.EntityID}`,
        },
        {
          text: `Sub-Entity: ${this.subEntity.Description}`,
          disabled: false,
          to: `/subEntity/${this.subEntity.EntityID}/${this.subEntity.ID}`,
        },
      ]
    },
  },
  watch: {
    currentUser(nval, oval) {
      if (!!nval && !this.$route.params.projectId) {
        this.loadSubEntityForNewProject()
      }
    },
  },
  methods: {
    loadSelectedProject: call('project/loadSelectedProject'),
    loadSelectedAuditSubEntity: call(
      'auditSubEntity/loadSelectedAuditSubEntity'
    ),
    newProject(entityDivisions) {
      // -check to see if we are in 1002 entity division and if so make the project tied to that division
      let divisionId
      if (
        entityDivisions.length == 1 &&
        entityDivisions[0].DivisionID == 1002
      ) {
        divisionId = 1002
      } else {
        divisionId = this.currentUser.DivisionID
      }
      this.selectedProject = new Project({
        SubEntityID: parseInt(this.$route.params.subEntityId),
        DivisionID: divisionId,
      })
    },
    loadSubEntityForNewProject() {
      this.loadSelectedAuditSubEntity(
        parseInt(this.$route.params.subEntityId)
      ).then((res) => {
        this.newProject(res.data.Entity.AuditEntityDivision)
      })
    },
  },
  created() {
    if (this.$route.params.projectId) {
      this.loadSelectedProject(this.$route.params.projectId).then((res) => {
        this.selectedProject = new Project(res.data)
      })
    } else if (this.currentUser) {
      this.loadSubEntityForNewProject()
    }
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>