import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('div',[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">","large":""}})],1),_c(VRow,[_c(VCol,{attrs:{"xs":"12"}},[_c('projectsDetails',{attrs:{"project":_vm.selectedProject,"subEntityId":this.$route.params.subEntityId}}),(!_vm.isNew)?_c(VCard,{staticClass:"mx-auto"},[_c(VCardText,[_c('project-tabs',{attrs:{"project":_vm.selectedProject,"userCanEditProjectItems":_vm.userCanEditProjectItems}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }