<template>
  <v-dialog
    persistent
    v-model="dialogBudget"
    @keydown.esc="dialogBudget = false"
    width="850"
    scrollable
  >
    <v-form ref="budgetedForm" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">{{ modalText }}</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-select
                v-model="selectedProjectBudget.ActivityID"
                item-text="Description"
                item-value="ID"
                :rules="[rules.fieldRequired]"
                :items="availableActivityCodes"
                label="Timesheet Activity"
                :readonly="selectedProjectBudget.Approved"
              >
                <template v-slot:selection="{item, index}">
                  <span>{{ item.Code }} {{ item.Description }}</span>
                </template>
                <template v-slot:item="{item, index}">
                  <span>{{ item.Code }} {{ item.Description }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="6">
              <v-text-field
                v-model="selectedProjectBudget.StandardBudgetHours"
                label="Standard Budget Hours"
                readonly
              ></v-text-field>
            </v-col>
            <v-col sm="12" md="6">
              <v-text-field
                v-model="selectedProjectBudget.BudgetedHours"
                :readonly="selectedProjectBudget.Approved"
                :rules="[rules.fieldRequired]"
                label="Budgeted Hours"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-textarea
                v-model="selectedProjectBudget.Comments"
                :readonly="selectedProjectBudget.Approved"
                outlined
                label="Comments"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="!selectedProjectBudget.Approved"
            @click="saveBudgetHours"
            :disabled="saving"
            >Save</v-btn
          >
          <v-btn
            color="primary"
            v-if="
              isManagerOfProject &&
              !selectedProjectBudget.Approved &&
              selectedProjectBudget.ID > 0
            "
            @click="approveBudgetHours"
            @keydown.esc="dialogBudget = false"
            :disabled="saving"
            >Approve</v-btn
          >
          <v-btn outlined color="primary" @click="cancelBudgetHours"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ProjectBudgetedHours from '@classes/ProjectBudgetedHours'
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
import Rules from '../../validation/rules'
export default {
  components: {},
  props: {
    project: Number,
    divisionID: Number,
    projectDetails: Object,
    projectManagers: Array,
  },
  data() {
    return {
      selectedProjectBudget: new ProjectBudgetedHours(),
      dialogBudget: false,
      valid: true,
      modalText: 'Budget Hours',
      rules: Rules,
      saving: false,
    }
  },
  created() {
    this.loadActivityCodes()
    this.loadLuStandardBudgetHours()
    var bh = this
    eventBus.$on('dialogBudget', function (value) {
      bh.selectedProjectBudget = value
      bh.dialogBudget = !!value
    })
  },
  beforeDestroy() {
    var bh = this
    eventBus.$off('dialogBudget')
  },
  computed: {
    ...authComputed,
    activityCodes: get('activityCodes/activityCodes'),
    luStandardBudgetHours: get('luStandardBudgetHours/luStandardBudgetHours'),
    projectBudgetedHours: get('projectBudgetedHours/projectBudgetedHours'),
    usedActivityCodes() {
      let activityCodes = []
      this.projectBudgetedHours.forEach((d) => {
        if (activityCodes.indexOf(d.ActivityID) < 0) {
          activityCodes.push(d.ActivityID)
        }
      })
      return activityCodes
    },
    availableActivityCodes() {
      // get active for this division
      var activity = this.activityCodes.filter(
        (a) =>
          a.IsActive &&
          a.DivisionID == this.divisionID &&
          (!a.Prefix || a.Prefix == this.projectDetails.Prefix)
      )
      // for public funds we are restricting it to unused selections
      if (this.divisionID == 4) {
        if (this.usedActivityCodes.length > 0) {
          this.usedActivityCodes.forEach((u) => {
            let index = activity.findIndex((a) => {
              return a.ID == u
            })
            if (index > -1) {
              activity.splice(index, 1)
            }
          })
        }
      }
      // if existing record make sure the previous selection is available
      if (this.selectedProjectBudget.ID > 0) {
        var selectedActivity = this.activityCodes.find(
          (a) => a.ID == this.selectedProjectBudget.ActivityID
        )
        if (
          !activity.find(
            (a) => a.ID == this.selectedProjectBudget.ActivityID
          ) &&
          selectedActivity
        ) {
          activity.push(selectedActivity)
        }
      }
      return activity
    },
    standardBudgetHours() {
      return this.luStandardBudgetHours
        .filter((h) => h.ProjectDetailID == this.projectDetails.ID)
        .find((h) => h.ActivityID == this.selectedProjectBudget.ActivityID)
    },
    isManagerOfProject() {
      return !!this.projectManagers.find(
        (up) => up.UserID == this.currentUser.ID
      )
    },
  },
  methods: {
    loadActivityCodes: call('activityCodes/loadActivityCodes'),
    loadLuStandardBudgetHours: call(
      'luStandardBudgetHours/loadLuStandardBudgetHours'
    ),
    saveProjectBudgetedHours: call(
      'projectBudgetedHours/saveProjectBudgetedHours'
    ),
    openDialog() {
      this.$refs.budgetedForm.resetValidation()
      this.dialogBudget = true
    },
    async validate() {
      this.$refs.budgetedForm.validate()
    },
    saveBudgetHours() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedProjectBudget.ProjectID = this.project
          this.saving = true
          this.saveProjectBudgetedHours(this.selectedProjectBudget.root())
            .then((res) => {
              this.saving = false
              this.$refs.budgetedForm.reset()
              this.dialogBudget = false
            })
            .catch((error) => {
              this.saving = false
              console.error(error)
            })
        }
      })
    },
    cancelBudgetHours() {
      this.selectedProjectBudget = new ProjectBudgetedHours({
        ProjectID: this.project,
      })
      this.dialogBudget = false
      this.$refs.budgetedForm.resetValidation()
    },
    approveBudgetHours() {
      this.selectedProjectBudget.Approved = true
      this.saveBudgetHours()
    },
  },
  watch: {
    //   standardBudgetHours(nval, oval) {
    //     if (
    //       this.selectedProjectBudget.ID == 0 ||
    //       !this.selectedProjectBudget.Approved
    //     ) {
    //       console.devlog(nval)
    //       if (nval) {
    //         this.selectedProjectBudget.StandardBudgetHours = nval.StandardHours
    //       } else {
    //         this.selectedProjectBudget.StandardBudgetHours = 0
    //       }
    //     }
    //   },
    // },
    'selectedProjectBudget.ActivityID': function (nval, oval) {
      let self = this
      if (
        this.selectedProjectBudget.ID == 0 ||
        !this.selectedProjectBudget.Approved
      ) {
        if (!!this.standardBudgetHours) {
          this.selectedProjectBudget.StandardBudgetHours = this.standardBudgetHours.StandardHours
        } else {
          this.selectedProjectBudget.StandardBudgetHours = 0
        }
      }
    },
  },
}
</script>
<style scoped></style>
