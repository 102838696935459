<template>
  <div>
    <div class="stickyBanner">
      <v-row>
        <v-col sm="12" md="8">
          <v-row justify="start" no-gutters>
            <v-col sm="auto" class="ml-2">
              <div>
                <span>{{ project.SubEntity.Entity.FullName }}</span>
                <span v-show="$vuetify.breakpoint.mdAndUp">
                  -
                  {{ project.SubEntity.Description }}
                </span>
              </div>
              <div v-show="$vuetify.breakpoint.mdAndUp">
                {{ project.AuditStart | dateLocal }} -
                {{ project.AuditEnd | dateLocal }}
              </div>
              <div class="font-weight-bold">
                {{ project.ProjectNumber }}
                <span v-show="$vuetify.breakpoint.mdAndUp">
                  -
                  {{ project.Description }}
                </span>
              </div>
            </v-col>
            <!-- <v-col sm="auto" class="ml-2"></v-col> -->
          </v-row>
        </v-col>
        <v-col sm="12" md="4" no-gutters>
          <v-row v-if="userCanEditProjectItems" justify="end" align="center">
            <v-col sm="auto">
              <v-tooltip bottom>
                <!-- CHANGE @CLICK -->
                <template v-slot:activator="{on}">
                  <v-icon color="primary" @click="addDate" v-on="on" small
                    >mdi-plus</v-icon
                  >
                  <span
                    v-if="project.DivisionID == 5 || project.DivisionID == 6"
                    >Dates / Findings</span
                  >
                  <span
                    v-if="project.DivisionID == 3 || project.DivisionID == 4"
                    >Dates</span
                  >
                </template>
                <span>Add Dates Information</span>
              </v-tooltip>
            </v-col>
            <v-col
              sm="auto"
              v-if="
                project.DivisionID == 3 && project.ProjectDetails.Prefix == 'BU'
              "
            >
              <v-tooltip bottom>
                <!-- CHANGE @CLICK -->
                <template v-slot:activator="{on}">
                  <v-icon color="primary" @click="addReview" v-on="on" small
                    >mdi-plus</v-icon
                  >
                  <span>Review</span>
                </template>
                <span>Add Review Information</span>
              </v-tooltip>
            </v-col>
            <!-- Mineral and Excise -->
            <v-col
              sm="auto"
              v-if="project.DivisionID == 5 || project.DivisionID == 6"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon
                    color="primary"
                    @click="addCollections"
                    v-on="on"
                    small
                    >mdi-plus</v-icon
                  >Collections
                </template>
                <span>Add Collection Information</span>
              </v-tooltip>
            </v-col>
            <!-- Mineral and Excise -->
            <v-col
              sm="auto"
              class="mr-2"
              v-if="project.DivisionID == 5 || project.DivisionID == 6"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon
                    color="primary"
                    @click="addReportedValues"
                    v-on="on"
                    small
                    >mdi-plus</v-icon
                  >Reported Values
                </template>
                <span>Add Reported Values</span>
              </v-tooltip>
            </v-col>
            <!-- Public Funds only -->
            <v-col sm="auto" class="mr-2" v-if="project.DivisionID == 4">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon
                    color="primary"
                    @click="addBudgetHours"
                    v-on="on"
                    small
                    >mdi-plus</v-icon
                  >Budget Hours
                </template>
                <span>Add Budget Hours</span>
              </v-tooltip>
            </v-col>
            <!-- Banking only -->
            <v-col sm="auto" class="mr-2" v-if="project.DivisionID == 3">
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon
                    color="primary"
                    @click="addTravelExpenses"
                    v-on="on"
                    small
                    >mdi-plus</v-icon
                  >Travel Expenses
                </template>
                <span>Add Travel Expenses</span>
              </v-tooltip>
            </v-col>
            <v-col
              sm="auto"
              class="mr-2"
              v-if="
                project.DivisionID == 3 && project.ProjectDetails.Prefix == 'BU'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-icon color="primary" @click="addRefunds" v-on="on" small
                    >mdi-plus</v-icon
                  >Refunds
                </template>
                <span>Add Refunds</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-tabs v-model="tab">
      <v-tab
        href="#details"
        v-if="project.DivisionID != 2 && project.DivisionID != 1002"
        >Project Details</v-tab
      >
      <v-tab href="#users" @click.stop="">Users</v-tab>
      <v-tab href="#notes">Notes</v-tab>
      <v-tab href="#time">Time</v-tab>
      <v-tab href="#stratum" v-if="project.DivisionID == 6"
        ><v-icon>mdi-format-list-bulleted-type</v-icon>Stratum</v-tab
      >
      <v-tab
        v-if="project.DivisionID == 6"
        :to="`/exceptionDatasheet/${project.ID}`"
        ><v-icon>mdi-view-list</v-icon>Exceptions</v-tab
      >
      <v-tab href="#adhoc" v-if="project.DivisionID == 6">Adhoc Reports</v-tab>
      <v-tab href="#canned" v-if="project.DivisionID == 6"
        >Canned Reports</v-tab
      >
      <v-tab href="#costs" v-if="project.DivisionID == 3">Costs</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        value="details"
        v-if="project.DivisionID != 2 && project.DivisionID != 1002"
      >
        <minerals
          v-if="project.DivisionID == 5"
          :ProjectID="project.ID"
          :DivisionId="project.DivisionID"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
          :userCanEditProjectItems="userCanEditProjectItems"
        ></minerals>
        <excise
          v-if="project.DivisionID == 6"
          :ProjectID="project.ID"
          :DivisionId="project.DivisionID"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
          :userCanEditProjectItems="userCanEditProjectItems"
        ></excise>
        <PublicFunds
          v-if="project.DivisionID == 4"
          :ProjectID="project.ID"
          :DivisionId="project.DivisionID"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
          :userCanEditProjectItems="userCanEditProjectItems"
        ></PublicFunds>
        <Banking
          v-if="project.DivisionID == 3"
          :ProjectID="project.ID"
          :DivisionId="project.DivisionID"
          :projectDetails="project.ProjectDetails"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
          :userCanEditProjectItems="userCanEditProjectItems"
        ></Banking>
      </v-tab-item>
      <!-- we want the currentUser division because the leave projects will not have users -->
      <v-tab-item value="users">
        <projectUsers
          :projectUsers="project.UserProject"
          :divisionId="currentUser.DivisionID"
          :projectId="project.ID"
          :projectDetails="project.ProjectDetails"
          @updateUsers="setUsers($event)"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
        ></projectUsers>
      </v-tab-item>
      <v-tab-item value="notes">
        <projectNotes
          :projectId="project.ID"
          :divisionId="project.DivisionID"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
          :userCanEditProjectItems="userCanEditProjectItems"
        ></projectNotes>
      </v-tab-item>
      <v-tab-item value="time">
        <projectHours
          :projectDetails="project.ProjectDetails"
          :projectId="project.ID"
          :divisionId="project.DivisionID"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
        ></projectHours>
      </v-tab-item>
      <v-tab-item value="stratum">
        <ExciseStratumGrid :projectId="project.ID"></ExciseStratumGrid>
      </v-tab-item>
      <v-tab-item value="adhoc">
        <AdhocReports :projectId="project.ID"></AdhocReports>
      </v-tab-item>
      <v-tab-item value="canned">
        <CannedReports :projectId="project.ID"></CannedReports>
      </v-tab-item>
      <v-tab-item value="costs">
        <projectCosts
          :projectId="project.ID"
          :divisionId="project.DivisionID"
          :projectNumber="project.ProjectNumber"
          :projectSummary="projectSummary"
          :userProject="project.UserProject"
        ></projectCosts>
      </v-tab-item>
    </v-tabs-items>
    <!-- <v-tab>Dept. Info</v-tab>
    <v-tab-item>
    <v-flex>
      <v-row class="pl-4">
        <v-col sm="12">
        YOUR DATA HERE
        </v-col>
      </v-row>
    </v-flex>
      </v-tab-item>-->
    <CollectionsEditVue
      ref="editCollections"
      :project="project.ID"
      :divisionID="project.DivisionID"
      :projectDetails="project.ProjectDetails"
    ></CollectionsEditVue>
    <ReportedValuesEditVue
      ref="editReportedValues"
      :project="project.ID"
      :divisionID="project.DivisionID"
      :projectDetails="project.ProjectDetails"
    ></ReportedValuesEditVue>
    <dateEditVue
      ref="editDate"
      :project="project.ID"
      :divisionID="project.DivisionID"
      :projectDetails="project.ProjectDetails"
    ></dateEditVue>
    <BudgetEditVue
      ref="editBudget"
      :project="project.ID"
      :projectDetails="project.ProjectDetails"
      :projectManagers="pfProjectManagers"
      :divisionID="project.DivisionID"
    ></BudgetEditVue>
    <ReviewEdit
      ref="editReview"
      :project="project.ID"
      :projectDetails="project.ProjectDetails"
      :divisionID="project.DivisionID"
    ></ReviewEdit>
    <TravelExpensesEdit
      ref="editTravelExpenses"
      :project="project.ID"
      :divisionID="project.DivisionID"
      :projectDetails="project.ProjectDetails"
    ></TravelExpensesEdit>
    <RefundsEdit
      ref="editRefunds"
      :project="project.ID"
      :divisionID="project.DivisionID"
      :projectDetails="project.ProjectDetails"
    ></RefundsEdit>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import Project from '@classes/Project'
import projectsDetails from '@components/projects/projectsDetails'
import projectUsers from '@components/projects/projectUsers'
import projectHours from '@components/projects/projectHours'
import projectCosts from '@components/projects/projectCosts'
import Minerals from '@components/divisions/minerals'
import Excise from '@components/divisions/excise'
import Banking from '@components/divisions/banking'
import PublicFunds from '@components/divisions/publicFunds'
import CollectionsEditVue from '@components/projects/collectionsEdit'
import ReportedValuesEditVue from '@components/projects/reportedValuesEdit'
import DateEditVue from '@components/projects/dateEdit'
import BudgetEditVue from '@components/projects/budgetEdit'
import ReviewEdit from '@components/projects/reviewEdit'
import ProjectCollections from '@classes/ProjectCollections'
import ProjectDate from '@classes/ProjectDate'
import ProjectReportedValues from '@classes/ProjectReportedValues'
import ExciseStratumGrid from '@components/grid/ExciseStratumGrid'
// import ExciseExceptionsGrid from '@components/grid/ExciseExceptionsGrid'
import ProjectBudgetedHours from '@classes/ProjectBudgetedHours'
import ProjectReviews from '@classes/ProjectReviews'
import TravelExpensesEdit from '@components/projects/travelExpensesEdit'
import ProjectsTravelExpenses from '@components/projects/projectsTravelExpenses'
import ProjectExpenses from '@classes/ProjectExpenses'
import RefundsEdit from '@components/projects/refundsEdit'
import ProjectsRefunds from '@components/projects/projectsRefunds'
import AdhocReports from '@components/projects/adhocReports'
import CannedReports from '@components/projects/cannedReports'
import ProjectRefunds from '@classes/ProjectRefunds'
import ProjectNotes from '@components/projects/projectNotes'

export default {
  components: {
    projectsDetails,
    projectUsers,
    Minerals,
    Excise,
    PublicFunds,
    CollectionsEditVue,
    ReportedValuesEditVue,
    DateEditVue,
    BudgetEditVue,
    projectHours,
    projectCosts,
    Banking,
    ReviewEdit,
    TravelExpensesEdit,
    ProjectsTravelExpenses,
    RefundsEdit,
    ProjectsRefunds,
    ProjectNotes,
    ExciseStratumGrid,
    AdhocReports,
    CannedReports,
    // ExciseExceptionsGrid,
  },
  props: {
    project: Object,
    userCanEditProjectItems: Boolean,
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialog3: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      selectedProject: new Project(),
      tab: '',
    }
  },

  computed: {
    ...authComputed,
    pfProjectManagers() {
      //added supervisors
      let managers = this.project.UserProject.filter((up) => {
        //16 is manager 3 is supervisor
        return up.ProjectRoleID == 16 || up.ProjectRoleID == 3
      })
      return managers
    },
    projectLead() {
      let lead = this.project.UserProject.filter((up) => {
        return up.ProjectRole && up.ProjectRole.IsPrimary
      })
      return lead
    },
    projectSummary() {
      var currentUrl = window.location.href
      let summary = []
      summary.push({
        Name: 'Entity',
        Data: `${this.project.SubEntity.Entity.FullName} - ${this.project.SubEntity.Description}`,
      })
      summary.push({
        Name: 'Entity Address',
        Data: `${this.project.SubEntity.Entity.Address.Address1.trim()}, ${this.project.SubEntity.Entity.Address.City.trim()}, ${
          this.project.SubEntity.Entity.Address.State.Abbreviation
        } ${this.project.SubEntity.Entity.Address.Zip.trim()}`,
      })
      if (
        this.project.DivisionID == 4 &&
        this.project.SubEntity.Entity.AuditEntityPublicFunds.ID > 0
      ) {
        summary.push({
          Name: 'Entity Type',
          Data: `${this.project.SubEntity.Entity.AuditEntityPublicFunds.EntityType.Description}`,
        })
      }
      if (this.projectLead.length > 0) {
        this.projectLead.forEach((user) => {
          summary.push({
            Name: 'Project Lead',
            Data: `${user.User.FirstName} ${user.User.LastName}`,
          })
        })
      }
      summary.push({
        Name: 'Project',
        Data: `${this.project.ProjectNumber} - ${this.project.Description}`,
      })
      summary.push({
        Name: 'Dates',
        Data: `${dayjs(this.project.AuditStart).format('MM/DD/YYYY')} - ${dayjs(
          this.project.AuditEnd
        ).format('MM/DD/YYYY')}`,
      })
      summary.push(
        {Name: 'Link', Data: currentUrl},
        {
          Name: 'Exported',
          Data: dayjs(new Date().toISOString().substr(0, 10)).format(
            'MM/DD/YYYY'
          ),
        }
      )

      return summary
    },
  },
  methods: {
    addCollections() {
      eventBus.$emit('dialogCollections', new ProjectCollections())
    },
    addReportedValues() {
      eventBus.$emit('dialogReportedValues', new ProjectReportedValues())
    },
    addDate() {
      eventBus.$emit('dialogDate', new ProjectDate())
    },
    addBudgetHours() {
      eventBus.$emit('dialogBudget', new ProjectDate())
    },
    addReview() {
      eventBus.$emit('dialogReview', new ProjectReviews())
    },
    addTravelExpenses() {
      eventBus.$emit('dialogTravelExpenses', new ProjectExpenses())
    },
    addRefunds() {
      eventBus.$emit('dialogRefunds', new ProjectRefunds())
    },
    setUsers(users) {
      this.project.UserProject = users
    },
  },
  watch: {},
}
</script>
<style scoped>
.stickyBanner {
  border: 1px solid #358345;
  background-color: var(--v-customBackground-base);
  position: sticky;
  top: 4rem;
  z-index: 2;
}
</style>
