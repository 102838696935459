<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary headline text-left white--text">
                Findings Information
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="findings"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Findings"
                        :name="`Project_Findings_${projectNumber}.xlsx`"
                      >Export</excelExport>
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items-per-page="-1"
                    :expanded.sync="expanded"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    single-expand
                    show-expand
                    dense
                    :items="findings"
                  >
                    <template v-slot:item.findingEdit="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn small color="primary" v-on="on" icon @click="openEditDialog(item)">
                            <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Finding Information</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.SentDate="{item}">
                      {{
                      item.SentDate | dateformat
                      }}
                    </template>
                    <template v-slot:item.FindingsValue="{item}">
                      <span>{{ item.FindingsValue | moneyFormat }}</span>
                    </template>
                    <template v-slot:expanded-item="{item}">
                      <td :colspan="headers.length">{{ item.Comment }}</td>
                    </template>
                    <template v-slot:item.Comment="{item}">
                      <span @click="expandRow(item)">
                        {{
                        item.Comment | snippet(50)
                        }}
                      </span>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import ProjectFindings from '@classes/ProjectFindings'
export default {

  props: {
    ProjectID: Number,
    projectNumber: String,
    divisionId: Number,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      data: [],
      search: '',
      expanded: [],
      menu: false,
      modal: false,
      menu2: false,
      panel: [0, 1],
      dialog: false,
      valid: true,
      modalText: 'Finding Information',
      loading: false,
    }
  },
  created() {
    this.loadFindingsData()
    eventBus.$on('SaveFinding', () => {
      this.loadFindingsData()
      // setTimeout(this.loadFindingsData(), 500)
    })
  },
      beforeDestroy() {
    eventBus.$off('SaveFinding')
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'findingEdit',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {
          text: 'Sent Date',
          align: 'left',
          value: 'SentDate',
          sortable: true,
          visible: true,
        },
        {
          text: 'Date Type',
          align: 'left',
          value: 'Date Type',
          sortable: true,
          visible: true,
        },
        {
          text: 'Findings Value',
          align: 'right',
          value: 'FindingsValue',
          sortable: true,
          visible: true,
        },
        {
          text: 'Findings Type',
          align: 'left',
          value: 'Finding Type',
          sortable: true,
          visible: true,
        },
        // {
        //   // EXCISE
        //   text: 'Mileage',
        //   align: 'left',
        //   value: 'mileage',
        //   sortable: true,
        // },
        {
          text: 'Comments',
          align: 'left',
          value: 'Comment',
          sortable: true,
          visible: true,
        },
        {
          text: 'County Name',
          align: 'left',
          value: 'County Name',
          sortable: true,
          visible: this.divisionId == 5,
        },
        {
          text: 'Entered By',
          align: 'left',
          sortable: true,
          visible: true,
          value: 'fullName',
          filterable: true,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      if (fields['Findings Value']) {
        fields['Findings Value'] = {
          field: 'FindingsValue',
          format: '$#,##0.00',
          sum: true,
        }
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Findings'})
      return summary
    },
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },

    findings() {
      return this.data.map((pf) => {
        return {
          ...pf,
          fullName: pf.EnteredByNavigation
            ? pf.EnteredByNavigation.FirstName +
              ' ' +
              pf.EnteredByNavigation.LastName
            : 'unk.',
          Comment: this.$filters.Snippet80(pf.Comments),
          'Finding Type': pf.FindingsType.FindingType,
          'Date Type': pf.ProjectDate.DateTypeNavigation.DateType,
          'County Name': pf.County ? pf.County.Name : '',
        }
      })
    },
  },
  methods: {
    loadFindingsData() {
      this.loading = true
      axios
        .get(`ProjectFindings/ByProject/${this.ProjectID}`)
        .then((res) => {
          this.data = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false

        })
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
    openEditDialog(value) {
      eventBus.$emit('dialogFindings', new ProjectFindings(value))
    },
  },
  watch: {},
}
</script>
<style scoped></style>
