<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary headline text-left white--text">
                Refunds
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col v-if="userCanEditProjectItems" sm="auto" class="mt-4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openRefundsDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Refunds</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="mappedData"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Refunds"
                        :name="`Project_Refunds_${projectNumber}.xlsx`"
                      >Export</excelExport>
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="visibleHeaders"
                    :items="mappedData"
                    :items-per-page="-1"
                    :search="search"
                    :loading="loading"
                    class="pt-4"
                    dense
                  >
                    <template v-if="userCanEditProjectItems" v-slot:item.dateEdit="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn small color="primary" v-on="on" icon @click="openEditDialog(item)">
                            <v-icon class="clickable" color="primary">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Refunds</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.Amount="{item, headers}">
                      <span>{{ item.Amount | money }}</span>
                    </template>
                    <template v-slot:footer>
                      <v-row>
                        <v-col sm="auto" class="ma-4">Total Refunds: {{ totalRefunds }}</v-col>
                        <v-col
                          sm="auto"
                          class="ma-4"
                        >Total Refund Amount: {{ totalRefundAmount | money }}</v-col>
                      </v-row>
                    </template>
                    <template v-slot:item.DateReceived="{item}">
                      {{
                      item.DateReceived | dateformat
                      }}
                    </template>
                    <template v-slot:item.DateProcessed="{item}">
                      {{
                      item.DateProcessed | dateformat
                      }}
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import ProjectRefunds from '@classes/ProjectRefunds'
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  props: {
    ProjectID: Number,
    DivisionId: Number,
    projectNumber: String,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      search: '',
      panel: [0, 1],
      data: [],
      loading: false
    }
  },
  created() {
    this.loadData()
    eventBus.$on('SaveRefunds', (te) => {
      this.loadData()
      eventBus.$emit('closeRefunds')
    })
  },
    beforeDestroy() {
    eventBus.$off('SaveRefunds')
  },
  filters: {
    money(value) {
      const amt = Number.parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
      return (
        '$' +
        ((amt && amt.toLocaleString(undefined, {maximumFractionDigits: 2})) ||
          '0')
      )
      // return Number.parseFloat(value).toFixed(2)
    },
  },
  computed: {
    ...authComputed,
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'dateEdit',
          sortable: false,
          visible: this.userCanEditProjectItems,
        },
        {
          text: 'Number of Refunds',
          align: 'center',
          value: 'Quantity',
          sortable: true,
          visible: true,
        },
        {
          text: 'Amount',
          align: 'right',
          value: 'Amount',
          sortable: true,
          visible: true,
        },
        {
          text: 'Date Received',
          align: 'right',
          value: 'DateReceived',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Date Processed',
          align: 'right',
          value: 'DateProcessed',
          sortable: 'true',
          visible: true,
        },
        {
          text: 'Entered By',
          align: 'right',
          value: 'enteredBy',
          sortable: 'true',
          visible: true,
        },
      ]
    },
    mappedData() {
      var data = this.data.map((item) => {
        return {
          ...item,
          enteredBy: item.EnteredByNavigation
            ? item.EnteredByNavigation.FirstName +
              ' ' +
              item.EnteredByNavigation.LastName
            : 'unknown',
        }
      })
      return data
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      fields['Number of Refunds'] = {
        field: 'Quantity',
        sum: true,
      }
      fields['Amount'] = {
        field: 'Amount',
        sum: true,
        format: '$#,##0.00',
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Refunds'})
      return summary
    },
    totalRefunds() {
      let refunds = this.data.reduce((sum, item) => {
        return sum + item.Quantity
      }, 0)
      return refunds
    },
    totalRefundAmount() {
      let amount = this.data.reduce((sum, item) => {
        return sum + item.Amount
      }, 0)
      return amount
    },
  },
  methods: {
    openRefundsDialog() {
      eventBus.$emit('dialogRefunds', new ProjectRefunds())
    },
    openEditDialog(value) {
      eventBus.$emit('dialogRefunds', new ProjectRefunds(value))
    },
    loadData() {
      this.loading = true
      axios
        .get(`ProjectRefunds/ByProject/${this.ProjectID}`)
        .then((res) => {
          this.data = res.data
      this.loading = false
        })
        .catch((err) => {
      this.loading = false

        })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
