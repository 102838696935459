<template>
  <div>
    <projectsDates
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsDates>
    <projectReviews
      v-if="projectDetails.Prefix == 'BU'"
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectReviews>
    <projectsTravelExpenses
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsTravelExpenses>
    <projectsRefunds
      v-if="projectDetails.Prefix == 'BU'"
      :ProjectID="ProjectID"
      :projectNumber="projectNumber"
      :projectSummary="projectSummary"
      :userCanEditProjectItems="userCanEditProjectItems"
    ></projectsRefunds>
  </div>
</template>
<script>
import projectsDates from '@components/projects/projectsDates'
import projectReviews from '@components/projects/projectReviews'
import projectsTravelExpenses from '@components/projects/projectsTravelExpenses'
import projectsRefunds from '@components/projects/projectsRefunds'
export default {
  components: {
    projectsDates,
    projectReviews,
    projectsTravelExpenses,
    projectsRefunds,
  },
  props: {
    projectNumber: String,
    ProjectID: Number,
    DivisionId: Number,
    projectDetails: Object,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
