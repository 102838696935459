<template>
  <div>
    <v-row justify="end">
      <v-col sm="12" md="2">
        <BaseDatePickerWithText
          label="Start Date"
          clearable
          v-model="adhocStartDate"
        >
        </BaseDatePickerWithText>
      </v-col>
      <v-col sm="12" md="2">
        <BaseDatePickerWithText
          label="End Date"
          clearable
          v-model="adhocEndDate"
        >
        </BaseDatePickerWithText>
      </v-col>
      <v-col class="ml-auto" sm="12" md="2">
        <v-text-field
          v-model="adhocText"
          prepend-inner-icon="mdi-file-document-box-search"
          label="Search"
          hint="Project Number, License Number, License Type, Entity Name, Project Description, Stratum Description, Stratum Status, Projection Type, Accounting Period, Document Number, Issue, Issue Number, Statute, Rules Reg, Transaction Type, Tax Type, Delivered To City, Delivered To County, Special District, Vendor Customer, Description, Taxable Amount, TaxPaid, Tax Rate, Reference, Status, Created By, Modified By"
        ></v-text-field>
      </v-col>
      <v-col sm="12" md="3">
        <v-autocomplete
          v-model="adhocSelectedFields"
          :items="headers"
          item-text="text"
          item-value="value"
          label="Select Columns"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        >
          <template v-slot:selection="{item, index}">
            <v-chip
              small
              v-if="index < 5"
              close
              @click:close="removeSelectedField(index)"
            >
              {{ item.text }}</v-chip
            >
            <span v-if="index === 5" class="grey--text caption"
              >(+{{ adhocSelectedFields.length - 5 }} others)</span
            >
          </template>
          <template v-slot:prepend>
            <reorderSelectedFields v-model="adhocSelectedFields">
            </reorderSelectedFields>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col sm="12" md="2">
        <h4 @click="toggleFilters" class="pointer primary--text">
          {{ filterText }}
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="filters">
      <!-- The Description and ID (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocID" 
                  :items="availableID"
                  item-text="Description" 
                  item-value="ID" 
                  label="ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <!--
          <ID
              v-model="adhocID"
              small-chips
              dense
          ></ID>
              -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocProjectID" 
                  :items="availableProjectID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Project ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3" v-if="!projectId">
        <Project
          v-model="adhocProjectID"
          :division="[6]"
          :filter="
            (item, queryText, itemText) => {
              return (
                item.ProjectNumber.toLocaleLowerCase().indexOf(
                  queryText.toLocaleLowerCase()
                ) > -1 ||
                item.Description.toLocaleLowerCase().indexOf(
                  queryText.toLocaleLowerCase()
                ) > -1
              )
            }
          "
          small-chips
          dense
        >
          <template v-slot:item="{item, index}">
            <span>{{ item.ProjectNumber }} - {{ item.Description }}</span>
          </template>
        </Project>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocProjectNumber" 
                  :items="availableProjectNumber"
                  label="Project Number"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocProjectDescription" 
                  :items="availableProjectDescription"
                  label="Project Description"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStratumID" 
                  :items="availableStratumID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Stratum ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <ExciseStratum
          v-model="adhocStratumID"
          :projectIds="adhocProjectID"
          :stratumStatus="adhocStratumStatus"
          small-chips
          dense
        >
          <!-- //todo: add filter for stratum status? -->
          <template v-slot:item="{item, index}">
            <span
              >{{ item.Description }} ({{ item.Project.ProjectNumber }})</span
            >
          </template>
        </ExciseStratum>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStratumNumber" 
                  :items="availableStratumNumber"
                  item-text="Description" 
                  item-value="ID" 
                  label="Stratum Number"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <!--
          <StratumNumber
              v-model="adhocStratumNumber"
              small-chips
              dense
          ></StratumNumber>
              -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStratumDescription" 
                  :items="availableStratumDescription"
                  label="Stratum Description"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <v-col sm="12" md="3">
        <LuExciseProjectionType
          v-model="adhocProjectionTypeID"
          small-chips
          dense
        ></LuExciseProjectionType>
      </v-col>
      <v-col sm="12" md="3">
        <v-select
          v-model="adhocStratumStatus"
          :items="availableStratumStatus"
          label="Stratum Status"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        >
        </v-select>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocProjectionTypeID" 
                  :items="availableProjectionTypeID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Projection Type ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocProjectionType" 
                  :items="availableProjectionType"
                  label="Projection Type"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocStratumIsActive" 
                  :items="['true', 'false']"
                  label="Stratum Is Active"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocDocumentNumber" 
                  :items="availableDocumentNumber"
                  label="Document Number"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocIssueID" 
                  :items="availableIssueID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Issue ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <LuExciseIssues
          v-model="adhocIssueID"
          small-chips
          dense
        ></LuExciseIssues>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocIssue" 
                  :items="availableIssue"
                  label="Issue"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocTransactionTypeID" 
                  :items="availableTransactionTypeID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Transaction Type ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <LuExciseTransactionType
          v-model="adhocTransactionTypeID"
          small-chips
          dense
        ></LuExciseTransactionType>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocTransactionType" 
                  :items="availableTransactionType"
                  label="Transaction Type"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocTaxTypeID" 
                  :items="availableTaxTypeID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Tax Type ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <LuExciseTaxTypes
          v-model="adhocTaxTypeID"
          small-chips
          dense
        ></LuExciseTaxTypes>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocTaxType" 
                  :items="availableTaxType"
                  label="Tax Type"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocDeliveredToCityID" 
                  :items="availableDeliveredToCityID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Delivered To City ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <LuCounty
          sortProperty="ID"
          v-model="adhocDeliveredToCountyID"
          small-chips
          dense
        ></LuCounty>
      </v-col>
      <v-col sm="12" md="3">
        <LuCity v-model="adhocDeliveredToCityID" small-chips dense></LuCity>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocDeliveredToCity" 
                  :items="availableDeliveredToCity"
                  label="Delivered To City"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocDeliveredToCountyID" 
                  :items="availableDeliveredToCountyID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Delivered To County ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocDeliveredToCounty" 
                  :items="availableDeliveredToCounty"
                  label="Delivered To County"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocSpecialDistrictID" 
                  :items="availableSpecialDistrictID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Special District ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <LuSpecialDistrict
          v-model="adhocSpecialDistrictID"
          small-chips
          dense
        ></LuSpecialDistrict>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocSpecialDistrict" 
                  :items="availableSpecialDistrict"
                  label="Special District"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocVendorCustomer" 
                  :items="availableVendorCustomer"
                  label="Vendor Customer"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocDescription" 
                  :items="availableDescription"
                  label="Description"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocReference" 
                  :items="availableReference"
                  label="Reference"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocIsActive" 
                  :items="['true', 'false']"
                  label="Is Active"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <v-col sm="12" md="3">
        <v-select
          v-model="adhocStatus"
          :items="availableStatus"
          label="Status"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        >
        </v-select>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocIsAppended" 
                  :items="['true', 'false']"
                  label="Is Appended"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocCreatedByID" 
                  :items="availableCreatedByID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Created By ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <User
          v-model="adhocCreatedByID"
          label="Created By"
          :division="[6]"
          small-chips
          dense
        ></User>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocCreatedBy" 
                  :items="availableCreatedBy"
                  label="Created By"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocModifiedByID" 
                  :items="availableModifiedByID"
                  item-text="Description" 
                  item-value="ID" 
                  label="Modified By ID"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
      <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
      <v-col sm="12" md="3">
        <User
          v-model="adhocModifiedByID"
          label="Modified By"
          :division="[6]"
          small-chips
          dense
        ></User>
      </v-col>
      <!-- <v-col sm="12" md="3">
              <v-select 
                  v-model="adhocModifiedBy" 
                  :items="availableModifiedBy"
                  label="Modified By"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
    </v-row>
  </div>
</template>

<script>
import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import {get, sync, commit, call} from 'vuex-pathify'
import Project from '@components/select/a-search-multi-id/Project'
import ExciseStratum from '@components/select/a-search-multi-id/ExciseStratum'
import User from '@components/select/a-search-multi-id/User'
import LuCity from '@components/select/a-multi-id/LuCity'
import LuCounty from '@components/select/a-multi-id/LuCounty'
import LuExciseProjectionType from '@components/select/a-multi-id/LuExciseProjectionType'
import LuExciseIssues from '@components/select/a-multi-id/LuExciseIssues'
// import LuCounty from '@components/select/a-multi-id/LuCounty'
import LuSpecialDistrict from '@components/select/a-multi-id/LuSpecialDistrict'
import LuExciseTaxTypes from '@components/select/a-multi-id/LuExciseTaxTypes'
import LuExciseTransactionType from '@components/select/a-multi-id/LuExciseTransactionType'
export default {
  name: 'exciseExceptionsFlatReport',
  components: {
    reorderSelectedFields,
    Project,
    User,
    LuCity,
    LuCounty,
    LuExciseProjectionType,
    LuExciseIssues,
    // LuCounty,
    LuSpecialDistrict,
    ExciseStratum,
    LuExciseTaxTypes,
    LuExciseTransactionType,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    filters: true,
    filterText: '-Hide Filters-',
    availableStatus: ['Active', 'Inactive'],
    availableStratumStatus: ['Active', 'Inactive'],
  }),
  created() {},
  watch: {},
  computed: {
    ...sync('exciseExceptionsFlat', [
      'adhocText',
      'adhocSelectedFields',
      'adhocStartDate',
      'adhocEndDate',
      //templateTODO: uncomment as used in AdHocexciseExceptionsFlatReportParams
      // 'adhocID',
      'adhocProjectID',
      // 'adhocProjectNumber',
      // 'adhocProjectDescription',
      'adhocStratumID',
      // 'adhocStratumNumber',
      // 'adhocStratumDescription',
      'adhocStratumStatus',
      'adhocProjectionTypeID',
      // 'adhocProjectionType',
      // 'adhocStratumIsActive',
      // 'adhocDocumentNumber',
      'adhocIssueID',
      // 'adhocIssue',
      'adhocTransactionTypeID',
      // 'adhocTransactionType',
      'adhocTaxTypeID',
      // 'adhocTaxType',
      'adhocDeliveredToCityID',
      // 'adhocDeliveredToCity',
      'adhocDeliveredToCountyID',
      // 'adhocDeliveredToCounty',
      'adhocSpecialDistrictID',
      // 'adhocSpecialDistrict',
      // 'adhocVendorCustomer',
      // 'adhocDescription',
      // 'adhocReference',
      // 'adhocIsActive',
      'adhocStatus',
      // 'adhocIsAppended',
      'adhocCreatedByID',
      // 'adhocCreatedBy',
      'adhocModifiedByID',
      // 'adhocModifiedBy',
    ]),
    //templateTODO: custom available lookup computed properties should go here
  },
  methods: {
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    removeSelectedField(index) {
      var list = this.adhocSelectedFields.filter((i) => true)
      list.splice(index, 1)
      this.adhocSelectedFields = list
    },
  },
}
</script>

<style lang="scss">
</style>