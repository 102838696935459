<template>
  <v-dialog
    persistent
    v-model="dialogReview"
    @keydown.esc="dialogReview = false"
    width="850"
    scrollable
  >
    <v-form ref="reviewForm" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">{{ modalText }}</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-select
                v-model="selectedProjectReview.ReviewTypeID"
                :items="availableReviewTypes"
                item-text="Description"
                item-value="ID"
                :rules="[rules.fieldRequired]"
                label="Review Type"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Date Submitted mm/dd/yyyy"
                v-model="selectedProjectReview.DateSubmitted"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <luUser
                v-model="selectedProjectReview.ReviewerID"
                item-value="ID"
                label="Reviewer"
                :divisionId="divisionID"
              ></luUser>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Date Completed mm/dd/yyyy"
                v-model="selectedProjectReview.DateCompleted"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="saving" @click="saveReview"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelReviewEdit"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import luUser from '@components/LuUser'
import ProjectReviews from '@classes/ProjectReviews'
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
import Rules from '../../validation/rules'
export default {
  components: {
    luUser,
  },
  props: {
    project: Number,
    divisionID: Number,
    projectDetails: Object,
  },
  data() {
    return {
      selectedProjectReview: new ProjectReviews(),
      dialogReview: false,
      valid: true,
      modalText: 'Exam Review',
      rules: Rules,
      saving: false,
    }
  },
  created() {
    this.loadLuReviewType()
    var self = this
    eventBus.$on('dialogReview', function (value) {
      self.selectedProjectReview = new ProjectReviews(value)
      self.dialogReview = !!value
    })
    eventBus.$on('closeProjectReview', (pb) => {
      self.cancelReviewEdit()
    })
  },
  beforeDestroy() {
    var self = this
    eventBus.$off('dialogReview')
    eventBus.$off('closeProjectReview')
  },
  computed: {
    ...authComputed,
    luReviewType: get('luReviewType/luReviewType'),
    availableReviewTypes() {
      // get active for this division
      var reviewTypes = this.luReviewType.filter(
        (r) =>
          r.IsActive &&
          // r.DivisionID == this.divisionID && review type does not have a divisionID
          (!r.Prefix || r.Prefix == this.projectDetails.Prefix)
      )
      // if existing record make sure the previous selection is available
      if (this.selectedProjectReview.ID > 0) {
        var selectedReviewType = this.luReviewType.find(
          (r) => r.ID == this.selectedProjectReview.ReviewTypeID
        )
        if (
          !reviewTypes.find(
            (r) => r.ID == this.selectedProjectReview.ReviewTypeID
          ) &&
          selectedReviewType
        ) {
          reviewTypes.push(selectedReviewType)
        }
      }
      // sort by sort order column
      reviewTypes.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return reviewTypes
    },
  },
  methods: {
    loadLuReviewType: call('luReviewType/loadLuReviewType'),
    saveProjectReviews: call('projectReviews/saveProjectReviews'),
    openDialog() {
      this.dialogReview = true
    },
    async validate() {
      this.$refs.reviewForm.validate()
    },
    cancelReviewEdit() {
      this.selectedProjectReview = new ProjectReviews()
      this.dialogReview = false
      this.$refs.reviewForm.resetValidation()
    },
    saveReview() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedProjectReview.ProjectID = this.project
          this.saving = true
          this.saveProjectReviews(this.selectedProjectReview.root())
            .then((res) => {
              eventBus.$emit('SaveReview', res.data)
              this.saving = false
            })
            .catch((error) => {
              console.error(error)
              this.saving = false
            })
        }
      })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
