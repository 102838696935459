import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"850","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.dialogReportedValues = false}},model:{value:(_vm.dialogReportedValues),callback:function ($$v) {_vm.dialogReportedValues=$$v},expression:"dialogReportedValues"}},[_c(VForm,{ref:"reportedValuesForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary"},[_c('h3',{staticClass:"hpHeading white--text"},[_vm._v(_vm._s(_vm.modalText))])]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"6"}},[_c(VTextField,{attrs:{"type":"number","rules":[function (v) { return v != null || 'Required'; }, _vm.rules.amountPattern],"label":"Value Reported","prefix":"$"},model:{value:(_vm.selectedReportedValue.ReportedValue),callback:function ($$v) {_vm.$set(_vm.selectedReportedValue, "ReportedValue", $$v)},expression:"selectedReportedValue.ReportedValue"}})],1),_c(VCol,{attrs:{"sm":"12","md":"6"}},[_c(VSelect,{attrs:{"items":_vm.availableValueTypes,"rules":[_vm.rules.fieldRequired],"item-text":"ReportedType","item-value":"ID","label":"Value Type"},model:{value:(_vm.selectedReportedValue.ReportedValueTypeID),callback:function ($$v) {_vm.$set(_vm.selectedReportedValue, "ReportedValueTypeID", $$v)},expression:"selectedReportedValue.ReportedValueTypeID"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.saveReportedValues}},[_vm._v("Save")]),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelReportedValues}},[_vm._v("Cancel")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }