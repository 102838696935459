<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Project Notes
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col
                      v-if="userCanEditProjectItems"
                      sm="auto"
                      class="mt-4"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            @click="openNote"
                            color="primary"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add New Note</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="mappedData"
                        :exportFields="excelFields"
                        :summaryData="summaryData"
                        worksheet="Project Note"
                        :name="`Project_Notes_${projectNumber}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="mappedData"
                    :expanded.sync="expanded"
                    single-expand
                    show-expand
                  >
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.noteEdit="{item}"
                    >
                      <v-tooltip v-if="currentUser.ID == item.EnteredBy" bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="editNote(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit Note</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="userCanEditProjectItems"
                      v-slot:item.noteDelete="{item}"
                    >
                      <v-tooltip v-if="currentUser.ID == item.EnteredBy" bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            @click="deleteNote(item)"
                            icon
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete Note</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.EnteredDate="{item}">
                      {{ item.EnteredDate | dateformat }}
                    </template>
                    <template v-slot:item.Note="{item, headers}">
                      <span @click="expandRow(item)">
                        {{ item.Note | snippet(120) }}
                      </span>
                    </template>
                    <template v-slot:expanded-item="{item, headers}">
                      <td :colspan="headers.length" class="pa-2">
                        {{ item.Note }}
                      </td>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="noteDialog"
      @keydown.esc="noteDialog = false"
      width="450"
    >
      <v-form
        ref="notesForm"
        @submit.prevent
        lazy-validation
        scrollable
        v-model="valid"
      >
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">Project Notes</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="12">
                <v-textarea
                  v-model="selectedNote.Note"
                  label="Add Note"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" :disabled="saving" @click="saveNote"
              >Save</v-btn
            >
            <v-btn @click="closeNote" outlined color="primary">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog persistent v-model="deleteDialog" scrollable width="620">
      <v-form ref="notesForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text"
              >Are you sure you want to delete this note?</h3
            >
          </v-card-title>
          <v-card-text class="pa-4"> </v-card-text>
          <v-card-actions>
            <v-btn @click="closeDeleteNote" color="primary">Cancel</v-btn>
            <v-btn @click="deleteSelectedNote" outlined color="primary"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ProjectNote from '@classes/ProjectNote'
import {authMethods, authComputed} from '@state/helpers'
import excelExport from '@components/excelExport'
export default {
  props: {
    projectId: Number,
    projectNumber: String,
    projectSummary: Array,
    userCanEditProjectItems: Boolean,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      selectedNote: new ProjectNote(),
      search: '',
      noteDialog: false,
      deleteDialog: false,
      valid: true,
      expanded: [],
      singleExpand: false,
      panel: [0, 1],
      data: [],
      saving: false,
    }
  },
  created() {
    this.loadNotes()
  },
  computed: {
    ...authComputed,
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Project Notes'})
      return summary
    },
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'noteEdit',
          visible: this.userCanEditProjectItems,
          sortable: false,
        },
        {
          text: 'Note',
          align: 'left',
          value: 'Note',
          visible: true,
          sortable: false,
        },
        {
          text: 'Entered By',
          align: 'left',
          value: 'enteredBy',
          visible: true,
          sortable: false,
        },
        {
          text: 'Entered Date',
          align: 'left',
          value: 'EnteredDate',
          visible: true,
          sortable: false,
        },
        {
          text: '',
          align: 'left',
          value: 'noteDelete',
          visible: this.userCanEditProjectItems,
          sortable: false,
          visible: true,
        },
        {text: '', value: 'data-table-expand', visible: true},
      ]
    },
    mappedData() {
      var data = this.data.map((item) => {
        return {
          ...item,
          enteredBy: item.EnteredByNavigation
            ? item.EnteredByNavigation.FirstName +
              ' ' +
              item.EnteredByNavigation.LastName
            : 'unknown',
        }
      })
      return data
    },
  },
  methods: {
    saveProjectNote: call('projectNote/saveProjectNote'),
    deleteProjectNote: call('projectNote/deleteProjectNote'),
    async validate() {
      this.$refs.notesForm.validate()
    },
    saveNote() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedNote.ProjectID = this.projectId
          this.saving = true
          this.saveProjectNote(this.selectedNote.root())
            .then((res) => {
              this.selectedNote = new ProjectNote({
                ProjectID: this.projectId,
              })
              this.loadNotes()
              this.noteDialog = false
              this.saving = false
            })
            .catch((error) => {
              console.error(error)
              this.saving = false
            })
        }
      })
    },
    deleteSelectedNote() {
      this.selectedNote.ProjectID = this.projectId
      this.deleteProjectNote(this.selectedNote.root())
        .then((res) => {
          this.selectedNote = new ProjectNote({
            ProjectID: this.projectId,
          })
          this.loadNotes()
          this.deleteDialog = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
    openNote() {
      this.selectedNote = new ProjectNote()
      this.noteDialog = !this.noteDialog
    },
    editNote(item) {
      this.selectedNote = new ProjectNote(item)
      this.noteDialog = true
    },
    deleteNote(item) {
      this.selectedNote = new ProjectNote(item)
      this.deleteDialog = true
    },
    closeDeleteNote() {
      this.selectedNote = new ProjectNote()
      this.deleteDialog = false
    },
    closeNote() {
      this.selectedNote = new ProjectNote()
      this.noteDialog = false
    },
    loadNotes() {
      axios
        .get(`ProjectNote/ByProject/${this.projectId}`)
        .then((res) => {
          this.data = res.data
        })
        .catch((err) => {})
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
  },
  watch: {},
}
</script>
<style scoped></style>
