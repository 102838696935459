import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"items":_vm.users,"label":_vm.label,"multiple":_vm.multi,"rules":_vm.rules,"item-text":"FirstName","item-value":"ID"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(item.FirstName)+" "+_vm._s(item.LastName))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(item.FirstName)+" "+_vm._s(item.LastName))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }