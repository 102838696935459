<template>
  <div>
    <activityHours
      :projectNumber="projectNumber"
      :projectId="projectId"
      :divisionId="divisionId"
      :data="mappedData"
      :projectSummary="projectSummary"
    ></activityHours>
    <activityUserHours
      :projectNumber="projectNumber"
      :projectId="projectId"
      :divisionId="divisionId"
      :data="mappedData"
      :projectSummary="projectSummary"
    ></activityUserHours>
    <userHours
      :projectNumber="projectNumber"
      :projectId="projectId"
      :divisionId="divisionId"
      :data="mappedData"
      :projectSummary="projectSummary"
    ></userHours>
    <detailHours
      :projectDetails="projectDetails"
      :projectNumber="projectNumber"
      :projectId="projectId"
      :divisionId="divisionId"
      :data="mappedData"
      :projectSummary="projectSummary"
    ></detailHours>
  </div>
</template>
<script>
import excelExport from '@components/excelExport'
import activityHours from '@components/projects/activityHours'
import detailHours from '@components/projects/detailHours'
import activityUserHours from '@components/projects/activityUserHours'
import userHours from '@components/projects/userHours'
import TimeSheetEntry from '@classes/TimeSheetEntry'
import {get, sync, commit, call} from 'vuex-pathify'
export default {

  props: {
    projectNumber: String,
    projectId: Number,
    divisionId: Number,
    projectDetails: Object,
    projectSummary: Array,
  },
  data() {
    return {
      data: [],
    }
  },
  filters: {},
  components: {
    activityHours,
    detailHours,
    activityUserHours,
    userHours,
  },
  created() {
    this.getProjectTime()
  },
  computed: {
    projectTimeSheetEntries: get('timeSheetEntry/projectTimeSheetEntries'),
    mappedData() {
      var data = this.projectTimeSheetEntries.map((item) => {
        return {
          ...item,
          activityCode: item.Code.Code,
          activityDescription: item.Code.Description,
          billable: item.BillableCategory
            ? item.BillableCategory.Description
            : 'No',
          userId: item.TimeSheet.UserID,
          user:
            item.TimeSheet.User.FirstName + ' ' + item.TimeSheet.User.LastName,
        }
      })
      return data
    },
  },
  methods: {
    loadProjectTimeSheetEntries: call(
      'timeSheetEntry/loadProjectTimeSheetEntries'
    ),
    getProjectTime() {
      if (this.projectId) {
        this.loadProjectTimeSheetEntries(this.projectId)
      }
    },
  },
  watch: {},
}
</script>
<style scoped></style>
