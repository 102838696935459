import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"primary headline text-left white--text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"white--text"},[_vm._v("$vuetify.icons.expand")])]},proxy:true}])},[_vm._v(" Travel Expenses ")]),_c(VExpansionPanelContent,[_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[(_vm.userCanEditProjectItems)?_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","fab":"","depressed":"","small":""},on:{"click":function($event){return _vm.openTravelExpensesDialog()}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}],null,false,2952018104)},[_c('span',[_vm._v("Add Travel Expenses")])])],1):_vm._e(),_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c('excelExport',{attrs:{"data":_vm.mappedData,"exportFields":_vm.excelFields,"summaryData":_vm.summaryData,"worksheet":"Project Expenses","name":("Project_Expenses_" + _vm.projectNumber + ".xlsx")}},[_vm._v("Export")])],1)],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"item-key":"ID","headers":_vm.visibleHeaders,"items":_vm.mappedData,"items-per-page":-1,"expanded":_vm.expanded,"search":_vm.search,"loading":_vm.loading,"single-expand":"","show-expand":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(_vm.userCanEditProjectItems)?{key:"item.dateEdit",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Travel Expenses")])])]}}:null,{key:"item.POV",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.POV)))])]}},{key:"item.StateCar",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.StateCar)))])]}},{key:"item.AirFare",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.AirFare)))])]}},{key:"item.Hotel",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.Hotel)))])]}},{key:"item.MIE",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.MIE)))])]}},{key:"item.RentalCar",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.RentalCar)))])]}},{key:"item.Other",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.Other)))])]}},{key:"item.totals",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',[_vm._v(_vm._s(_vm._f("money")(item.totals)))])]}},{key:"item.Comments",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('span',{on:{"click":function($event){return _vm.expandRow(item)}}},[_vm._v(" "+_vm._s(_vm._f("snippet")(item.Comments,50))+" ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.Comments)+" ")])]}}],null,true)})]],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }