<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Activity Time
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :data="mappedData"
                        :summaryData="summaryData"
                        :exportFields="excelFields"
                        worksheet="Activity Time"
                        :name="`Activity_Time_${projectNumber}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="mappedData"
                    :items-per-page="-1"
                    :search="search"
                    class="pt-4"
                    dense
                  >
                    <template v-slot:item.hours="{item}">{{
                      item.hours | decimalToTime
                    }}</template>
                    <template v-slot:footer>
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col sm="auto" class="ma-4"
                          >Total Hours: {{ totalActivityTime }}</v-col
                        >
                      </v-row>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template
>
<script>
import excelExport from '@components/excelExport'
export default {
  props: {
    projectNumber: String,
    projectId: Number,
    divisionId: Number,
    data: Array,
    projectSummary: Array,
  },
  components: {
    excelExport,
  },
  filters: {
    decimalToTime(d) {
      var decimalTime = parseFloat(d) * 60 * 60
      var hours = Math.floor(decimalTime / (60 * 60))

      decimalTime = decimalTime - hours * 60 * 60
      var minutes = Math.round(decimalTime / 60)

      if (minutes < 10) {
        minutes = '0' + minutes
      }

      return hours + ':' + minutes
    },
  },
  data() {
    return {
      search: '',
      panel: [0],
    }
  },
  created() {},
  computed: {
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: 'Activity',
          align: 'left',
          sortable: 'true',
          value: 'activityCode',
          visible: true,
        },
        {
          text: 'Code Description',
          align: 'left',
          sortable: 'true',
          value: 'activityDescription',
          visible: true,
        },
        {
          text: 'Hours',
          align: 'right',
          sortable: 'true',
          value: 'hours',
          visible: true,
        },
      ]
    },

    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        fields[item.text] = item.value
      })
      fields.hours = {
        field: 'hours',
        callback: (value) => {
          return value / 24
        },
        format: '[h]:mm',
        sum: true,
      }
      fields.Hours = {
        field: 'hours',
        sum: true,
      }
      return fields
    },
    summaryData() {
      let summary = JSON.parse(JSON.stringify(this.projectSummary))
      summary.push({Name: 'Title', Data: 'Activity Time'})
      return summary
    },
    mappedData() {
      let data = []
      let activities = [...new Set(this.data.map((item) => item.activityCode))]
      activities.forEach((activity) => {
        let matches = this.data.filter((item) => item.activityCode == activity)
        if (matches[0]) {
          let entry = {
            activityCode: matches[0].activityCode,
            activityDescription: matches[0].activityDescription,
            user: matches[0].user,
            hours: matches.reduce((prev, item) => {
              return prev + item.Hours
            }, 0),
          }
          data.push(entry)
        }
      })
      return data
    },
    totalActivityTime() {
      let totalTime = this.data.reduce((sum, item) => {
        return sum + item.Hours
      }, 0)
      return totalTime
    },
  },
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
