<template>
  <v-dialog
    persistent
    v-model="dialogReportedValues"
    @keydown.esc="dialogReportedValues = false"
    width="850"
    scrollable
  >
    <v-form
      ref="reportedValuesForm"
      @submit.prevent
      lazy-validation
      v-model="valid"
    >
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">{{ modalText }}</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12" md="6">
              <v-text-field
                v-model="selectedReportedValue.ReportedValue"
                type="number"
                :rules="[(v) => v != null || 'Required', rules.amountPattern]"
                label="Value Reported"
                prefix="$"
              ></v-text-field>
            </v-col>
            <!-- HIDE FOR EXCISE -->
            <v-col sm="12" md="6">
              <v-select
                v-model="selectedReportedValue.ReportedValueTypeID"
                :items="availableValueTypes"
                :rules="[rules.fieldRequired]"
                item-text="ReportedType"
                item-value="ID"
                label="Value Type"
              ></v-select>
            </v-col>
            <!-- Stage EXCISE FIELD ONLY/NO LONGER NEEDED -->
            <!-- <v-col sm="12" md="6">
                <v-select label="Stage"></v-select>
            </v-col>-->
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" :disabled="saving" @click="saveReportedValues"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelReportedValues"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ProjectReportedValues from '@classes/ProjectReportedValues'
import {mask} from 'vue-the-mask'
import Rules from '../../validation/rules'
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  directives: {
    mask,
  },
  props: {
    project: Number,
    divisionID: Number,
  },
  data() {
    return {
      selectedReportedValue: new ProjectReportedValues(),
      dialogReportedValues: false,
      valid: true,
      modalText: 'Reported Value',
      rules: Rules,
      saving: false,
    }
  },
  created() {
    this.loadLuReportedType()
    var vm = this
    eventBus.$on('dialogReportedValues', function (value) {
      vm.selectedReportedValue = value
      vm.dialogReportedValues = !!value
    })
    eventBus.$on('closeReportedValues', (rv) => {
      vm.dialogReportedValues = false
    })
  },
  beforeDestroy() {
    var vm = this
    eventBus.$off('dialogReportedValues')
    eventBus.$off('closeReportedValues')
  },
  computed: {
    luReportedType: get('luReportedType/luReportedType'),

    availableValueTypes() {
      // get active for this division
      var valueTypes = this.luReportedType.filter(
        (v) => v.IsActive && v.DivisionID == this.divisionID
      )
      // if existing record make sure the previous selection is available
      if (this.selectedReportedValue.ID > 0) {
        var selectedValueType = this.luReportedType.find(
          (v) => v.ID == this.selectedReportedValue.ReportedValueTypeID
        )
        if (
          !valueTypes.find(
            (v) => v.ID == this.selectedReportedValue.ReportedValueTypeID
          ) &&
          selectedValueType
        ) {
          valueTypes.push(selectedValueType)
        }
      }
      // sort by sort order column
      valueTypes.sort((a, b) => {
        let textField = 'ReportedType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return valueTypes
    },
  },
  methods: {
    loadLuReportedType: call('luReportedType/loadLuReportedType'),
    saveProjectReportedValues: call(
      'projectReportedValues/saveProjectReportedValues'
    ),
    openDialog() {
      this.dialogReportedValues = true
    },
    async validate() {
      this.$refs.reportedValuesForm.validate()
    },
    saveReportedValues() {
      this.validate().then(() => {
        if (this.valid) {
          this.selectedReportedValue.ProjectID = this.project
          this.saving = true
          this.saveProjectReportedValues(this.selectedReportedValue.root())
            .then((res) => {
              eventBus.$emit('SaveReportedValues', res.data)
              this.saving = false
            })
            .catch((error) => {
              console.error(error)
              this.saving = false
            })
        }
      })
    },
    cancelReportedValues() {
      this.selectedReportedValue = new ProjectReportedValues({
        ProjectID: this.project,
      })
      this.dialogReportedValues = false
      this.$refs.reportedValuesForm.resetValidation()
    },
  },
  watch: {},
}
</script>
<style scoped></style>
