<template>
  <v-autocomplete
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :search-input.sync="lookupText"
    @change="lookupText = ''"
    :loading="loading"
    :placeholder="placeholder"
    :item-text="itemText"
    item-value="ID"
    :clearable="clearable"
    :rules="rules"
    cache-items
    chips
    deletable-chips
    multiple
    dense
  >
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      v-slot:[scopedSlotName]="slotData"
    >
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-autocomplete>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  name: 'autocomplete-search-multi-id-user',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'User',
    },
    division: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Start typing to search',
    },
    value: Array,
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: Array,
  },
  data() {
    return {
      //templateFIX: if there is more than one pick one to display
      // itemText: 'FirstName',
      // itemText: 'LastName',
      itemText: 'eMail',
      // itemText: 'EmployeeID',
    }
  },
  watch: {
    value(nval, oval) {
      if (nval != oval) {
        this.lookupText = ''
      }
    },
    division(nval, oval) {
      this.searchDivision = nval
    },
  },
  computed: {
    ...get('user', ['user', 'loading']),
    ...sync('user', ['lookupText', 'searchDivision']),
    availableItems() {
      var list = this.user.filter((i) => true)
      //filter for not deleted items
      list = list.filter((i) => !i.IsDeleted)
      // if existing record make sure the previous selection is available even if no longer active
      if (this.value > 0) {
        var selectedItem = this.user.find((i) => i.ID == this.value)
        if (!list.find((i) => i.ID == this.value) && selectedItem) {
          list.push(selectedItem)
        }
      }
      // sort by column
      list.sort((a, b) => {
        //set the property to sort on
        let textField = this.itemText
        let sort = a[textField] > b[textField] ? 1 : -1
        return sort
      })
      return list
    },
    selected: {
      get() {
        if (this.value && this.value.length > 0) {
          return this.value
        } else {
          return []
        }
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
  },
  created() {
    this.searchDivision = this.division
  },
  methods: {},
}
</script>
<style>
</style>