import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"primary headline text-left white--text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"white--text"},[_vm._v("$vuetify.icons.expand")])]},proxy:true}])},[_vm._v(" Time Details ")]),_c(VExpansionPanelContent,[_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,[_c(VTextField,{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c('excelExport',{attrs:{"data":_vm.data,"exportFields":_vm.excelFields,"summaryData":_vm.summaryData,"worksheet":"Project Hours","name":("Project_Hours_" + _vm.projectNumber + ".xlsx")}},[_vm._v("Export")])],1)],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"item-key":"ID","headers":_vm.visibleHeaders,"items":_vm.data,"items-per-page":-1,"search":_vm.search,"show-group-by":"","group-by":_vm.divisionId == 5 ? [] : 'Date',"dense":"","sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)}},scopedSlots:_vm._u([{key:"item.billable",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._v(" "+_vm._s(item.billable)+" "),_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openBillableEdit(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Billable Status")])])]}},{key:"item.Hours",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.Hours)))]}},{key:"footer",fn:function(){return [_c(VRow,[_c(VSpacer),_c(VCol,{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Total Hours: "+_vm._s(_vm.totalTimeDetails))])],1)]},proxy:true}])})]],2)],1)],1)],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","scrollable":"","width":"300"},model:{value:(_vm.billableDialog),callback:function ($$v) {_vm.billableDialog=$$v},expression:"billableDialog"}},[_c(VForm,{ref:"billableForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary"},[_c('h3',{staticClass:"hpHeading white--text"},[_vm._v("Billable Time?")])]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_vm._v(" "+_vm._s(_vm.selectedTimeSheetEntry.Date)+" - "+_vm._s(_vm.selectedTimeSheetEntry.TimeSheet.User.FirstName)+" "+_vm._s(_vm.selectedTimeSheetEntry.TimeSheet.User.LastName)+" - "+_vm._s(_vm._f("decimalToTime")(_vm.selectedTimeSheetEntry.Hours))+" ")])],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_vm._v(" Change this time to be "),_c(VSelect,{attrs:{"items":_vm.availableBillableCategories,"item-text":"Description","item-value":"ID","label":"Billing Category","clearable":""},model:{value:(_vm.selectedTimeSheetEntry.BillableCategoryID),callback:function ($$v) {_vm.$set(_vm.selectedTimeSheetEntry, "BillableCategoryID", $$v)},expression:"selectedTimeSheetEntry.BillableCategoryID"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.confirmBillableTime}},[_vm._v("Save")]),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelBillableEdit}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }