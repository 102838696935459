<template>
  <div>
    <BasePanelWithHeader headerText="Canned Project Reports">
      <v-row>
        <v-col>
          <v-select
            label="Select Report"
            :items="availableCannedReports"
            return-object
            v-model="selectedCannedReport"
            item-text="Description"
          >
          </v-select>
        </v-col>
        <downloadButtons
          v-if="
            selectedCannedReport.FileTypes &&
              selectedCannedReport.FileTypes.length > 0
          "
          :fileTypes="selectedCannedReport.FileTypes"
          @downloadReport="downloadReport"
        ></downloadButtons>
      </v-row>
      <reportParameters
        v-if="reportParameters.length > 0"
        :reportParameters="reportParameters"
        :searchParams="searchParams"
        @updateSearchParam="updateSearchParam"
        :staticParams="['projectId', 'licenseNumber', 'ridNumber']"
      ></reportParameters>
      <v-row>
        <v-col sm="12" md="6">
          <v-select
            v-if="
              selectedCannedReport &&
                selectedCannedReport.reportDefaults &&
                selectedCannedReport.reportDefaults.ReportSections &&
                selectedCannedReport.reportDefaults.ReportSections.length > 0
            "
            label="Show Section"
            :items="selectedCannedReport.reportDefaults.ReportSections"
            v-model="sectionId"
            item-text="Name"
            item-value="ID"
          >
          </v-select>
        </v-col>
      </v-row>
      <BaseServerSyncDataTable
        v-if="selectedCannedReport.ID"
        :headers="filteredHeaders"
        :items="gridData"
        :searchTotal="searchTotal"
        :loading="loading"
        :searchSortBy.sync="searchSortBy"
        :searchDescending.sync="searchDescending"
        :searchPage.sync="searchPage"
        :searchRowsPerPage.sync="searchRowsPerPage"
        multi-sort
        :show-group-by="showGroupBy"
        :groupBy.sync="groupBy"
        :groupDesc.sync="groupByDescending"
      >
        <template
          v-for="(format, field) in mappedFormats"
          v-slot:[field]="{item}"
          >{{ formatItemField(item, field, format) }}</template
        >
      </BaseServerSyncDataTable>
    </BasePanelWithHeader>
  </div>
</template>
<script>
import {ref, reactive, watch, computed} from 'vue'
import {get, sync, commit, call} from 'vuex-pathify'
import {authComputed} from '@state/helpers'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat) // needed for custom date parsing
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat)
import reportParameters from '@components/reports/reportParameters'
import downloadButtons from '@components/reports/downloadButtons'
import useDisplayFormatters from '@composables/useDisplayFormatters'
export default {
  //model: {
  //	prop: ''
  //},
  props: {
    projectId: {
      required: true,
      type: Number,
    },
  },
  components: {
    reportParameters,
    downloadButtons,
  },
  filters: {},
  setup(props, context) {
    const {formatItemField, splitTitleCase} = useDisplayFormatters()
    return {
      formatItemField,
      splitTitleCase,
    }
  },
  data() {
    return {
      searchParams: {},
      cannedReports: [],
    }
  },
  created() {
    this.getCannedReportList()
    this.searchProjectId = this.projectId
    this.loadLuExciseTransactionType()
    this.loadLuExciseProjectionType()
    if (this.projectId) {
      this.loadSelectedProject(this.projectId).then(() => {
        if (this.selectedProject && this.selectedProject.ProjectLicense) {
          this.searchLicenseNumber = this.selectedProject.ProjectLicense.LicenseNumber
          this.searchStartDate = this.selectedProject.AuditStart
          this.searchEndDate = this.selectedProject.AuditEnd
        }
        this.loadSelectedProjectExciseStratum()
      })
    }
  },
  computed: {
    ...authComputed,
    ...get('cannedReport', [
      'reportParameters',
      'gridData', //entries in reportresult
      'loading',
      'headerItems', // from reportresult
      'formatFields', // from reportresult
    ]),
    ...get('project', ['selectedProject', 'selectedProjectExciseStratum']),
    ...get('luExciseTransactionType', ['luExciseTransactionType']),
    ...get('luExciseProjectionType', ['luExciseProjectionType']),
    ...sync('cannedReport', [
      'selectedCannedReport',
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'groupByProperty',
      'groupByDescending',
      'searchRowsPerPage',
      'sectionId',
    ]),
    searchProjectId: sync('cannedReport/projectId'),
    searchStratumIds: sync('cannedReport/stratumIds'),
    searchTransactionTypeId: sync('cannedReport/transactionTypeId'),
    searchProjectionTypeId: sync('cannedReport/projectionTypeId'),
    searchLicenseNumber: sync('cannedReport/licenseNumber'),
    searchRidNumber: sync('cannedReport/ridNumber'),
    searchStartDate: sync('cannedReport/startDate'),
    searchEndDate: sync('cannedReport/endDate'),
    headers() {
      var groupByProperties = this.selectedCannedReport.reportParameters.find(
        (i) => i.name == 'groupByProperty'
      )
      if (
        groupByProperties &&
        groupByProperties.options &&
        groupByProperties.options.list
      ) {
        groupByProperties = groupByProperties.options.list
      }
      let headers = this.headerItems.map((item) => {
        return {
          text: this.splitTitleCase(item),
          align: 'center',
          value: item,
          visible: true,
          groupable: groupByProperties
            ? groupByProperties.findIndex((i) => i.stringValue == item) > -1
            : false,
          //todo: set sortable property
        }
      })
      return headers
    },
    showGroupBy() {
      return this.headers.filter((x) => x.groupable).length > 0
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    //note: this is totally lame, but if I try to use a string literal in the v-slot vscode reformats it to urlencoded
    // this was tested with v-slot:[`item.${field}`] and vscode/vetur? converts to v-slot:[&#x60;item.${field}&#x60;] :(
    mappedFormats() {
      let slotFormat = {}
      for (var key in this.formatFields) {
        if (this.formatFields.hasOwnProperty(key)) {
          slotFormat[`item.${key}`] = this.formatFields[key]
        }
      }
      return slotFormat
    },

    reportParameters() {
      if (
        this.selectedCannedReport &&
        this.selectedCannedReport.reportParameters
      ) {
        let params = JSON.parse(
          JSON.stringify(this.selectedCannedReport.reportParameters)
        )
        params.forEach((p) => {
          var list = []
          if (p.options && p.options.list && p.options.list.length > 0) {
            p.options.list = p.options.list.map((i) => {
              return {
                text: i.text,
                value: i.value ? i.value : i.stringValue,
              }
            })
          } else {
            if (p.name == 'stratumIds') {
              list = this.selectedProjectExciseStratum.filter((i) => i.IsActive)
              if (
                this.searchProjectionTypeId &&
                this.searchProjectionTypeId > 0
              ) {
                list = list.filter(
                  (i) => i.ProjectionTypeID == this.searchProjectionTypeId
                )
              }
            }
            if (p.name == 'transactionTypeId') {
              list = JSON.parse(JSON.stringify(this.luExciseTransactionType))
            }
            if (p.name == 'projectionTypeId') {
              list = JSON.parse(JSON.stringify(this.luExciseProjectionType))
            }
            if (list.length > 0) {
              p.options.list = this.filterList(p, list)
            }
          }
        })
        return params
      } else {
        return []
      }
    },
    needsParameters() {
      if (this.reportParameters && this.reportParameters.length > 0) {
        return true
      } else {
        return false
      }
    },
    availableCannedReports() {
      return this.cannedReports.filter(
        (x) =>
          x.IsActive &&
          x.reportParameters.findIndex(
            (x) => x.name == 'projectId' || x.name == 'licenseNumber'
          ) > -1
      )
    },
    groupBy: {
      get() {
        if (this.groupByProperty) {
          return [this.groupByProperty]
        } else {
          return []
        }
      },
      set(nval) {
        if (nval) {
          if (Array.isArray(nval)) {
            if (nval.length > 0) {
              this.groupByProperty = nval[0]
            } else {
              this.groupByProperty = null
            }
          } else {
            this.groupByProperty = nval
          }
        } else {
          this.groupByProperty = null
        }
      },
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
    loadLuExciseProjectionType: call(
      'luExciseProjectionType/loadLuExciseProjectionType'
    ),
    ...call('project', [
      'loadSelectedProject',
      'loadSelectedProjectExciseStratum',
    ]),
    ...call('cannedReport', [
      'loadCannedReportGridData',
      'downloadCannedReport',
    ]),
    filterList(param, list) {
      if (list && list.length > 0) {
        if (
          param &&
          param.options &&
          param.options.filterList &&
          param.options.filterList.length > 0
        ) {
          param.options.filterList.forEach((x) => {
            if (x.valueMatch) {
              let matchList = JSON.parse(x.valueMatch)
              if (matchList && matchList.length > 0) {
                list = list.filter((i) => matchList.includes(i[x.propertyName]))
              }
            }
          })
        }
        return list.map((x) => {
          return {
            text: x.Description,
            value: x.ID,
          }
        })
      }
    },
    downloadReport(fileType) {
      this.downloadCannedReport({selectedFileTypeID: fileType.ID})
    },
    getCannedReportList() {
      let url = `/Division/${this.currentUser.DivisionID}/CannedReport`
      this.$axios.get(url).then(
        (res) => {
          this.cannedReports = res.data
        },
        (error) => {
          this.handleError(error)
        }
      )
    },
    setDefaultParameters() {
      this.reportParameters.forEach((item) => {
        if (!this.searchParams[item.name] && item.defaultValue) {
          this.searchParams[item.name] = item.defaultValue
        }
      })
      if (this.searchParams.startDate) {
        this.searchStartDate = this.searchParams.startDate
      }
      if (this.searchParams.endDate) {
        this.searchEndDate = this.searchParams.endDate
      }
    },
    updateSearchParam(p) {
      if (p.name == 'startDate') {
        this.searchStartDate = p.val
      } else if (p.name == 'endDate') {
        this.searchEndDate = p.val
      } else if (p.name == 'stratumIds') {
        this.searchStratumIds = p.val
      } else if (p.name == 'transactionTypeId') {
        this.searchTransactionTypeId = p.val
      } else if (p.name == 'projectionTypeId') {
        this.searchProjectionTypeId = p.val
      } else if (p.name == 'groupByProperty') {
        this.groupByProperty = p.val
      } else if (p.name == 'licenseNumber') {
        this.searchLicenseNumber = p.val
      } else if (p.name == 'ridNumber') {
        this.searchRidNumber = p.val
      }
    },
  },
  watch: {
    reportParameters(nval, oval) {
      this.setDefaultParameters()
    },
    selectedCannedReport(nval, oval) {
      if (
        nval.reportDefaults &&
        nval.reportDefaults.ReportSections &&
        nval.reportDefaults.ReportSections.length > 0
      ) {
        this.sectionId = nval.reportDefaults.ReportSections[0].ID
      } else {
        this.sectionId = null
      }
      this.loadCannedReportGridData()
    },
    searchStartDate(nval, oval) {
      this.searchParams.startDate = nval
    },
    searchEndDate(nval, oval) {
      this.searchParams.endDate = nval
    },
    searchStratumIds(nval, oval) {
      this.searchParams.stratumIds = nval
    },
    searchProjectionTypeId(nval, oval) {
      this.searchParams.projectionTypeId = nval
    },
    searchTransactionTypeId(nval, oval) {
      this.searchParams.transactionTypeId = nval
    },
    searchLicenseNumber(nval, oval) {
      this.searchParams.licenseNumber = nval
    },
    searchRidNumber(nval, oval) {
      this.searchParams.ridNumber = nval
    },
    groupByProperty(nval, oval) {
      if (nval && !oval) {
        // this sets the sort by to the date (asc) if we are grouping the report
        // if we are adding another canned report that does grouping we may want to do a check for just the review of transactions report
        this.searchSortBy = ['InvoiceDate']
        this.searchSortDesc = [false]
      }
      this.searchParams.groupByProperty = nval
    },
  },
}
</script>
<style scoped></style>
