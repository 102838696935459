<template>
  <div>
    <!-- ASSIGNED USERS -->
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel2" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Assigned Users
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col sm="12">
                      <v-list-item v-if="roleUsers.length > 0">
                        <v-chip-group
                          v-if="isSuperUser || isProjectCreator"
                          multiple
                          column
                        >
                          <v-chip
                            pill
                            v-for="projectUser in roleUsers"
                            :key="projectUser.ID"
                            close
                            @click="addProjectRole(projectUser)"
                            @click:close="removeUser(projectUser)"
                          >
                            <v-avatar left color="primary">
                              <strong class="white--text">
                                {{ projectUser.initials }}
                              </strong>
                            </v-avatar>
                            {{ projectUser.fullName }} - {{ projectUser.role }}
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-else multiple column>
                          <v-chip
                            pill
                            v-for="projectUser in roleUsers"
                            :key="projectUser.ID"
                          >
                            <v-avatar left color="primary">
                              <strong class="white--text">
                                {{ projectUser.initials }}
                              </strong>
                            </v-avatar>
                            {{ projectUser.fullName }} - {{ projectUser.role }}
                          </v-chip>
                        </v-chip-group>
                      </v-list-item>
                      <v-list-item>
                        <v-chip-group
                          v-if="isSuperUser || isProjectCreator"
                          multiple
                          column
                        >
                          <v-chip
                            pill
                            v-for="projectUser in nonRoleUsers"
                            :key="projectUser.ID"
                            close
                            @click="addProjectRole(projectUser)"
                            @click:close="removeUser(projectUser)"
                          >
                            <v-avatar left color="primary">
                              <strong class="white--text">
                                {{ projectUser.initials }}
                              </strong>
                            </v-avatar>
                            {{ projectUser.fullName }}
                          </v-chip>
                        </v-chip-group>
                        <v-chip-group v-else multiple column>
                          <v-chip
                            pill
                            v-for="projectUser in nonRoleUsers"
                            :key="projectUser.ID"
                          >
                            <v-avatar left color="primary">
                              <strong class="white--text">
                                {{ projectUser.initials }}
                              </strong>
                            </v-avatar>
                            {{ projectUser.fullName }}
                          </v-chip>
                        </v-chip-group>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <!-- USERS -->
    <v-row v-if="isSuperUser || isProjectCreator">
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Users
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col sm="auto">
                      <v-text-field
                        v-model="searchText"
                        prepend-inner-icon="mdi-account-search"
                        label="Search"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="isSuperUser || isProjectCreator"
                      sm="auto"
                      class="mt-4"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="addAllUsers"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark
                              >mdi-account-multiple-plus-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Add All Users</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="users"
                    :server-items-length="total"
                    :loading="loading"
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                    :page.sync="pagination.page"
                    :items-per-page.sync="pagination.rowsPerPage"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 25, 50],
                    }"
                    class="pt-4"
                    dense
                  >
                    <template
                      v-if="isSuperUser || isProjectCreator"
                      v-slot:item.view="{item}"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            @click="addUser(item)"
                            v-on="on"
                            icon
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-account-plus</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Add User</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.LastName="{item}">
                      {{ item.LastName }}
                    </template>
                    <template v-slot:item.FirstName="{item}">
                      {{ item.FirstName }}
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      v-model="roleDialog"
      @keydown.esc="roleDialog = false"
      width="500"
      scrollable
    >
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Add Project Role</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row no-gutters>
            <v-col sm="12"
              >Select Project Role for {{ selectedProjectUser.fullName }}</v-col
            >
            <v-select
              v-model="selectedProjectUser.ProjectRoleID"
              :items="availableProjectRoles"
              item-text="Description"
              item-value="ID"
              label="Role"
              clearable
            ></v-select>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="updateProjectUser" color="primary" depressed
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="roleDialog = !roleDialog"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- SCRIPTS -->
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import UserProject from '@classes/UserProject'
import {debounce, range} from 'lodash'
import {authComputed} from '@state/helpers'
export default {
  model: {
    prop: 'projectUsers',
    event: 'change',
  },
  props: {
    divisionId: Number,
    projectId: Number,
    projectUsers: Array,
    projectDetails: Object,
  },

  data: () => ({
    users: [],
    total: 0,
    searchCount: 0,
    selectedProjectUser: {},
    roleDialog: false,
    pagination: {
      page: 1,
      sortBy: 'LastName',
      descending: false,
      rowsPerPage: 25,
    },
    panel: [0, 1],
    panel2: [0, 1],
    searchText: '',
    headers: [
      {
        text: '',
        align: 'left',
        value: 'view',
        sortable: false,
      },
      {
        text: 'Last Name',
        align: 'left',
        sortable: 'true',
        value: 'LastName',
      },
      {
        text: 'Fist Name',
        align: 'left',
        sortable: 'true',
        value: 'FirstName',
      },
    ],
    dialog: false,
    modalText: '',
    search: '',
  }),
  created() {
    this.searchUsers()
    this.loadLuProjectRole()
  },
  watch: {
    divisionId(nval, oval) {
      if (nval) {
        this.searchUsers()
      }
    },
    searchText: {
      handler() {
        this.bounce(this)
      },
    },
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
  },
  computed: {
    ...authComputed,
    luProjectRole: get('luProjectRole/luProjectRole'),
    loading() {
      return this.searchCount > 0
    },
    roleUsers() {
      return this.assignedUsers.filter((pu) => !!pu.ProjectRoleID)
    },
    nonRoleUsers() {
      return this.assignedUsers.filter((pu) => !pu.ProjectRoleID)
    },
    assignedUsers() {
      var projectUsers = this.projectUsers.map((pu) => {
        return {
          ...pu,
          fullName: pu.User
            ? pu.User.FirstName + ' ' + pu.User.LastName
            : 'unknown',
          initials: pu.User
            ? pu.User.FirstName.toString().substring(0, 1) +
              pu.User.LastName.toString().substring(0, 1)
            : '??',
          role: this.luProjectRole.find((r) => r.ID == pu.ProjectRoleID)
            ? this.luProjectRole.find((r) => r.ID == pu.ProjectRoleID)
                .Description
            : '',
        }
      })
      return projectUsers
    },
    availableProjectRoles() {
      // get active for this division
      var projectRoles = this.luProjectRole.filter(
        (r) =>
          r.IsActive &&
          r.DivisionID == this.divisionId &&
          (!r.Prefix || r.Prefix == this.projectDetails.Prefix)
      )
      // if existing record make sure the previous selection is available
      if (this.selectedProjectUser.ProjectRoleID > 0) {
        var selectedRole = this.luProjectRole.find(
          (r) => r.ID == this.selectedProjectUser.ProjectRoleID
        )
        if (
          !projectRoles.find(
            (r) => r.ID == this.selectedProjectUser.ProjectRoleID
          ) &&
          selectedRole
        ) {
          projectRoles.push(selectedRole)
        }
      }
      // sort by sort order column
      projectRoles.sort((a, b) => {
        let textField = 'Description'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return projectRoles
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    addUserProject: call('userProject/createUserProject'),
    saveUserProject: call('userProject/saveUserProject'),
    deleteUserProject: call('userProject/deleteUserProject'),
    loadLuProjectRole: call('luProjectRole/loadLuProjectRole'),
    addProjectRole(pu) {
      this.selectedProjectUser = pu
      if (this.availableProjectRoles.length > 0) {
        this.roleDialog = true
      }
    },
    updateProjectUser() {
      if (!this.selectedProjectUser.ProjectRoleID) {
        this.selectedProjectUser.ProjectRoleID = null
      }
      this.saveUserProject(
        new UserProject(this.selectedProjectUser).root()
      ).then(() => {
        this.roleDialog = false
        this.refreshUserList()
      })
    },
    refreshUserList() {
      let url = `UserProject/Project/${this.projectId}`
      this.$axios.get(url).then((res) => {
        this.$emit('updateUsers', res.data)
      })
    },
    searchUsers() {
      if (this.divisionId) {
        if (!this.pagination.sortBy) {
          this.pagination.sortBy = 'LastName'
          this.pagination.descending = false
        }
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `UserSearch?pageNum=${this.pagination.page - 1}&pageSize=${
          this.pagination.rowsPerPage
        }&sortProperty=${this.pagination.sortBy}&sortDirection=${
          this.pagination.descending ? 'desc' : 'asc'
        }&searchText=${this.searchText}&allUsers=false&division=${
          this.divisionId
        }`
        this.$axios.get(url).then(
          (res) => {
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.users = res.data.Entries
            this.searchCount--
          },
          (error) => {
            this.error = error
            this.handleError(error)
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
    addUser(user) {
      // -check to make sure user does not exist
      let existinguser = this.projectUsers.find((pu) => pu.UserID == user.ID)
      if (!existinguser) {
        let userProject = {ID: 0, UserID: user.ID, ProjectID: this.projectId}
        this.addUserProject(userProject).then((res) => {
          this.projectUsers.push(res.data)
        })
      } else {
        this.handleError('user already added')
      }
    },
    addAllUsers() {
      let url = `UserProject/AddAll`
      let dto = {projectId: this.projectId, divisionId: this.divisionId}
      this.$axios.post(url, dto).then(
        (res) => {
          // this.projectUsers = res.data
          this.$emit('updateUsers', res.data)
        },
        (error) => {
          console.error(error)
          this.handleError(error)
        }
      )
    },
    removeUser(projectUser) {
      this.deleteUserProject(projectUser).then((res) => {
        let existinguser = this.projectUsers.findIndex(
          (pu) => pu.ID == projectUser.ID
        )
        this.projectUsers.splice(existinguser, 1)
      })
    },
    bounce: _.debounce((self) => {
      self.searchUsers()
    }, 750),
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';

.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
